import { ColorMode, theme } from '@chakra-ui/react';

export const Tooltip = {
  ...theme.components.Switch,
  baseStyle: ({ colorMode }: { colorMode: ColorMode }) => ({
    px: '4',
    py: '1',
    // bg: colorMode === "dark" ? "gray.300" : "gray.500",
    color: colorMode === 'dark' ? 'gray.900' : 'whiteAlpha.900',
    borderRadius: 'md',
    fontWeight: 'medium',
    fontSize: 'xs',
    boxShadow: 'md',
    maxW: '320px',
    zIndex: 'tooltip',
  }),
};
