import { Select, SelectProps } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { IField } from 'constants/form';

export interface BaseSelectProps extends SelectProps {
  field: IField;
  name?: string;
  options?: any[];
  isLoading?: boolean;
}

export function BaseSelect({
  field,
  name,
  size = 'sm',
  borderRadius = 'md',
  options = [],
  isLoading = false,
  ...rest
}: BaseSelectProps) {
  const { register } = useFormContext();

  function getOptionsType() {
    if (options.every((option) => option?.value && option?.label)) {
      return options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ));
    }

    return options.map((value, index) => (
      <option key={index} value={value}>
        {value}
      </option>
    ));
  }

  return (
    <>
      {isLoading ? (
        <Select size={size} borderRadius={borderRadius} placeholder="Loading resources..." isDisabled />
      ) : (
        <Select
          size={size}
          borderRadius={borderRadius}
          placeholder={field.placeholder || 'Select an option'}
          name={name || field.name}
          ref={register(field.validation)}
          {...rest}
        >
          {getOptionsType()}
        </Select>
      )}
    </>
  );
}
