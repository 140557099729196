import { useEffect, useState } from 'react';
import { FormControlProps } from '@chakra-ui/react';
import { deepClone } from 'ds4biz-core';
import { useFormContext } from 'react-hook-form';
import { IField } from 'constants/form';
import { Field } from './Field';

interface DynamicProps {
  field: IField & {
    parent: string;
    condition: string;
    dynamicType: string;
  };
  customStyle?: FormControlProps;
}

export function Dynamic({ field, customStyle }: DynamicProps) {
  const { watch } = useFormContext();
  const [updatedField, setUpdatedField] = useState<IField | null>(null);

  const parentWatch = watch(field.parent);

  useEffect(() => {
    if (field.condition) {
      let { condition } = field;

      if (field.condition.includes('{parent}')) {
        let value = '';

        if (typeof parentWatch !== 'string' && (typeof parentWatch === 'boolean' || !Number.isNaN(parentWatch))) {
          value = parentWatch;
        } else {
          value = `"${parentWatch}"`;
        }

        condition = condition.replace('{parent}', value);
      }

      // eslint-disable-next-line no-eval
      if (eval(condition)) {
        const toSend = deepClone(field);

        if (field.dynamicType) {
          if (typeof field.dynamicType === 'string') {
            toSend.type = field.dynamicType;
          } else {
            toSend.type = field.dynamicType[parentWatch];
          }
        }

        setUpdatedField(toSend);
      } else {
        setUpdatedField(null);
      }
    } else if (parentWatch) {
      const toSend = deepClone(field);

      if (field.dynamicType) {
        if (typeof field.dynamicType === 'string') {
          toSend.type = field.dynamicType;
        } else {
          toSend.type = field.dynamicType[parentWatch];
        }
      }

      setUpdatedField(toSend);
    } else {
      setUpdatedField(null);
    }
  }, [parentWatch]);

  return updatedField && <Field field={updatedField} {...customStyle} />;
}
