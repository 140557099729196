import { useEffect, useState } from 'react';
import { Flex, Text } from '@chakra-ui/layout';
import axios from 'axios';
import { useHistory } from 'react-router';
import urljoin from 'url-join';
import { requiredLabel } from 'constants/form';
import { baseURL } from 'services/api';
import { Form } from 'shared/Form';
import { LogoLabel } from 'shared/ImageSprite';
import { Button } from '@chakra-ui/button';
import { Modal, ModalBody, ModalContent, ModalHeader } from '@chakra-ui/modal';
import useLocalStorage, { writeStorage } from '@rehooks/local-storage';
import { Tenant } from 'Routes';
import { useConfirm } from 'shared/ConfirmerProvider';
import { ACCESS_TOKEN_STORAGE, REFRESH_TOKEN_STORAGE, TENANT_STORAGE } from 'constants/localStorage';

const login = [
  {
    name: 'username',
    placeholder: 'Username or email',
    type: 'text',
    hideLabel: true,
    validation: {
      required: requiredLabel,
    },
  },
  {
    name: 'password',
    placeholder: 'Password',
    type: 'password',
    hideLabel: true,
    validation: {
      required: requiredLabel,
    },
  },
];

export function Login() {
  const history = useHistory();
  const confirm = useConfirm();

  const [error, setError] = useState<string | null>(null);

  const [accessToken, setAccessToken] = useLocalStorage<string>(ACCESS_TOKEN_STORAGE);

  const isSignupDisabled = import.meta.env.VITE_DISABLE_SIGNUP === 'true';

  useEffect(() => {
    if (accessToken) {
      history.push('/welcome');
    }
  }, [accessToken]);

  function handleLogin(credentials: { username: string; password: string }) {
    axios
      .post(urljoin(baseURL, 'auth'), credentials)

      .then(async ({ data }) => {
        const useIAM = import.meta.env.VITE_USE_IAM === 'true';

        setError(null);

        if (useIAM) {
          const { data: tenants } = await axios.get<Tenant[]>(urljoin(baseURL, 'iaas', 'tenants'), {
            headers: {
              Authorization: `Bearer ${data.access_token}`,
            },
          });

          const { tenant } = await confirm({
            title: 'Tenant selection',
            settings: [
              {
                name: 'tenant',
                type: 'select',
                label: 'Tenant',
                options: tenants
                  .filter((_tenant) => _tenant.status === 'running')
                  .map((_tenant) => ({ label: _tenant.name, value: _tenant.url })),
                validation: {
                  required: requiredLabel,
                },
              },
            ],
          });

          if (tenant) {
            setAccessToken(data.access_token);
            writeStorage(REFRESH_TOKEN_STORAGE, data.refresh_token);
            writeStorage(TENANT_STORAGE, tenant);
            history.push('/welcome');
          }
        } else {
          setAccessToken(data.access_token);
          writeStorage(REFRESH_TOKEN_STORAGE, data.refresh_token);

          history.push('/welcome');
        }
      })
      .catch((err) => {
        setError(err.response?.data.exception || 'Error during login');
      });
  }

  return (
    <Modal isOpen onClose={() => false} isCentered size="xs" closeOnOverlayClick={false} closeOnEsc={false}>
      <ModalContent borderRadius="2xl">
        <ModalHeader>
          <Flex my={8} justifyContent="center">
            <LogoLabel width="90" />
          </Flex>
        </ModalHeader>
        <ModalBody px={8}>
          <Form
            cancelButton={false}
            settings={login}
            fieldStyle={{
              mb: 2,
              variant: 'outline',
              size: 'sm',
              borderRadius: 'lg',
              bg: 'transparent',
            }}
            customFooter={
              <Button mt={2} mb={2} type="submit" size="sm" isFullWidth variant="solid" colorScheme="gray">
                Login
              </Button>
            }
            onSubmit={handleLogin}
          />

          {error && (
            <Text textAlign="center" mb={4} color="red.400">
              {error}
            </Text>
          )}

          <Flex my={4} justifyContent="center">
            <Button size="xs" variant="link" colorScheme="cyan" onClick={() => history.push('/reset')}>
              Forgot password?
            </Button>
            {!isSignupDisabled && (
              <>
                <Text fontSize="xs" mx={1.5}>
                  or
                </Text>
                <Button size="xs" variant="link" colorScheme="cyan" onClick={() => history.push('/signup')}>
                  Sign up
                </Button>
              </>
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
