import { Box, Flex, ButtonGroup, Button, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { isMobile } from 'utils/misc';
import { LogoFlat } from './ImageSprite';

interface OnlyDesktopProps {
  children: ReactNode;
}

export function OnlyDesktop({ children }: OnlyDesktopProps) {
  return (
    <>
      {isMobile() ? (
        <Box px={7} py={16} textAlign="center">
          <Flex justifyContent="center" my="10">
            <LogoFlat width="120" />
          </Flex>

          <Text fontSize="xl" mb="4">
            <b>LOKO AI</b> is not available on mobile screen yet!
          </Text>

          <ButtonGroup spacing="2">
            <Button as="a" href="https://loko-ai.com/" variant="outline" color="orange.400">
              LOKO AI website
            </Button>
          </ButtonGroup>
        </Box>
      ) : (
        children
      )}
    </>
  );
}
