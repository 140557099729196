export function LogoFlat({ width = '36', style = {} }) {
  return (
    <svg width={width} viewBox="0 0 264 280" style={style}>
      <defs>
        <radialGradient
          cx="0%"
          cy="100%"
          fx="0%"
          fy="100%"
          r="141.422%"
          gradientTransform="matrix(.7071 -.7071 .70711 .7071 -.707 .293)"
          id="a"
        >
          <stop stopColor="#F90" offset="0%" />
          <stop stopColor="#FFB000" offset="100%" />
        </radialGradient>
        <radialGradient
          cx="100%"
          cy="0%"
          fx="100%"
          fy="0%"
          r="141.422%"
          gradientTransform="scale(-1) rotate(-45 0 2.414)"
          id="b"
        >
          <stop stopColor="#F64950" offset="0%" />
          <stop stopColor="#FA6B24" offset="100%" />
        </radialGradient>
        <radialGradient cx="100%" cy="100%" fx="100%" fy="100%" r="141.421%" id="c">
          <stop stopColor="#FC750D" offset="0%" />
          <stop stopColor="#FF9700" offset="100%" />
        </radialGradient>
        <radialGradient cx=".165%" cy="-.165%" fx=".165%" fy="-.165%" r="141.422%" id="d">
          <stop stopColor="#F2216F" offset="0%" />
          <stop stopColor="#F65246" offset="100%" />
        </radialGradient>
      </defs>
      <g>
        <path
          d="M128 0v128.001L.021 128 0 120.993l.224-15.498.555-11.252L1.874 82.99l1.715-10.597 2.994-11.907 2.294-6.618 1.943-4.636 4.672-8.9 5.343-7.694 5.223-5.91h.001l6.594-5.91 5.341-3.857 11.25-6.162 4.636-1.941 6.617-2.295 8.82-2.341 13.68-2.369 8.036-.847 6.91-.48 14.63-.48L128 0z"
          fill="url(#a)"
        />
        <path
          d="M263.979 152.001l.021 7.008-.224 15.498-.555 11.252-1.095 11.253-1.715 10.597-2.994 11.907-2.294 6.618-1.943 4.636-4.672 8.9-5.343 7.694-5.224 5.91-6.594 5.91-5.341 3.857-11.25 6.162-4.636 1.941-6.617 2.295-8.82 2.341-13.68 2.369-8.036.847-6.91.48-14.63.48-15.427.046V152.001h127.979z"
          fill="url(#b)"
        />
        <path
          d="M0 136.001h127.999L128 263.98l-7.008.021-15.498-.224-11.252-.555-11.253-1.095-10.597-1.715-11.907-2.994-6.618-2.294-4.636-1.943-8.9-4.672-7.694-5.343-5.91-5.224-5.91-6.594-3.857-5.341-.585-.903-5.577-10.348-1.941-4.635-2.295-6.617-2.341-8.82-2.369-13.68-.847-8.036-.48-6.91-.48-14.63L0 136z"
          fill="url(#c)"
        />
        <path
          d="M158.506 16.225l11.252.555 11.252 1.095 10.598 1.716 11.906 2.994 6.618 2.295 4.636 1.943 8.9 4.673 7.694 5.343 5.91 5.226 5.91 6.595 3.858 5.342.584.903 5.577 10.35 1.941 4.635 2.295 6.618 2.341 8.823 2.369 13.681.847 8.038.48 6.912.48 14.632L264 144H136V16.022l7.008-.021 15.498.224z"
          fill="url(#d)"
        />
      </g>
    </svg>
  );
}

export function LogoLabel({ width = '96' }) {
  return (
    <svg width={width} viewBox="0 0 264 361" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <radialGradient
          cx="0%"
          cy="100%"
          fx="0%"
          fy="100%"
          r="141.422%"
          gradientTransform="matrix(.7071 -.7071 .70711 .7071 -.707 .293)"
          id="a"
        >
          <stop stopColor="#F90" offset="0%" />
          <stop stopColor="#FFB000" offset="100%" />
        </radialGradient>
        <radialGradient
          cx="100%"
          cy="0%"
          fx="100%"
          fy="0%"
          r="141.422%"
          gradientTransform="scale(-1) rotate(-45 0 2.414)"
          id="b"
        >
          <stop stopColor="#F64950" offset="0%" />
          <stop stopColor="#FA6B24" offset="100%" />
        </radialGradient>
        <radialGradient cx="100%" cy="100%" fx="100%" fy="100%" r="141.421%" id="c">
          <stop stopColor="#FC750D" offset="0%" />
          <stop stopColor="#FF9700" offset="100%" />
        </radialGradient>
        <radialGradient cx=".165%" cy="-.165%" fx=".165%" fy="-.165%" r="141.422%" id="d">
          <stop stopColor="#F2216F" offset="0%" />
          <stop stopColor="#F65246" offset="100%" />
        </radialGradient>
      </defs>
      <g fill="currentColor">
        <path d="M243.92 360.156c3.6 0 6.936-.865 10.01-2.596 3.072-1.731 5.487-4.111 7.243-7.14 1.756-3.03 2.634-6.32 2.634-9.868s-.886-6.837-2.66-9.867c-1.774-3.03-4.215-5.4-7.323-7.114-3.109-1.714-6.463-2.571-10.063-2.571-3.6 0-6.928.866-9.983 2.597-3.056 1.73-5.462 4.12-7.218 7.166-1.756 3.047-2.634 6.37-2.634 9.971 0 3.497.887 6.751 2.66 9.763 1.774 3.012 4.206 5.375 7.297 7.089 3.09 1.714 6.436 2.57 10.036 2.57zm-.027-3.635c-4.425 0-8.201-1.54-11.327-4.622-3.126-3.081-4.689-6.846-4.689-11.295s1.52-8.222 4.557-11.32c3.038-3.1 6.823-4.649 11.354-4.649 4.425 0 8.21 1.54 11.353 4.622 3.143 3.081 4.715 6.855 4.715 11.321 0 4.605-1.554 8.413-4.662 11.425-3.109 3.012-6.876 4.518-11.301 4.518zM158.82 360.156v-18.269l17.153 18.27h5.173l-20.257-21.597L179.675 321h-5.282l-15.573 14.67V321h-4.03v39.156zM85.458 360.156c3.6 0 6.937-.865 10.01-2.596 3.073-1.731 5.488-4.111 7.244-7.14 1.756-3.03 2.634-6.32 2.634-9.868s-.887-6.837-2.66-9.867c-1.774-3.03-4.215-5.4-7.323-7.114C92.254 321.857 88.9 321 85.3 321c-3.6 0-6.928.866-9.983 2.597-3.056 1.73-5.462 4.12-7.218 7.166-1.756 3.047-2.634 6.37-2.634 9.971 0 3.497.887 6.751 2.66 9.763 1.774 3.012 4.206 5.375 7.297 7.089 3.09 1.714 6.436 2.57 10.036 2.57zm-.026-3.635c-4.426 0-8.201-1.54-11.327-4.622-3.126-3.081-4.69-6.846-4.69-11.295s1.52-8.222 4.558-11.32c3.038-3.1 6.823-4.649 11.353-4.649 4.426 0 8.21 1.54 11.354 4.622 3.143 3.081 4.715 6.855 4.715 11.321 0 4.605-1.554 8.413-4.663 11.425-3.108 3.012-6.875 4.518-11.3 4.518zM18.825 360.156v-3.763H3.938V321H0v39.156z" />
      </g>
      <path
        d="M128 0v128.001L.021 128 0 120.993l.224-15.498.555-11.252L1.874 82.99l1.715-10.597 2.994-11.907 2.294-6.618 1.943-4.636 4.672-8.9 5.343-7.694 5.223-5.91h.001l6.594-5.91 5.341-3.857 11.25-6.162 4.636-1.941 6.617-2.295 8.82-2.341 13.68-2.369 8.036-.847 6.91-.48 14.63-.48L128 0z"
        fill="url(#a)"
      />
      <path
        d="M263.979 152.001l.021 7.008-.224 15.498-.555 11.252-1.095 11.253-1.715 10.597-2.994 11.907-2.294 6.618-1.943 4.636-4.672 8.9-5.343 7.694-5.224 5.91-6.594 5.91-5.341 3.857-11.25 6.162-4.636 1.941-6.617 2.295-8.82 2.341-13.68 2.369-8.036.847-6.91.48-14.63.48-15.427.046V152.001h127.979z"
        fill="url(#b)"
      />
      <path
        d="M0 136.001h127.999L128 263.98l-7.008.021-15.498-.224-11.252-.555-11.253-1.095-10.597-1.715-11.907-2.994-6.618-2.294-4.636-1.943-8.9-4.672-7.694-5.343-5.91-5.224-5.91-6.594-3.857-5.341-.585-.903-5.577-10.348-1.941-4.635-2.295-6.617-2.341-8.82-2.369-13.68-.847-8.036-.48-6.91-.48-14.63L0 136z"
        fill="url(#c)"
      />
      <path
        d="M158.506 16.225l11.252.555 11.252 1.095 10.598 1.716 11.906 2.994 6.618 2.295 4.636 1.943 8.9 4.673 7.694 5.343 5.91 5.226 5.91 6.595 3.858 5.342.584.903 5.577 10.35 1.941 4.635 2.295 6.618 2.341 8.823 2.369 13.681.847 8.038.48 6.912.48 14.632L264 144H136V16.022l7.008-.021 15.498.224z"
        fill="url(#d)"
      />
    </svg>
  );
}

export function StatBgImage() {
  return (
    <svg width="512" height="256">
      <g fill="#D8D8D8" fillOpacity=".3">
        <path d="M512 156.7c-64.52 0-80.66 39.126-119.773 39.126-39.114 0-55.903-50.642-101.88-50.642-45.976 0-60.671 53.156-117.347 53.156-56.676 0-58.008-53.156-107.98-53.156-49.973 0-39.844 34.058-65.02 34.058V256h512v-99.3z" />
        <path d="M0 221.566c51.363 0 72.438-85.914 161.188-85.914 88.75 0 91.14 80.133 172.917 80.133 81.778 0 75.727-80.133 177.895-80.133V256H0v-34.434z" />
      </g>
    </svg>
  );
}
