import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { Dialog } from 'ds4biz-core';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { ChangeEvent, ReactNode, useState } from 'react';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { gatewayCrud } from 'services/api';
import { makeLogout } from 'store/async/resources';
import { showToast } from './Toast';

interface InputFieldProps extends InputProps {
  label: string;
  type?: string;
  placeholder: string;
  helperText?: ReactNode;
  hasResetPassword?: boolean;
}

export function InputField({
  label,
  placeholder,
  type = 'password',
  helperText,
  hasResetPassword,
  ...rest
}: InputFieldProps) {
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <FormControl>
      <FormLabel d="flex" fontSize="sm" justifyContent="space-between" mb={1} mr={0}>
        {label}
        {hasResetPassword && (
          <Button as={Link} to="/reset" variant="link" colorScheme="pink" size="xs" fontWeight={700}>
            Forgot password?
          </Button>
        )}
      </FormLabel>
      <InputGroup>
        <Input type={passwordVisible ? 'text' : type} placeholder={placeholder} {...rest} />
        {type === 'password' && (
          <InputRightElement onClick={() => setPasswordVisible(!passwordVisible)}>
            {passwordVisible ? (
              <RiEyeOffLine color="#888" title="Hide password" />
            ) : (
              <RiEyeLine color="#888" title="Show password" />
            )}
          </InputRightElement>
        )}
      </InputGroup>
      {helperText && <FormHelperText color="red.400">{helperText}</FormHelperText>}
    </FormControl>
  );
}

interface PasswordChangeProps {
  onClose: () => void;
}

export function PasswordChange({ onClose }: PasswordChangeProps) {
  const user = useAppSelector((state) => state.resources.user);
  const dispatch = useAppDispatch();

  const [passwords, setPasswords] = useState({ old_password: '', new_password: '', confirm_password: '' });

  const strongRegex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$');

  const arePasswordMatched = passwords.new_password === passwords.confirm_password;
  const isNewPasswordEqualToOld = passwords.old_password === passwords.new_password;
  const isNewPasswordStrong = strongRegex.test(passwords.new_password);

  function handlePasswordChange() {
    const { old_password, new_password, confirm_password } = passwords;

    if (
      !old_password ||
      !new_password ||
      !confirm_password ||
      !arePasswordMatched ||
      !isNewPasswordStrong ||
      isNewPasswordEqualToOld
    ) {
      return;
    }

    if (user) {
      gatewayCrud
        .createOne(`/users/${user.username}/password/change`, {
          old_password,
          new_password,
        })
        .then(() => {
          dispatch(makeLogout());
          showToast({
            title: 'Password changed, log-in again!',
            type: 'info',
          });
        });
    }
  }

  function handleChangeValue(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setPasswords({ ...passwords, [name]: value });
  }

  return (
    <Dialog title="Change password" isOpen onClose={onClose} onSave={handlePasswordChange} saveLabel="Save">
      <>
        <InputField
          label="Old password"
          name="old_password"
          placeholder="Your old password"
          value={passwords.old_password}
          onChange={handleChangeValue}
          hasResetPassword
          isRequired
        />

        <Box mt={4}>
          <Text mb={2} fontSize="sm">
            Your password must contains:
          </Text>
          <UnorderedList mb={4} pl={4} fontSize="xs">
            <ListItem>at least 8 characters</ListItem>
            <ListItem>at least one lowercase letter</ListItem>
            <ListItem>at least one capital letter</ListItem>
            <ListItem>at least one number</ListItem>
            <ListItem>at least one special character</ListItem>
          </UnorderedList>
          <InputField
            label="New password"
            name="new_password"
            placeholder="Your new password"
            value={passwords.new_password}
            _focus={{
              borderColor: passwords.new_password && isNewPasswordStrong && 'green.400',
            }}
            helperText={
              passwords.new_password && !isNewPasswordStrong
                ? "Password doesn't meet the requirements"
                : passwords.new_password &&
                  isNewPasswordEqualToOld &&
                  'The new password cannot be the same as the old password'
            }
            onChange={handleChangeValue}
            isRequired
          />
        </Box>

        <Box mt={4} mb={8}>
          <InputField
            label="Confirm password"
            name="confirm_password"
            placeholder="Confirm password"
            value={passwords.confirm_password}
            onChange={handleChangeValue}
            _focus={{
              borderColor: passwords.new_password && passwords.confirm_password && arePasswordMatched && 'green.400',
            }}
            helperText={
              passwords.new_password && passwords.confirm_password && !arePasswordMatched && 'Passwords does not match'
            }
            isRequired
          />
        </Box>
      </>
    </Dialog>
  );
}
