import { useColorModeValue } from '@chakra-ui/react';
import { LineSvgProps, ResponsiveLine } from '@nivo/line';
import { getAxisTooltip } from './constants';

interface LineProps extends LineSvgProps {
  yLegend?: string;
  xLegend?: string;
}

export function Line({ data, xLegend, yLegend, ...rest }: LineProps) {
  const textColor = useColorModeValue('#222222', '#C9C9C9');
  const gridColor = useColorModeValue('#ddd', '#444');
  return (
    <ResponsiveLine
      data={data}
      theme={{
        fontFamily: 'Share Tech Mono',
        textColor,
        grid: {
          line: {
            stroke: gridColor,
            strokeWidth: 1,
          },
        },
      }}
      margin={{ top: 25, right: 125, bottom: 80, left: 55 }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
      }}
      axisBottom={{
        format: (v) => getAxisTooltip(v),
        tickSize: 0,
        tickPadding: 5,
        tickRotation: -35,
        legend: xLegend ?? 'X',
        legendOffset: 36,
        legendPosition: 'middle',
      }}
      axisLeft={{
        format: (v) => getAxisTooltip(v),
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        legend: yLegend ?? 'Y',
        legendOffset: -40,
        legendPosition: 'middle',
      }}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      enablePoints={false}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      useMesh
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      {...rest}
    />
  );
}
