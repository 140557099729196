import { ButtonGroup, IconButton } from '@chakra-ui/button';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { Flex, FlexProps, Heading, Text } from '@chakra-ui/layout';
import { chakra, Progress } from '@chakra-ui/react';
import { Fragment, MouseEvent, useState } from 'react';
import { RiDeleteBin4Line, RiShareForward2Fill } from 'react-icons/ri';
import { Row, RowActions, RowBody } from 'shared/Row';

interface ScoreProps extends FlexProps {
  score?: string;
  fitted?: boolean;
}

export function Score({ score, fitted, ...rest }: ScoreProps) {
  return (
    <Flex
      mt="1"
      right="0"
      zIndex="0"
      bottom="-20px"
      pos="absolute"
      flexDir="column"
      fontSize="88px"
      lineHeight="99px"
      color="whiteAlpha.100"
      fontStyle="italic"
      letterSpacing={score ? '-.75rem' : '-.35rem'}
      {...rest}
    >
      <Text fontWeight="900" textAlign="right">
        {fitted ? score?.split(' (')[0] : 'Not fitted'}
      </Text>
    </Flex>
  );
}

export interface IPredictor {
  description: string;
  fitted: boolean;
  ['last fit']: string;
  model: string;
  name: string;
  score: string;
  task: string;
  transformer: string;
}

interface PredictorProps extends Omit<FlexProps, 'position'> {
  predictor: IPredictor;
  onDelete: (e: MouseEvent) => void;
  onExport: (e: MouseEvent) => void;
}

export function Predictor({ predictor, onDelete, onExport, ...rest }: PredictorProps) {
  const { name, description, model, transformer, task, score, fitted } = predictor;

  const details = [
    { key: 'score', value: score },
    { key: 'model', value: model },
    { key: 'transformer', value: transformer },
    { key: 'last fit', value: predictor['last fit'] },
    { key: 'task', value: task },
  ];

  const colorCard = useColorModeValue('gray.900', 'gray.200');
  const bgCard = useColorModeValue('gray.100', 'gray.800');
  const titleColor = useColorModeValue('red.500', 'red.500');
  const titleHoverColor = useColorModeValue('red.400', 'red.400');

  const [hover, setHover] = useState(false);
  // @ts-ignore
  const numberScore = score && score.split(' (') ? score.split(' (')[0] * 100 : 0;

  return (
    <Row
      bg={bgCard}
      pos="relative"
      cursor="pointer"
      color={colorCard}
      borderRadius="xl"
      _hover={{ bg: bgCard }}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{ transition: 'background 150ms ease-in-out' }}
      flexDir={{ base: 'column', md: 'row' }}
      {...rest}
    >
      <RowBody d="flex" flex="1" alignItems="flex-start" justifyContent="flex-start" minW={{ base: '100%', md: 0 }}>
        <Heading
          color={hover ? titleHoverColor : titleColor}
          style={{ transition: '150ms color ease-in-out' }}
          size="sm"
        >
          {name.split('.predictor')[0]}{' '}
          <chakra.span
            bg={fitted ? 'teal.300' : 'red.100'}
            py={0.5}
            pl={1}
            pr={1.5}
            borderRadius="full"
            letterSpacing={-0.5}
            color="gray.700"
            fontSize={10}
          >
            {fitted ? 'Fitted' : 'Not fitted'}
          </chakra.span>
        </Heading>
        {description && (
          <Text
            alignSelf="flex-start"
            mt={1}
            opacity={0.4}
            fontStyle="italic"
            lineHeight="1.25"
            fontSize="xs"
            maxW="90%"
          >
            {description}
          </Text>
        )}
        <Flex mt={3} fontSize="xs" flexDir={{ base: 'column', md: 'row' }}>
          {details.map((detail, i) => (
            <Fragment key={detail.key}>
              {detail.value && (
                <Flex flexDir="column" key={i} opacity={0.8} mr={4}>
                  {detail.key}
                  <chakra.span opacity={0.5} fontWeight={900} lineHeight={1.1}>
                    {detail.value}
                  </chakra.span>
                  {detail.key === 'score' && (
                    <Flex flexDir="column" mt={0.5}>
                      <Progress height="2px" maxW="60px" borderRadius="md" value={numberScore} colorScheme="cyan" />
                    </Flex>
                  )}
                </Flex>
              )}
            </Fragment>
          ))}
        </Flex>
      </RowBody>
      <RowActions flexDir={{ base: 'column', md: 'row' }} mt={{ base: 6, md: 0 }} ml={{ base: 'auto', md: '0' }}>
        <ButtonGroup spacing={1} size="sm" variant="solid" colorScheme="gray">
          <IconButton
            aria-label="Export predicotor"
            icon={<RiShareForward2Fill />}
            tabIndex={-1}
            borderRadius="full"
            onClick={onExport}
          />
          <IconButton
            aria-label="Delete predicotor"
            icon={<RiDeleteBin4Line />}
            tabIndex={-1}
            borderRadius="full"
            onClick={onDelete}
          />
        </ButtonGroup>
      </RowActions>
    </Row>
  );
}
