import { useState, useEffect } from 'react';
import { Box, Button, SimpleGrid } from '@chakra-ui/react';
import { isJsonValid } from 'ds4biz-core';
import { CodeEditor } from 'shared/CodeEditor';
import { showToast } from 'shared/Toast';
import { orchestratorCrud } from 'services/api';
import { GenericObject } from 'utils/misc';
import { Item } from '../types';

export function JsonViewer({ item }: { item: Item }) {
  const [object, setObject] = useState<string | null>(null);

  useEffect(() => {
    orchestratorCrud.getOne<GenericObject>('files', item.path).then((data) => {
      setObject(JSON.stringify(data, null, 2));
    });
  }, [item]);

  function handleSave() {
    if (object) {
      const form = new FormData();
      const blob = new Blob([object], {
        type: 'text/plain',
      });
      form.append('file', blob, item.name);

      orchestratorCrud.overwriteOne('files', item.parent, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      showToast({ title: 'File saved!' });
    }
  }

  return (
    <Box overflow="auto" w="full">
      {object && (
        <>
          <SimpleGrid columns={1} spacing={5}>
            <CodeEditor mode="json" value={object} onChange={(value) => value && setObject(value)} />
          </SimpleGrid>

          <Box mt={4} textAlign="right">
            <Button disabled={!isJsonValid(object)} colorScheme="pink" variant="ghost" size="sm" onClick={handleSave}>
              Save
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}
