import { ColorMode, theme } from '@chakra-ui/react';

export const Switch = {
  ...theme.components.Switch,
  parts: ['track', 'thumb'],
  baseStyle: ({ colorMode }: { colorMode: ColorMode }) => ({
    track: {
      borderRadius: 'full',
      p: '2px',
      transition: 'all 120ms',
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
      _checked: {
        // background: colorMode === "dark" ? "blue.300" : "blue.500",
      },
    },
    thumb: {
      transition: 'transform 250ms',
      borderRadius: 'full',
      transform: 'translateX(0)',
      _checked: {
        background: colorMode === 'dark' ? 'gray.700' : 'gray.100',
      },
    },
  }),
  sizes: {
    xs: {
      track: { w: '1.25rem', h: '0.675rem' },
      thumb: {
        w: '0.675rem',
        h: '0.675rem',
        _checked: {
          transform: 'translateX(0.5rem)',
        },
      },
    },
    sm: {
      track: { w: '1.375rem', h: '0.75rem' },
      thumb: {
        w: '0.75rem',
        h: '0.75rem',
        _checked: {
          transform: 'translateX(0.625rem)',
        },
      },
    },
    md: {
      track: { w: '1.875rem', h: '1rem' },
      thumb: {
        w: '1rem',
        h: '1rem',
        _checked: {
          transform: 'translateX(0.875rem)',
        },
      },
    },
    lg: {
      track: { w: '2.875rem', h: '1.5rem' },
      thumb: {
        w: '1.5rem',
        h: '1.5rem',
        _checked: {
          transform: 'translateX(1.375rem)',
        },
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
};
