import axios from 'axios';
import urljoin from 'url-join';
import { Crud } from './crud';
import { errorHandler, requestHandler, successHandler } from './interceptors';

const baseURL = import.meta.env.VITE_BASE_URL;

const orchestratorAxiosInstance = axios.create({
  baseURL: urljoin(baseURL, 'orchestrator'),
});

orchestratorAxiosInstance.interceptors.request.use(requestHandler);
orchestratorAxiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
);

const gatewayAxiosInstance = axios.create({
  baseURL,
});

gatewayAxiosInstance.interceptors.request.use(requestHandler);
gatewayAxiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
);

const orchestratorCrud = new Crud(orchestratorAxiosInstance);
const gatewayCrud = new Crud(gatewayAxiosInstance);

export { baseURL, orchestratorCrud, gatewayCrud };
