export const MenuItem = {
  baseStyle: () => ({
    whiteSpace: 'nowrap',
    padding: '0.25rem 0.5rem',
    cursor: 'pointer',
    _hover: {
      backgroundColor: 'whiteAlpha.300',
    },
  }),
  sizes: {
    xs: {
      fontSize: '0.75rem',
    },
    sm: {
      fontSize: '0.875rem',
    },
    md: {
      fontSize: '1rem',
    },
    lg: {
      fontSize: '1.125rem',
    },
    xl: {
      fontSize: '1.5rem',
    },
  },
  variants: {
    plain: {
      listStyleType: 'none',
    },
  },
  defaultProps: {
    size: 'sm',
    variant: 'plain',
  },
};
