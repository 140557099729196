import { Alert, AlertDescription, AlertIcon, AlertTitle } from '@chakra-ui/alert';
import { Button } from '@chakra-ui/button';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { Box, Flex, Grid, GridItem, Heading, Text } from '@chakra-ui/layout';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';
import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';
import { Slider, SliderFilledTrack, SliderThumb, SliderTrack } from '@chakra-ui/slider';
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table';
import { Tooltip } from '@chakra-ui/tooltip';
import { Serie as LineSerie } from '@nivo/line';
import StatBgImage from 'assets/images/stat-bg-graphic-seamless.svg';
import * as d3 from 'd3';
import { useCompositeState } from 'ds4biz-core';
import { saveAs } from 'file-saver';
import { useQuery } from 'hooks/useQuery';
import sampleSize from 'lodash.samplesize';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { RiArrowDownSLine, RiArrowLeftLine } from 'react-icons/ri';
import { useHistory, useParams } from 'react-router';
import { gatewayCrud } from 'services/api';
import { AsyncLoader } from 'shared/AsyncLoader';
import { CodeEditor } from 'shared/CodeEditor';
import { Bar, Heatmap, Line, Scatter } from 'shared/Plot';
import { getAxisTooltip } from 'shared/Plot/constants';
import { Row, RowBody } from 'shared/Row';
import { showToast } from 'shared/Toast';
import urljoin from 'url-join';
import { capitalize, GenericObject } from 'utils/misc';
import { IPredictor, Score } from '../PredictorList/Predictor';
import { PredictorDetailsBox } from './PredictorDetailsBox';

function formatDate(ms: number) {
  return new Date(ms).toLocaleString();
}

interface Report {
  metrics: {
    [key: string]: number;
  };
  classification_report: Array<{
    label: string;
    'precision (%)': number;
    'recall  (%)': number;
    'f1-score (%)': number;
    support: number;
  }>;
  confusion_matrix: {
    values: number[][];
    labels: string[];
  };
  scatter: {
    y_true: number[];
    y_pred: number[];
  };
}

interface History {
  tdist: {
    hist: {
      [value: string]: number;
    };
    y: number[];
  };
  cv_scores: number | null;
  task: 'regression' | 'classification';
  datetime: number;
  training_report: Report;
  test_report: Report;
  report_split: {
    'test_size (%)': number;
    'train_size (%)': number;
  };
}

const bgCard = 'pink.300';

const chartMargins = { top: 20, right: 10, bottom: 70, left: 20 };

// Evaluate is filled when opened from .eval files in file manager
export function PredictorDetails({ evaluate }: any) {
  const query = useQuery();
  const { name }: { name: string } = useParams();
  const history = useHistory();
  const [showHistorySliderInOverlay] = useState(true);
  const [hover, setHover] = useState(false);
  const [binsNumber, setBinsNumber] = useState(10);

  const binSelector = useRef<HTMLInputElement>(null);

  const colorCard = useColorModeValue('gray.100', 'gray.900');
  const histogramBarsColor = useColorModeValue('#444', '#ddd');
  const histogramBarsLabelColor = useColorModeValue('#ddd', '#222');

  function handleBinSelector() {
    if (binSelector.current) {
      setBinsNumber(+binSelector.current.value);
    }
  }

  const state: {
    url: string | null;
    info: IPredictor | null;
    history: History[];
    activeHistory: number;
    activeReport: 'test' | 'training' | null;
    report: Report | null;
    isLoading: boolean;
    tableView: boolean;
    histogramView: boolean;
    showBlueprint: boolean;
    blueprint: string | null;
    model: { [key: string]: string | number } | null;
    transformers: Array<{ key: string; values: string[] }>;
  } = useCompositeState({
    url: null,
    info: null,
    history: [],
    activeHistory: 0,
    activeReport: null,
    report: null,
    isLoading: true,
    tableView: true,
    histogramView: true,
    showBlueprint: false,
    blueprint: null,
    model: null,
    transformers: [],
  });

  const taskType = state.history[state.activeHistory]?.task;

  const predictorName = query.get('predictor_name');

  useEffect(() => {
    if (evaluate) {
      state.info = evaluate.info;
      state.history = evaluate.results;
      state.blueprint = JSON.stringify(evaluate.blueprint, null, 2);
      state.model = evaluate.blueprint.model;
      state.activeReport = 'test';

      if (evaluate.blueprint.transformer?.transformers) {
        state.transformers = evaluate.blueprint.transformer.transformers.map((transformer: any) => ({
          key: transformer[0],
          values: transformer[2],
        }));
      }

      state.isLoading = false;
    }
  }, []);

  useEffect(() => {
    if (name) {
      state.url = urljoin(predictorName || 'predictor', 'predictors', name);
    }
  }, [name]);

  useEffect(() => {
    if (state.url) {
      state.isLoading = true;

      const infoApi = gatewayCrud.getAll<IPredictor>(state.url, { params: { details: 'true' } });
      const historyApi = gatewayCrud.getAll<History[]>(urljoin(state.url, 'history'));
      const blueprintApi = gatewayCrud.getAll<{ [key: string]: any }>(state.url, {
        params: { details: 'false' },
      });

      Promise.all([infoApi, historyApi, blueprintApi])
        .then((response) => {
          // eslint-disable-next-line prefer-destructuring
          state.info = response[0];
          // eslint-disable-next-line prefer-destructuring
          state.history = response[1];
          state.activeHistory = response[1].length > 1 ? response[1].length - 1 : 0;
          state.blueprint = JSON.stringify(response[2], null, 2);
          state.model = response[2].model;

          if (response[2].transformer?.transformers) {
            state.transformers = response[2].transformer.transformers.map((transformer: any) => ({
              key: transformer[0],
              values: transformer[2],
            }));
          }

          state.activeReport = 'test';

          state.isLoading = false;
        })
        .catch(() => {
          state.history = [];
          state.isLoading = false;
        });
    }
  }, [state.url]);

  useEffect(() => {
    if (state.activeReport && state.history.length > 0) {
      const selectedReport: 'test_report' | 'training_report' = `${state.activeReport}_report`;

      state.report = state.history[state.activeHistory][selectedReport];
    }
  }, [state.activeReport, state.activeHistory]);

  const confusionMatrix: any[] = state.report?.confusion_matrix?.labels
    ? state.report.confusion_matrix.labels.map((value, i) => {
        const x: any = { id: value, data: [] };

        state.report?.confusion_matrix.labels.forEach((el, j) => {
          if (state.report && state.report.confusion_matrix.values[i][j] !== null) {
            x.data.push({ x: el, y: state.report.confusion_matrix.values[i][j] });
          }
        });

        return x;
      })
    : [];

  function getScoreData(): LineSerie[] | null {
    const metricToPlot = taskType === 'classification' ? 'accuracy' : 'R2';

    const trainingValues = state.history.map((_history) => _history.training_report.metrics[metricToPlot]);

    const testValues = state.history.map((_history) => _history.test_report.metrics[metricToPlot]);

    if (trainingValues.length < 2 || testValues.length < 2) {
      return null;
    }

    const keys = state.history.map((_history) => formatDate(_history.datetime));

    const toSend = [
      {
        id: 'training',
        data: trainingValues.map((value, i) => ({
          x: keys[i],
          y: value,
        })),
      },
      {
        id: 'test',
        data: testValues.map((value, i) => ({
          x: keys[i],
          y: value,
        })),
      },
    ];

    return toSend;
  }

  const sampleData = useMemo(() => {
    if (state.report && taskType === 'regression') {
      const keys = state.report.scatter.y_pred;
      const values = state.report.scatter.y_true;

      return sampleSize(
        keys.map((value, i) => ({ x: value, y: values[i] })),
        1000,
      );
    }
  }, [state.report]);

  function getScatterData() {
    const toSend = [
      {
        id: 'Values',
        data: sampleData,
      },
    ];

    return toSend;
  }

  function generateBarChart() {
    const keys = Object.keys(state.history[state.activeHistory].tdist.hist);

    const data: Array<{ [key: string]: any }> = [
      {
        value: 'Distribution',
      },
    ];

    keys.forEach((key) => {
      data[0][key] = state.history[state.activeHistory].tdist.hist[key];
    });

    return <Bar data={data} keys={keys} indexBy="value" xLegend="" yLegend="" enableLegend />;
  }

  function generateHistogramChart(decimals = 2) {
    if (state.report) {
      const values = state.report.scatter.y_true;

      const min = d3.min(values, (d) => d);
      const max = d3.max(values, (d) => d);

      const keys = Array.from(Array(binsNumber).keys()).map((k) => `${k}`);

      // @ts-ignore
      const binWidth = (max - min) / binsNumber;

      const binsSteps = keys.map(
        // @ts-ignore
        (v, i) => Math.round((binWidth * i + min) * `1e${decimals}`) / `1e${decimals}`,
      );

      const histogram = d3.bin().thresholds(binsSteps);

      const histogramValues = histogram(values);

      const labels = histogramValues.map((l, i) => `${histogramValues[i].x0}-${histogramValues[i].x1}`);

      const data: Array<{ [key: string]: any }> = [];

      labels.forEach((key, index) => {
        data.push({ bin: key, occurrences: histogramValues[index].length });
      });

      if (state.histogramView) {
        return (
          <Flex w="full" h="full" overflowX="hidden">
            <Bar
              data={data}
              indexBy="bin"
              keys={['occurrences']}
              xLegend=""
              yLegend=""
              animate={false}
              innerPadding={0}
              padding={0.03}
              borderRadius={0}
              gridXValues={11}
              colors={() => histogramBarsColor}
              labelTextColor={histogramBarsLabelColor}
              margin={chartMargins}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -40,
                format: (v) => getAxisTooltip(v),
              }}
            />
          </Flex>
        );
      }

      return (
        <Box w="full" h="full" pos="relative" overflowX="hidden" px={2}>
          <Table size="xs" mt={5}>
            <Thead>
              <Tr fontSize="xs">
                {Object.keys(data[0]).map((k, i) => (
                  <Th key={i} isNumeric={i > 0}>
                    {k}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {data.map((v, i) => (
                <Tr key={i} fontSize="sm">
                  <Td>{v.bin}</Td>
                  <Td isNumeric>{v.occurrences}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      );
    }
  }

  function handleExport(type: 'zip' | 'blueprint') {
    if (state.url) {
      showToast({
        title: 'Exporting predictor...',
        type: 'info',
      });

      gatewayCrud
        .getAll<string | Blob>(type === 'zip' ? urljoin(state.url, 'export') : state.url, {
          headers: {
            'Content-type': `application/${type === 'zip' ? 'octet-stream' : 'json'}`,
          },
          responseType: type === 'zip' ? 'blob' : 'json',
        })
        .then((data) => {
          const payload =
            type === 'zip' ? data : new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });

          saveAs(payload, `${name}.${type === 'zip' ? 'zip' : 'json'}`);
        });
    }
  }

  return (
    <Flex flexDirection="column" my={6} px={6}>
      <Flex justifyContent="space-between" mb={8} pos="relative" zIndex={2}>
        {!evaluate && (
          <Button
            colorScheme="black"
            leftIcon={<RiArrowLeftLine />}
            variant="outline"
            onClick={() =>
              history.push({
                pathname: '/predictors',
                search: `?predictor=${predictorName}`,
              })
            }
          >
            <Text d={{ base: 'none', md: 'block' }}>All models</Text>
          </Button>
        )}
        <Flex>
          <Button
            colorScheme="black"
            ml="2"
            variant="outline"
            isDisabled={state.isLoading}
            onClick={() => (state.showBlueprint = !state.showBlueprint)}
          >
            {state.showBlueprint ? 'Show dashboard' : 'Show blueprint'}
          </Button>
          {!evaluate && (
            <>
              <Button
                colorScheme="black"
                ml="2"
                variant="outline"
                isDisabled={state.isLoading || state.history.length === 0 || state.showBlueprint}
                onClick={() => (state.activeReport = state.activeReport === 'training' ? 'test' : 'training')}
              >
                {state.activeReport === 'training' ? 'Show test results' : 'Show training results'}
              </Button>

              <Menu>
                <MenuButton
                  colorScheme="black"
                  ml="2"
                  as={Button}
                  isDisabled={state.isLoading}
                  variant="outline"
                  rightIcon={<RiArrowDownSLine />}
                >
                  Export
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={() => handleExport('blueprint')}>Blueprint</MenuItem>
                  <MenuItem onClick={() => handleExport('zip')}>Zip</MenuItem>
                </MenuList>
              </Menu>
            </>
          )}
        </Flex>
      </Flex>

      <AsyncLoader isLoading={state.isLoading} label="loading…">
        <>
          {state.showBlueprint ? (
            <CodeEditor mode="json" readOnly value={state.blueprint ?? ''} height="700px" />
          ) : (
            <>
              {state.history.length > 0 ? (
                <>
                  {state.report && state.activeReport && (
                    <Grid templateColumns="repeat(6, 1fr)" gap={8} pb="16">
                      <GridItem colSpan={6}>
                        <Row
                          mx="0"
                          pos="relative"
                          bgSize="cover"
                          cursor="default"
                          overflow="hidden"
                          color={colorCard}
                          bgRepeat="repeat-x"
                          borderRadius="2xl"
                          bgPosition="bottom center"
                          bgImage={`url(${StatBgImage})`}
                          bg={state.activeReport === 'training' ? bgCard : 'gray.400'}
                        >
                          <RowBody pos="relative" zIndex="1">
                            <Heading mb="4" as="h1" size="lg" fontWeight="600" wordBreak="break-all" userSelect="text">
                              {evaluate ? evaluate.info.predictor : name.split('.predictor')[0]}
                            </Heading>

                            {state.info?.description && (
                              <Text
                                alignSelf="flex-start"
                                mb="4"
                                as="h2"
                                size="lg"
                                fontWeight="600"
                                wordBreak="break-all"
                              >
                                {state.info?.description}
                              </Text>
                            )}

                            <Flex>
                              <Box mr="2">Model:</Box>
                              <Box fontWeight="bold">{state.info?.model}</Box>
                            </Flex>

                            <Box>
                              {state.history[state.activeHistory].datetime && (
                                <Flex>
                                  <Box mr="2">Last fit:</Box>
                                  <Box fontWeight="bold">{formatDate(state.history[state.activeHistory].datetime)}</Box>
                                </Flex>
                              )}

                              <Flex>
                                <Box mr="2">{evaluate ? 'Evaluated models' : 'Number of histories'}:</Box>
                                <Box fontWeight="bold">{state.history.length}</Box>
                              </Flex>

                              <Flex d={{ base: 'flex', md: 'none' }}>
                                <Text fontWeight={700}>
                                  {`${capitalize(state.activeReport)} ~ ${
                                    taskType === 'regression'
                                      ? `${state.report.metrics.R2} R2`
                                      : `${state.report.metrics.accuracy}% ACC`
                                  }`}
                                </Text>
                              </Flex>
                            </Box>
                          </RowBody>

                          <Score
                            d={{ base: 'none', md: 'flex' }}
                            score={`${capitalize(state.activeReport)} ~ ${
                              taskType === 'regression'
                                ? `${state.report.metrics.R2} R2`
                                : `${state.report.metrics.accuracy}% ACC`
                            }`}
                            fitted={state.history.length > 0}
                            color="blackAlpha.700"
                            letterSpacing="-.55rem"
                          />
                        </Row>
                      </GridItem>

                      {state.history.length > 1 && (
                        <PredictorDetailsBox colSpan={6} title="History" minH={0} maxH={48}>
                          <Box mb={4} w="100%" mx="auto" pos="relative" overflow="hidden">
                            <Flex pt="0" px="12" pb="10">
                              <Slider
                                min={0}
                                step={1}
                                value={state.activeHistory}
                                aria-label="slider-ex-5"
                                max={state.history.length - 1}
                                onChange={(val) => (state.activeHistory = val)}
                              >
                                <SliderTrack h="1px" bg={bgCard}>
                                  <Box position="relative" right={10} />
                                  <SliderFilledTrack bg={bgCard} />
                                </SliderTrack>
                                <Flex w="100%" zIndex="1" ml="0" pos="relative" justifyContent="space-between">
                                  {state.history
                                    // @ts-ignore
                                    .sort((a, b) => a.datetime - b.datetime)
                                    .map((el, i) => (
                                      <Tooltip key={i} label={formatDate(el.datetime)} aria-label="Copy message">
                                        <Box h="2" w="px" mt="2" bg={bgCard} whiteSpace="nowrap" pos="relative">
                                          <Box
                                            top="20px"
                                            left="8px"
                                            pos="absolute"
                                            fontSize="9px"
                                            fontWeight="600"
                                            color="gray.500"
                                            textAlign="center"
                                            transform="translateX(-50%)"
                                          >
                                            {formatDate(el.datetime)}
                                          </Box>
                                        </Box>
                                      </Tooltip>
                                    ))}
                                </Flex>
                                <SliderThumb _focus={{ outline: 'none' }} boxSize={4} bg={bgCard} />
                              </Slider>
                            </Flex>
                          </Box>
                        </PredictorDetailsBox>
                      )}

                      <PredictorDetailsBox colSpan={{ base: 6, md: 3 }} title="Model information">
                        <Table variant="simple" size="sm" color="gray.500" mb="4">
                          <Thead>
                            <Tr>
                              <Td />
                              <Td />
                            </Tr>
                          </Thead>
                          <Tbody>
                            <Tr>
                              <Td borderColor="gray.600" fontWeight="300">
                                Model
                              </Td>
                              <Td borderColor="gray.600" fontWeight="300">
                                {state.info?.model}
                              </Td>
                            </Tr>
                            {state.model &&
                              Object.entries(state.model)
                                .filter(([key]) => key !== '__klass__')
                                .map(([key, value], i) => (
                                  <Tr key={i}>
                                    <Td borderColor="gray.600" fontWeight="300">
                                      {capitalize(key)}
                                    </Td>
                                    <Td borderColor="gray.600" fontWeight="300">
                                      {value.toString()}
                                    </Td>
                                  </Tr>
                                ))}
                          </Tbody>
                        </Table>
                      </PredictorDetailsBox>

                      {getScoreData() && (
                        <PredictorDetailsBox colSpan={{ base: 6, md: 3 }} rowSpan={1} title="Performance">
                          <Box pos="relative" w="full" h="full">
                            <Line
                              xLegend=""
                              data={getScoreData() as LineSerie[]}
                              yLegend={taskType === 'regression' ? 'R2' : 'Accuracy'}
                            />
                          </Box>
                        </PredictorDetailsBox>
                      )}

                      <PredictorDetailsBox
                        colSpan={{ base: 6, md: 3 }}
                        rowSpan={1}
                        exportable
                        title={taskType === 'classification' ? 'Confusion Matrix' : 'True values / Predicted values'}
                      >
                        <Flex w="full" h="full" id="chartBox" overflow="hidden">
                          {state.activeReport && (
                            <Flex w="full" h="full" color="gray.300" pos="relative" overflow="hidden" flexDir="column">
                              {taskType === 'classification' ? (
                                <Heatmap
                                  data={confusionMatrix}
                                  yLegend="Observed"
                                  xLegend="Predicted"
                                  margin={chartMargins}
                                />
                              ) : (
                                <Flex flexDir="column" pos="relative" w="full" h="full">
                                  <Flex w="full" h="100%" mt={8} overflowY="auto" overflowX="hidden">
                                    <Scatter
                                      // @ts-ignore
                                      data={getScatterData()}
                                      margin={chartMargins}
                                      legends={[]}
                                      yLegend="Observed"
                                      xLegend="Predicted"
                                    />
                                  </Flex>
                                </Flex>
                              )}
                            </Flex>
                          )}
                        </Flex>
                      </PredictorDetailsBox>

                      {taskType === 'regression' ? (
                        <PredictorDetailsBox
                          viewSwitch
                          viewSwitchLabel="Histogram view / Table view"
                          colSpan={{ base: 6, md: 3 }}
                          title={`${capitalize(state.activeReport)} set distribution`}
                          onViewChange={() => (state.histogramView = !state.histogramView)}
                          overflowX="hidden"
                        >
                          <Flex w="full" h="full" color="gray.500" pos="relative" flexDir="column">
                            <Flex
                              top={0}
                              pt={1}
                              pos="absolute"
                              right={0}
                              w="full"
                              h={12}
                              flexDir="column"
                              alignItems="center"
                            >
                              <Flex w="full" justifySelf="start">
                                <Flex alignItems="center">
                                  <Text fontSize="xs" minW={12} mb={0}>
                                    # bins:
                                  </Text>

                                  <NumberInput
                                    maxW={24}
                                    min={2}
                                    step={1}
                                    justifyContent="end"
                                    size="sm"
                                    defaultValue={binsNumber}
                                  >
                                    <NumberInputField ref={binSelector} textAlign="right" />
                                    <NumberInputStepper>
                                      <NumberIncrementStepper />
                                      <NumberDecrementStepper />
                                    </NumberInputStepper>
                                  </NumberInput>

                                  <Button ml={2} size="sm" colorScheme="black" onClick={handleBinSelector}>
                                    Apply
                                  </Button>
                                </Flex>
                              </Flex>
                            </Flex>
                            <Flex w="full" h="100%" mt={8} overflowY="auto">
                              {generateHistogramChart()}
                            </Flex>
                          </Flex>
                        </PredictorDetailsBox>
                      ) : (
                        <PredictorDetailsBox
                          viewSwitch
                          colSpan={{ base: 6, md: 3 }}
                          title="Training set distribution"
                          onViewChange={() => (state.tableView = !state.tableView)}
                        >
                          <Flex w="full" h="full" color="gray.300" mb="4">
                            {state.tableView ? (
                              <>
                                <Flex flexDirection="column" w="full">
                                  {Object.keys(state.history[state.activeHistory].tdist.hist).map((k, i) => (
                                    <Flex
                                      key={i}
                                      px={4}
                                      py={1}
                                      flexShrink={0}
                                      fontSize="sm"
                                      overflow="hidden"
                                      whiteSpace="nowrap"
                                      borderBottom="1px solid"
                                      borderColor="gray.600"
                                    >
                                      {k}
                                    </Flex>
                                  ))}
                                </Flex>
                                <Flex flexDirection="column" w="full">
                                  {Object.values(state.history[state.activeHistory].tdist.hist).map((v, i) => (
                                    <Flex
                                      key={i}
                                      px={4}
                                      py={1}
                                      flexShrink={0}
                                      fontSize="sm"
                                      fontWeight="600"
                                      overflow="hidden"
                                      whiteSpace="nowrap"
                                      justifyContent="flex-end"
                                      borderBottom="1px solid"
                                      borderColor="gray.600"
                                    >
                                      {v}
                                    </Flex>
                                  ))}
                                </Flex>
                              </>
                            ) : (
                              <Box w="full" h="full" pos="relative">
                                {generateBarChart()}
                              </Box>
                            )}
                          </Flex>
                        </PredictorDetailsBox>
                      )}

                      {taskType === 'classification' ? (
                        <PredictorDetailsBox colSpan={{ base: 6, md: 3 }} title="Classification report">
                          {state.report.classification_report ? (
                            <Table variant="simple" size="sm" color="gray.500" mb="4">
                              <Thead>
                                <Tr>
                                  {Object.keys(state.report.classification_report[0]).map((k, i) => (
                                    <Td
                                      key={i}
                                      fontSize="xs"
                                      color="gray.500"
                                      isNumeric={i > 0}
                                      borderColor="gray.600"
                                      fontWeight={i > 0 ? '600' : '400'}
                                    >
                                      {k}
                                    </Td>
                                  ))}
                                </Tr>
                              </Thead>
                              <Tbody>
                                {Object.values(state.report.classification_report).map((r, i) => (
                                  <Tr key={i}>
                                    {Object.values(r).map((v, j) => (
                                      <Td
                                        key={j}
                                        isNumeric={j > 0}
                                        borderColor="gray.600"
                                        fontWeight={j < 1 ? '600' : '300'}
                                      >
                                        {v}
                                      </Td>
                                    ))}
                                  </Tr>
                                ))}
                              </Tbody>
                            </Table>
                          ) : (
                            <Text mt={7} textAlign="center">
                              Classification report not found
                            </Text>
                          )}
                        </PredictorDetailsBox>
                      ) : (
                        <PredictorDetailsBox colSpan={{ base: 6, md: 3 }} title="Regression report">
                          <Flex alignItems="center" color="gray.300" mb="4">
                            <Flex flexDirection="column" w="full">
                              {Object.keys(
                                state.history[state.activeHistory][
                                  `${state.activeReport}_report` as 'training_report' | 'test_report'
                                ].metrics,
                              ).map((k, i) => (
                                <Flex
                                  key={i}
                                  px={4}
                                  py={1}
                                  fontSize="sm"
                                  overflow="hidden"
                                  whiteSpace="nowrap"
                                  borderBottom="1px solid"
                                  borderColor="gray.600"
                                  fontWeight="600"
                                >
                                  {k}
                                </Flex>
                              ))}
                            </Flex>
                            <Flex flexDirection="column" w="full">
                              {Object.values(
                                state.history[state.activeHistory][
                                  `${state.activeReport}_report` as 'training_report' | 'test_report'
                                ].metrics,
                              ).map((v, i) => (
                                <Flex
                                  key={i}
                                  px={4}
                                  py={1}
                                  fontSize="sm"
                                  overflow="hidden"
                                  whiteSpace="nowrap"
                                  justifyContent="flex-end"
                                  borderBottom="1px solid"
                                  borderColor="gray.600"
                                >
                                  {v}
                                </Flex>
                              ))}
                            </Flex>
                          </Flex>
                        </PredictorDetailsBox>
                      )}

                      <PredictorDetailsBox colSpan={{ base: 6, md: 3 }} title="Model features">
                        <Table variant="simple" size="sm" color="gray.500" mb="4">
                          <Thead>
                            <Tr>
                              <Td />
                            </Tr>
                          </Thead>
                          <Tbody>
                            {state.transformers.map((transformer: { key: string; values: string[] }) => (
                              <Fragment key={transformer.key}>
                                <Tr>
                                  <Td borderColor="gray.600" fontSize="lg" fontWeight="900" textTransform="uppercase">
                                    {transformer.key}
                                  </Td>
                                </Tr>
                                {transformer.values.map((value) => (
                                  <Tr key={value}>
                                    <Td borderColor="gray.600" fontWeight="300" pl={7}>
                                      {value}
                                    </Td>
                                  </Tr>
                                ))}
                              </Fragment>
                            ))}
                          </Tbody>
                        </Table>
                      </PredictorDetailsBox>

                      {showHistorySliderInOverlay && state.history.length > 1 && (
                        <Box
                          p="4"
                          mb="4"
                          w="50%"
                          mx="auto"
                          left="50%"
                          bottom="0"
                          pos="fixed"
                          maxW="1000px"
                          zIndex="1090"
                          boxShadow="lg"
                          overflow="hidden"
                          borderRadius="3xl"
                          transform="translateX(-50%)"
                          opacity={hover ? '0.9' : '0.3'}
                          onMouseOver={() => setHover(true)}
                          onMouseLeave={() => setHover(false)}
                          bgGradient={`linear(to-tr, ${state.activeReport === 'training' ? 'pink' : 'gray'}.900, ${
                            state.activeReport === 'training' ? 'pink' : 'gray'
                          }.800)`}
                          style={{ transition: 'opacity 0.25s ease-in-out' }}
                        >
                          <Flex pt="0" px="6">
                            <Slider
                              min={0}
                              step={1}
                              value={state.activeHistory}
                              aria-label="slider-ex-5"
                              max={state.history.length - 1}
                              onChange={(val) => (state.activeHistory = val)}
                            >
                              <SliderTrack h="1px" bg={bgCard}>
                                <Box position="relative" right={10} />
                                <SliderFilledTrack bg={bgCard} />
                              </SliderTrack>
                              <Flex w="100%" zIndex="1" ml="0" pos="relative" justifyContent="space-between">
                                {state.history
                                  // @ts-ignore
                                  .sort((a, b) => a.datetime - b.datetime)
                                  .map((el, i) => (
                                    <Tooltip key={i} label={formatDate(el.datetime)} aria-label="Copy message">
                                      <Box h="2" w="px" mt="2" bg={bgCard} whiteSpace="nowrap" pos="relative">
                                        <Box
                                          top="20px"
                                          left="8px"
                                          pos="absolute"
                                          fontSize="9px"
                                          fontWeight="600"
                                          color="gray.500"
                                          textAlign="center"
                                          transform="translateX(-50%)"
                                        />
                                      </Box>
                                    </Tooltip>
                                  ))}
                              </Flex>
                              <SliderThumb _focus={{ outline: 'none' }} boxSize={4} bg={bgCard} />
                            </Slider>
                          </Flex>
                        </Box>
                      )}
                    </Grid>
                  )}
                </>
              ) : (
                <Flex>
                  <Alert
                    height="200px"
                    variant="subtle"
                    status="warning"
                    textAlign="center"
                    alignItems="center"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <AlertIcon boxSize="40px" mr={0} />
                    <AlertTitle mt={4} mb={1} fontSize="lg">
                      No history found
                    </AlertTitle>
                    <AlertDescription maxWidth="sm">Fit your model and come back!</AlertDescription>
                  </Alert>
                </Flex>
              )}
            </>
          )}
        </>
      </AsyncLoader>
    </Flex>
  );
}
