import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { deleteFromStorage } from '@rehooks/local-storage';
import { ACCESS_TOKEN_STORAGE, REFRESH_TOKEN_STORAGE, TENANT_STORAGE } from 'constants/localStorage';
import { Components, IUser, Resources, Structure } from '../types/resources';

interface ResourcesState extends Resources {
  activeProject: { id: string; name: string } | null;
  loading: boolean;
}

const initialState = {
  templates: [],
  components: [],
  activeProject: null,
  loading: false,
  user: null,
} as ResourcesState;

export const resourcesStore = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    getUserSuccess(state, action: PayloadAction<{ user: IUser }>) {
      const { user } = action.payload;

      state.user = user;
    },
    setComponents(state, action: PayloadAction<Components[]>) {
      state.components = action.payload;
    },
    setTemplates(state, action: PayloadAction<Structure[]>) {
      state.templates = action.payload;
    },
    makeLogoutSuccess(state) {
      deleteFromStorage(ACCESS_TOKEN_STORAGE);
      deleteFromStorage(REFRESH_TOKEN_STORAGE);
      deleteFromStorage(TENANT_STORAGE);

      state.activeProject = null;
      state.user = null;
    },
    setActiveProject(state, action: PayloadAction<{ name: string; id: string } | null>) {
      state.activeProject = action.payload;
    },
    createTemplateSuccess(state, action: PayloadAction<Structure>) {
      state.templates.push(action.payload);
    },
    editTemplateSuccess(state, action) {
      const { oldName, newName } = action.payload;
      const index = state.templates.findIndex((template) => template === oldName);
      state.templates[index] = newName;
    },
    deleteTemplateSuccess(state, action: PayloadAction<{ template: string }>) {
      state.templates = state.templates.filter((template) => template.id !== action.payload.template);
    },
  },
});

export const {
  makeLogoutSuccess,
  getUserSuccess,
  setComponents,
  setTemplates,
  createTemplateSuccess,
  editTemplateSuccess,
  deleteTemplateSuccess,
  setActiveProject,
  setLoading,
} = resourcesStore.actions;
