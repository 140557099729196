import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Flex, Text, Center, Heading, Box, UnorderedList, ListItem } from '@chakra-ui/layout';
import { useHistory } from 'react-router';
import { baseURL } from 'services/api';
import { LogoLabel } from 'shared/ImageSprite';
import { Button } from '@chakra-ui/button';
import { Modal, ModalBody, ModalContent, ModalHeader } from '@chakra-ui/modal';
import { InputField } from 'shared/PasswordChange';
import { Link, Checkbox, SimpleGrid, Popover, PopoverTrigger, PopoverContent, PopoverBody } from '@chakra-ui/react';
import { useDebouncedCallback } from 'use-debounce';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import urljoin from 'url-join';
import { RiInformationFill } from 'react-icons/ri';
import useLocalStorage from '@rehooks/local-storage';
import { ACCESS_TOKEN_STORAGE } from 'constants/localStorage';

export function SignUp() {
  const history = useHistory();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [registrationCompleted, setRegistrationCompleted] = useState(false);
  const [alreadyExists, setAlreadyExists] = useState({
    email: false,
    username: false,
  });
  const [newUser, setNewUser] = useState({
    username: '',
    name: '',
    family_name: '',
    email: '',
    location: '',
    company: '',
    employment: '',
    password: '',
    confirm_password: '',
    newsletter: false,
    privacy_policy_agreement: false,
  });

  const [accessToken] = useLocalStorage<string>(ACCESS_TOKEN_STORAGE);

  const strongRegex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$');
  const usernameRegex = new RegExp('^(?=.{4,20}$)(?![_.])(?!.*[_.]{2})([a-zA-Z0-9]+[a-zA-Z0-9._]+[a-zA-Z0-9]+)$');

  const arePasswordMatched = newUser.password === newUser.confirm_password;
  const isUsernameValid = usernameRegex.test(newUser.username);
  const isNewPasswordStrong = strongRegex.test(newUser.password);

  useEffect(() => {
    if (accessToken) {
      history.push('/welcome');
    }
  }, [accessToken]);

  function handleSignUp(e: FormEvent) {
    e.preventDefault();

    setLoading(true);

    const baseurl = window.location.origin;

    axios
      .post(urljoin(baseURL, '/users'), { ...newUser, baseurl })
      .then(() => {
        setError(null);
        setLoading(false);
        setRegistrationCompleted(true);
      })
      .catch((err) => {
        setError(err.response?.data.exception || 'Error during user creation');
        setLoading(false);
      });
  }

  const handleExistsFieldChange = useDebouncedCallback((field: 'email' | 'username', value: string) => {
    axios.post<boolean>(urljoin(baseURL, '/users/exist'), { [field]: value }).then(({ data }) => {
      setAlreadyExists((prevState) => ({ ...prevState, [field]: data }));
    });

    setNewUser({ ...newUser, [field]: value });
  }, 500);

  function handleChangeValue(event: ChangeEvent<HTMLInputElement>) {
    const { name, value, type, checked } = event.target;

    if (type === 'checkbox') {
      setNewUser({ ...newUser, [name]: checked });
      return;
    }

    setNewUser({ ...newUser, [name]: value });
  }

  function checkUsername() {
    if (newUser.username) {
      if (alreadyExists.username) {
        return 'Username is not available';
      }

      if (!isUsernameValid) {
        return "Username doesn't meet the requirements";
      }
    }

    return false;
  }

  return (
    <Modal isOpen onClose={() => false} isCentered size="2xl" closeOnOverlayClick={false} closeOnEsc={false}>
      <ModalContent borderRadius="2xl" m={0}>
        <ModalHeader>
          <Flex my={8} justifyContent="center">
            <LogoLabel width="90" />
          </Flex>
        </ModalHeader>
        <ModalBody px={8}>
          {loading ? (
            <Center>
              <Heading fontSize="md" mt={4} mb={8}>
                Submitting...
              </Heading>
            </Center>
          ) : (
            <>
              {registrationCompleted ? (
                <Center flexDir="column">
                  <Heading fontSize="xl" pb={2}>
                    Registration completed!
                  </Heading>
                  <Text maxW="66%" textAlign="center" mb={4}>
                    Check your mailbox and follow the link we sent you and{' '}
                    <Link as={RouterLink} textDecor="underline" to="/login" title="Terms of service">
                      login
                    </Link>
                    !
                  </Text>
                </Center>
              ) : (
                <>
                  <form onSubmit={handleSignUp}>
                    <Box mb={4} pos="relative" w="calc(50% - 4px)">
                      <InputField
                        autoComplete="off"
                        label="Username"
                        name="username"
                        placeholder="Username"
                        defaultValue=""
                        onChange={(e) => handleExistsFieldChange('username', e.target.value)}
                        type="text"
                        isRequired
                        isInvalid={alreadyExists.username}
                        helperText={checkUsername()}
                      />
                      <Popover placement="bottom-end">
                        <PopoverTrigger>
                          <Button variant="link" minW={0} pos="absolute" top={0} right={0}>
                            <RiInformationFill />
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverBody>
                            <Box color="gray.500">
                              <Text fontSize="xs">Your username must:</Text>
                              <UnorderedList fontSize="xs">
                                <ListItem>start and end with a character</ListItem>
                                <ListItem>not contain whitespaces or tabs</ListItem>
                                <ListItem>contain at least 4 and not more than 20 characters</ListItem>
                              </UnorderedList>
                            </Box>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Box>
                    <SimpleGrid columns={2} spacingX={2} spacingY={4}>
                      <InputField
                        label="First name"
                        name="name"
                        placeholder="First name"
                        value={newUser.name}
                        onChange={handleChangeValue}
                        type="text"
                        isRequired
                      />

                      <InputField
                        label="Last name"
                        name="family_name"
                        placeholder="Last name"
                        value={newUser.family_name}
                        onChange={handleChangeValue}
                        type="text"
                        isRequired
                      />

                      <InputField
                        label="Email"
                        name="email"
                        placeholder="Email"
                        defaultValue=""
                        onChange={(e) => handleExistsFieldChange('email', e.target.value)}
                        type="email"
                        isRequired
                        isInvalid={alreadyExists.email}
                        helperText={alreadyExists.email && 'Email address already in use'}
                      />

                      <InputField
                        label="Location"
                        name="location"
                        placeholder='es. "Rome"'
                        value={newUser.location}
                        onChange={handleChangeValue}
                        type="text"
                      />

                      <InputField
                        label="Company"
                        name="company"
                        placeholder="Your company or your employer"
                        value={newUser.company}
                        onChange={handleChangeValue}
                        type="text"
                      />

                      <InputField
                        label="Role"
                        name="employment"
                        placeholder="Your role or position"
                        value={newUser.employment}
                        onChange={handleChangeValue}
                        type="text"
                      />
                      <Box pos="relative" mb={6}>
                        <InputField
                          label="Password"
                          name="password"
                          placeholder="A strong password"
                          value={newUser.password}
                          _focus={{
                            borderColor: newUser.password && isNewPasswordStrong && 'green.400',
                          }}
                          helperText={
                            newUser.password && !isNewPasswordStrong ? "Password doesn't meet the requirements" : null
                          }
                          onChange={handleChangeValue}
                          isRequired
                        />
                        <Popover placement="bottom-end">
                          <PopoverTrigger>
                            <Button variant="link" minW={0} pos="absolute" top={0} right={0}>
                              <RiInformationFill />
                            </Button>
                          </PopoverTrigger>
                          <PopoverContent>
                            <PopoverBody>
                              <Box color="gray.500">
                                <Text fontSize="xs">Your password must contains:</Text>
                                <UnorderedList fontSize="xs">
                                  <ListItem>at least 8 characters</ListItem>
                                  <ListItem>at least one lowercase letter</ListItem>
                                  <ListItem>at least one capital letter</ListItem>
                                  <ListItem>at least one number</ListItem>
                                  <ListItem>at least one special character</ListItem>
                                </UnorderedList>
                              </Box>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Box>

                      <InputField
                        label="Confirm password"
                        name="confirm_password"
                        placeholder="Confirm your password"
                        value={newUser.confirm_password}
                        onChange={handleChangeValue}
                        _focus={{
                          borderColor:
                            newUser.password && newUser.confirm_password && arePasswordMatched && 'green.400',
                        }}
                        helperText={
                          newUser.password &&
                          newUser.confirm_password &&
                          !arePasswordMatched &&
                          'Passwords does not match'
                        }
                        isRequired
                      />
                    </SimpleGrid>
                    <Box mb={2}>
                      <Checkbox
                        name="newsletter"
                        size="sm"
                        alignItems="flex-start"
                        pt={0.75}
                        onChange={handleChangeValue}
                      >
                        <Text fontSize=".6rem" mt={-0.75}>
                          By checking this box, you agree to receive emails for marketing from LOKO AI.
                        </Text>
                      </Checkbox>
                    </Box>
                    <Box mb={2}>
                      <Checkbox
                        name="privacy_policy_agreement"
                        size="sm"
                        alignItems="flex-start"
                        pt={0.75}
                        onChange={handleChangeValue}
                        isRequired
                      >
                        <Text fontSize=".6rem" mt={-0.75}>
                          By checking this box, you agree to our{' '}
                          <Link textDecor="underline" href="https://loko-ai.com/" title="Terms of service" isExternal>
                            Terms of Service
                          </Link>{' '}
                          and{' '}
                          <Link textDecor="underline" href="https://loko-ai.com/" title="Privacy policy" isExternal>
                            Privacy Policy
                          </Link>
                          , and consent to data transfer, hosting, and processing outside of the EU.
                        </Text>
                      </Checkbox>
                    </Box>
                    <Button
                      size="sm"
                      variant="solid"
                      isFullWidth
                      isDisabled={
                        !isNewPasswordStrong || !arePasswordMatched || !!checkUsername() || alreadyExists.email
                      }
                      mt={2}
                      mb={2}
                      type="submit"
                    >
                      Sign up
                    </Button>
                  </form>

                  {error && (
                    <Text textAlign="center" mb={4} color="red.400">
                      {error}
                    </Text>
                  )}

                  <Flex mb={6} justifyContent="center" color="gray.500">
                    <Text fontWeight={600} fontSize="xs" mr={2}>
                      or
                    </Text>
                    <Button
                      size="xs"
                      variant="link"
                      fontWeight={600}
                      color="gray.500"
                      colorScheme="black"
                      onClick={() => history.push('/login')}
                    >
                      back to login
                    </Button>
                  </Flex>
                </>
              )}
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
