import { ColorMode } from '@chakra-ui/react';

export const Tabs = {
  parts: ['tabs', 'tablist', 'tab', 'tabpanels', 'tabpanel'],
  sizes: {
    xs: {
      tablist: {
        fontSize: '11px',
      },
    },
  },
  variants: {
    enclosed: ({ colorMode }: { colorMode: ColorMode }) => ({
      tab: {
        _selected: {
          borderBottomColor: colorMode === 'dark' ? 'gray.900' : 'gray.100',
        },
        _disabled: {
          opacity: 0.5,
          cursor: 'not-allowed',
        },
      },
    }),
    console: ({ colorMode, orientation }: { colorMode: ColorMode; orientation: string }) => ({
      tab: {
        borderLeft: orientation === 'vertical' ? '1px solid' : '0',
        mb: '-1px',
        fontSize: '14px',
        color: colorMode === 'dark' ? 'gray.500' : 'gray.500',
        _hover: {
          color: colorMode === 'dark' ? 'gray.300' : 'gray.700',
          borderColor: 'currentColor',
        },
        _selected: {
          color: colorMode === 'dark' ? 'gray.200' : 'gray.900',
          borderColor: 'currentColor',
        },
        _disabled: {
          opacity: 0.5,
          cursor: 'not-allowed',
        },
        _focus: {
          zIndex: 1,
          boxShadow: 'none',
        },
      },
      tablist: {
        color: colorMode === 'dark' ? 'gray.300' : 'gray.600',
        // background: colorMode === 'dark' ? 'gray.900' : 'gray.50',
        bg: 'transparent',
        borderLeft: orientation === 'vertical' ? '1px solid' : '0',
        borderRight: orientation === 'vertical' ? '1px solid' : '0',
        borderBottom: orientation === 'vertical' ? '0' : '0',
        borderColor: colorMode === 'dark' ? 'gray.400' : 'gray.200',
        alignItems: 'center',
      },
      tabpanels: {
        bg: 'transparent',
        // background: colorMode === 'dark' ? 'gray.900' : 'gray.50',
      },
      tabpanel: {
        h: 'full',
        padding: '0',
        overflow: 'auto',
        bg: 'transparent',
        // background: colorMode === 'dark' ? 'gray.900' : 'gray.50',
        borderColor: colorMode === 'dark' ? 'red.400' : 'red.200', // borderColor={useColorModeValue('gray.200', 'gray.400')}
      },
    }),
  },
};
