/* eslint-disable react/jsx-key */
import { useCallback, useState, useEffect, useMemo } from 'react';
import {
  Box,
  Table as CTable,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  IconButton,
  Flex,
  TagCloseButton,
  TagLabel,
  Tag,
  Tooltip,
  chakra,
} from '@chakra-ui/react';
import { Dialog, useCompositeState } from 'ds4biz-core';
import { RiArrowDownSLine, RiArrowUpSLine, RiFilter2Line, RiSettings2Line } from 'react-icons/ri';
import { usePagination, useSortBy, useTable } from 'react-table';
import urljoin from 'url-join';
import { requiredLabel } from 'constants/form';
import { AsyncLoader } from 'shared/AsyncLoader';
import { useConfirm } from 'shared/ConfirmerProvider';
import { Form } from 'shared/Form';
import { MultiKeyValueField } from 'shared/Form/MultiKeyValue';
import { Pagination } from 'shared/Pagination';
import { getWindowDimensions } from 'utils/misc';
import { orchestratorCrud } from 'services/api';
import { Charts } from './Charts';
import { Overview } from './Overview';

const conditions = [
  '=',
  '!=',
  '<',
  '>',
  '<=',
  '>=',
  // "Biggest",
  // "Smallest",
  // "Contain",
  // "Does not contain",
  // "Starts with",
  // "Does no starts with",
  // "Ends with",
  // "Does not ends with",
];

const options = {
  styles: {
    tableStyle: {
      borderSpacing: 0,
      fontFamily: "'Helvetica Neue', sans-serif",
      margin: 0,
    },
    headCellStyle: {
      border: 0,
      padding: '.5rem',
      textAlign: 'left' as const,
      position: 'relative' as const,
      paddingRight: '1rem',
      fontSize: '0.75rem',
      borderBottom: '1px solid',
      borderColor: 'rgba(128,128,128,.25)',
      justifyContent: 'space-between',
    },
    sortingIcon: {
      position: 'absolute' as const,
      marginLeft: '4px',
      marginTop: '2px',
    },
    cellStyle: {
      border: 0,
      padding: '.5rem',
      textAlign: 'left',
      fontSize: '0.8125rem',
      borderBottom: '1px solid',
      borderColor: 'rgba(128,128,128,.25)',
    },
    noDataStyle: {
      border: 0,
      padding: '.8rem',
      textAlign: 'center' as const,
      fontStyle: 'italic',
      fontSize: '1rem',
    },
    stripedRowStyle: {
      backgroundColor: 'rgba(128,128,128,0.05)',
    },
    tableCaptionStyle: {
      marginTop: 0,
      padding: '1rem 0',
      borderBottom: '1px solid',
      borderColor: 'rgba(128,128,128,.25)',
      // backgroundColor: "rgba(0,0,0,.025)",
    },
  },
};

interface Rule {
  id: string;
  column: string;
  type: string;
  condition: string;
  value: string;
}

interface TableProps {
  path: string;
  variant?: 'unstyled' | 'simple' | 'striped';
  size?: 'sm' | 'md' | 'lg';
}

export function Table({ path, variant = 'simple', size = 'sm', ...rest }: TableProps) {
  const confirm = useConfirm();

  const state: {
    columns: Array<{ Header: string; accessor: string }>;
    data: Array<{ [key: string]: any }>;
    initialData: Array<{ [key: string]: any }>;
    rules: {
      rules: Rule[];
    };
    sort: Array<{ id: string; desc?: boolean }>;
    isLoading: boolean;
    drawerOpen: boolean;
    rulesOpen: boolean;
    config: {
      nrows: number;
      separator: string;
    };
  } = useCompositeState({
    columns: [],
    initialData: [],
    data: [],
    isLoading: false,
    drawerOpen: false,
    rules: null,
    rulesOpen: false,
    sort: null,
    config: {
      nrows: 100,
      separator: ',',
    },
  });

  // Memoize columns/data (required for REACT-TABLE)
  const fetchData = useCallback(() => {
    state.isLoading = true;

    orchestratorCrud
      .getAll<Array<{ [key: string]: any }>>(urljoin('preview', path), { params: state.config })
      .then((data) => {
        if (data.length > 0) {
          const columnsArray = Object.keys(data[0]).map((column) => ({
            Header: column,
            accessor: column,
          }));

          state.columns = columnsArray;
          state.initialData = data;
          state.data = data;
        }

        state.isLoading = false;
      });
  }, [path, state.config]);

  useEffect(() => {
    fetchData();
  }, [state.config]);

  function filterData(data: Array<{ [key: string]: any }>, rules: Rule[]) {
    let newData = [...data];

    rules.forEach((rule) => {
      const { column, type, condition, value } = rule;

      function parseValue(_value: string | boolean | number) {
        if (type === 'Boolean') {
          return Boolean(_value);
        }

        if (type === 'Numeric') {
          return Number(_value);
        }

        return _value.toString();
      }

      const ruleValue = parseValue(value);

      switch (condition) {
        case '=': {
          newData = newData.filter((row) => parseValue(row[column]) === ruleValue);
          break;
        }
        case '!=': {
          newData = newData.filter((row) => parseValue(row[column]) !== ruleValue);
          break;
        }
        case '<': {
          newData = newData.filter((row) => parseValue(row[column]) < ruleValue);
          break;
        }
        case '<=': {
          newData = newData.filter((row) => parseValue(row[column]) <= ruleValue);
          break;
        }
        case '>': {
          newData = newData.filter((row) => parseValue(row[column]) > ruleValue);
          break;
        }
        case '>=': {
          newData = newData.filter((row) => parseValue(row[column]) >= ruleValue);
          break;
        }
      }
    });

    return newData;
  }

  function handleSubmit(values: { rules: Rule[] }) {
    if (values) {
      if (values.rules) {
        state.data = filterData(state.initialData, values.rules);
      } else {
        state.data = state.initialData;
      }

      state.rules = values;
    }

    state.rulesOpen = false;
  }

  function handleDeleteFilter(ruleId: string) {
    const newRules = {
      rules: state.rules.rules.filter((rule) => rule.id !== ruleId),
    };

    handleSubmit(newRules);
  }

  async function handleSettingsChange() {
    const response = await confirm({
      title: 'CSV settings',
      settings: [
        {
          name: 'separator',
          placeholder: 'Choose separator',
          label: 'Separator',
          type: 'text',
          validation: {
            required: requiredLabel,
          },
        },
        {
          name: 'nrows',
          placeholder: 'Rows number',
          label: 'Rows number',
          type: 'number',
          validation: {
            required: requiredLabel,
            min: {
              value: 1,
              message: 'Minimum value is 1',
            },
          },
        },
      ],
      defaultValues: {
        separator: state.config.separator,
        nrows: state.config.nrows,
      },
    });

    if (response.separator) {
      state.config = {
        separator: response.separator,
        nrows: response.nrows,
      };
    }
  }

  function sortedData() {
    if (!state.sort || state.sort.length === 0) {
      return state.data;
    }

    let updatedData = [...state.data];
    state.sort.forEach((sort) => {
      updatedData = updatedData.sort((a, b) => {
        // If numbers we parse them
        if (!Number.isNaN(a[sort.id]) && !Number.isNaN(b[sort.id])) {
          return +a[sort.id] > +b[sort.id] ? 1 : -1;
        }

        return a[sort.id] > b[sort.id] ? 1 : -1;
      });

      if (sort.desc) {
        updatedData = updatedData.reverse();
      }
    });

    return updatedData;
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: state.columns,
      data: state.data,
      initialState: { pageIndex: 0 },
      useControlledState: (table: any) =>
        useMemo(() => {
          state.sort = table.sortBy;
          return table;
        }, [table]),
    } as any,
    useSortBy,
    usePagination,
  ) as any;

  const [tableSize, setTableSize] = useState({
    height: 0,
    width: 0,
  });

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setTableSize({
      height: windowDimensions.innerHeight - 322,
      width: windowDimensions.innerWidth - 124,
    });
  }, [windowDimensions]);

  function getSorting(column: { isSorted: boolean; isSortedDesc: boolean }) {
    if (column.isSorted) {
      if (column.isSortedDesc) {
        return <RiArrowDownSLine />;
      }

      return <RiArrowUpSLine />;
    }

    return '';
  }

  return (
    <Box id="table" w="full" {...rest}>
      <Tabs size="sm">
        <TabList margin="1rem 0 1rem 0">
          <Tab>Data</Tab>
          <Tab>Overview</Tab>
          <Tab>Charts</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px="0" pb="0">
            <AsyncLoader label="Loading content" isLoading={state.isLoading}>
              <Flex mb="2">
                <IconButton
                  aria-label="Show settings"
                  size="sm"
                  mr="3"
                  icon={<RiSettings2Line />}
                  onClick={handleSettingsChange}
                />
                <IconButton
                  aria-label="Open rules"
                  size="sm"
                  mr="4"
                  icon={
                    <>
                      <RiFilter2Line />
                      {state.rules?.rules && state.rules.rules.length > 0 && (
                        <chakra.span
                          pos="absolute"
                          top={0}
                          right={0}
                          display="inline-flex"
                          alignItems="center"
                          justifyContent="center"
                          px={2}
                          py={1}
                          fontSize="xs"
                          fontWeight="bold"
                          lineHeight="none"
                          color="red.100"
                          transform="translate(50%,-50%)"
                          bg="red.600"
                          rounded="full"
                        >
                          {state.rules.rules.length}
                        </chakra.span>
                      )}
                    </>
                  }
                  onClick={() => (state.rulesOpen = true)}
                />

                {state.rules?.rules &&
                  state.rules.rules.map((rule) => (
                    <Tooltip
                      key={rule.id}
                      placement="top"
                      label={`${rule.column} (${rule.type}) ${rule.condition} ${rule.value}`}
                    >
                      <Tag size="md" borderRadius="full" variant="solid" mr="2">
                        <TagLabel>{rule.column}</TagLabel>
                        <TagCloseButton onClick={() => handleDeleteFilter(rule.id)} />
                      </Tag>
                    </Tooltip>
                  ))}
              </Flex>
              <Box
                w="full"
                height={tableSize.height}
                overflow="auto"
                style={{
                  background: ' #f8f9fa !important',
                  padding: ' 16px !important',
                  marginBottom: ' 8px !important',
                  borderBottom: ' 2px solid #f1f3f5 !important',
                }}
              >
                <CTable
                  variant={variant}
                  size={size}
                  style={options.styles.tableStyle}
                  userSelect="text"
                  {...getTableProps()}
                >
                  <Thead>
                    {headerGroups.map((headerGroup: any) => (
                      <Tr {...headerGroup.getHeaderGroupProps()} whiteSpace="nowrap">
                        {headerGroup.headers.map((column: any) => (
                          <Th
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            cursor="pointer"
                            style={options.styles.headCellStyle}
                            textTransform="none"
                          >
                            <span>{column.render('Header')}</span>
                            <span style={options.styles.sortingIcon}>{getSorting(column)}</span>
                          </Th>
                        ))}
                      </Tr>
                    ))}
                  </Thead>

                  <Tbody {...getTableBodyProps()}>
                    {page.map((row: any) => {
                      prepareRow(row);
                      return (
                        <Tr {...row.getRowProps()}>
                          {row.cells.map((cell: any) => (
                            <Td style={options.styles.cellStyle} {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </Td>
                          ))}
                        </Tr>
                      );
                    })}

                    {state.data && state.data.length === 0 && (
                      <Tr>
                        <Td style={options.styles.noDataStyle} colSpan={state.columns.length}>
                          No data found
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </CTable>
              </Box>

              <Box mt="1">
                <Pagination
                  current={pageIndex}
                  itemsPerPage={pageSize}
                  total={state.data.length}
                  onBack={previousPage}
                  onForward={nextPage}
                  onRangeChange={(number) => setPageSize(number)}
                />
              </Box>
            </AsyncLoader>
          </TabPanel>
          <TabPanel px="0">
            <Overview data={state.data} columns={state.columns} />
          </TabPanel>
          <TabPanel px="0">
            <Charts data={sortedData()} columns={state.columns.map((column) => column.Header)} />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Dialog
        title="Rules"
        isOpen={state.rulesOpen}
        closeButton={false}
        size="2xl"
        onClose={() => (state.rulesOpen = false)}
      >
        <Form
          settings={[
            {
              name: 'rules',
              hideLabel: true,
              type: 'multiKeyValue',
              fields: [
                {
                  name: 'column',
                  placeholder: 'Column',
                  type: 'select',
                  options: state.columns.map((column) => column.Header),
                  validation: {
                    required: requiredLabel,
                  },
                },
                {
                  name: 'type',
                  placeholder: 'Column type',
                  type: 'select',
                  options: ['String', 'Numeric', 'Boolean'],
                  validation: {
                    required: requiredLabel,
                  },
                },
                {
                  name: 'condition',
                  placeholder: 'Condition',
                  type: 'select',
                  options: conditions,
                  validation: {
                    required: requiredLabel,
                  },
                },
                {
                  name: 'value',
                  placeholder: 'Value',
                  validation: {
                    required: requiredLabel,
                  },
                },
              ],
              addLabel: 'Add rule',
            } as MultiKeyValueField,
          ]}
          defaultValues={state.rules}
          onSubmit={handleSubmit}
        />
      </Dialog>
    </Box>
  );
}
