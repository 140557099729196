import { useColorModeValue } from '@chakra-ui/react';
import { BarDatum, BarSvgProps, ResponsiveBar } from '@nivo/bar';
import { getAxisTooltip } from './constants';

interface BarProps extends Omit<BarSvgProps<BarDatum>, 'height' | 'width'> {
  enableLegend?: boolean;
  xLegend?: string;
  yLegend?: string;
}

export function Bar({ keys, data, xLegend, yLegend, enableLegend, ...rest }: BarProps) {
  const textColor = useColorModeValue('#222222', '#C9C9C9');
  const gridColor = useColorModeValue('#ddd', '#444');
  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      groupMode="grouped"
      theme={{
        fontFamily: 'Share Tech Mono',
        textColor,
        grid: {
          line: {
            stroke: gridColor,
            strokeWidth: 1,
          },
        },
      }}
      margin={{ top: 5, right: 130, bottom: 40, left: 50 }}
      innerPadding={4}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={['#F74B65', '#FC8930', '#F9C74F', '#90BE6D', '#43AA8B', '#4D908E', '#577590', '#277DA1']}
      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      axisBottom={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        legend: xLegend ?? 'X',
        legendPosition: 'middle',
        legendOffset: 32,
        format: (v) => getAxisTooltip(v),
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        legend: yLegend ?? 'Y',
        legendPosition: 'middle',
        legendOffset: -40,
        format: (v) => getAxisTooltip(v),
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      legends={
        enableLegend
          ? [
              {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]
          : []
      }
      animate={false}
      {...rest}
    />
  );
}
