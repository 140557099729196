import { StatGroup } from '@chakra-ui/react';
import { CustomStat } from 'shared/CustomStat';

function countWords(str: string) {
  return str.trim().split(/\s+/).length;
}

function getRowsType(columns: Array<{ Header: string; accessor: string }>, data: Record<string, unknown>[]) {
  const values: {
    [column: string]: string[];
  } = {};

  let text = 0;
  let categorical = 0;
  let numerical = 0;
  let boolean = 0;
  let notDefined = 0;

  // Format data to use them
  columns.forEach(({ accessor }) => {
    values[accessor] = data.map((element: any) => element[accessor]);
  });

  Object.keys(values).forEach((column) => {
    // If elements length without Null values is 0 then the row is undefined
    if (data.filter((element: any) => element[column]).length === 0) {
      notDefined += notDefined;
    } else {
      switch (true) {
        case typeof values[column][0] === 'string':
          // If > 4 is a phrase
          if (countWords(values[column][0]) <= 4) {
            categorical += categorical;
          } else {
            text += text;
          }
          break;
        case typeof values[column][0] === 'number':
          numerical += numerical;
          break;
        case typeof values[column][0] === 'boolean': {
          boolean += boolean;
          break;
        }
      }
    }
  });

  return { text, categorical, numerical, boolean, notDefined };
}

export function Overview({ columns, data }: OverviewProps) {
  const { notDefined, categorical, numerical, boolean, text } = getRowsType(columns, data);

  function getDuplicateRows() {
    const result = Object.values(
      data.reduce((acc: any, e: any) => {
        const k = JSON.stringify(e);
        if (!acc[k]) acc[k] = { ...e, count: 1 };
        else acc[k].count += 1;
        return acc;
      }, {}),
    )
      .filter((row: any) => row.count > 1)
      .map((row: any) => row.count)
      .reduce((a, b) => a + b, 0);

    return result;
  }

  function getNaNCount() {
    const result = data.filter(
      (obj) =>
        Object.values(obj).includes(null) || Object.values(obj).includes(undefined) || Object.values(obj).includes(''),
    );

    return result.length;
  }

  const stats = [
    {
      title: 'Rows',
      value: data.length,
    },
    {
      title: 'Columns',
      value: columns.length,
    },
    {
      title: 'Duplicate rows',
      value: getDuplicateRows(),
    },
    {
      title: 'NaN count',
      value: getNaNCount(),
    },
    {
      title: 'Categorical',
      value: categorical,
    },
    {
      title: 'Text',
      value: text,
    },
    {
      title: 'Numerical',
      value: numerical,
    },
    {
      title: 'Boolean',
      value: boolean,
    },
    {
      title: 'Undefined',
      value: notDefined,
    },
  ];

  return (
    <StatGroup d="flex" flexWrap="wrap" justifyContent="flex-start">
      {stats.map((stat, index) => (
        <CustomStat key={index} stat={stat} />
      ))}
    </StatGroup>
  );
}

interface OverviewProps {
  columns: Array<{ Header: string; accessor: string }>;
  data: Record<string, unknown>[];
}
