import { ButtonHTMLAttributes } from 'react';
import { Flex, useColorModeValue, Icon, Menu, MenuButton, Button, MenuList, MenuItem } from '@chakra-ui/react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { RiArrowDownSLine } from 'react-icons/ri';

const paginationValues = [10, 20, 50, 100];

interface PagButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  active?: boolean;
  isDisabled?: boolean;
}

function PagButton({ active, children, ...rest }: PagButtonProps) {
  const activeStyle = {
    bg: useColorModeValue('blue.600', 'blue.500'),
    color: useColorModeValue('white', 'gray.200'),
  };

  return (
    <Button
      mx={1}
      px={1}
      py={0}
      size="sm"
      variant="ghost"
      color={useColorModeValue('gray.700', 'gray.200')}
      {...(active && activeStyle)}
      {...rest}
    >
      {children}
    </Button>
  );
}

interface PaginationProps {
  total: number;
  itemsPerPage: number;
  current: number;
  onBack: () => void;
  onForward: () => void;
  onRangeChange: (range: number) => void;
  showRange?: boolean;
}

export function Pagination({
  total,
  itemsPerPage,
  current,
  onBack,
  onForward,
  onRangeChange,
  showRange = true,
  ...rest
}: PaginationProps) {
  const iconBg = useColorModeValue('gray.700', 'gray.200');
  const pages = total > itemsPerPage ? Math.ceil(total / itemsPerPage) : 1;

  return (
    <Flex w="full" alignItems="center" justifyContent="space-between" {...rest}>
      <Flex>
        {showRange && (
          <Menu>
            <MenuButton ml={1} as={Button} variant="ghost" rightIcon={<RiArrowDownSLine />} size="sm">
              {itemsPerPage} / page
            </MenuButton>

            <MenuList>
              {paginationValues.map((number) => (
                <MenuItem key={number} onClick={() => onRangeChange(number)}>
                  {number} / page
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        )}
      </Flex>
      <Flex>
        <Flex alignItems="center">
          <PagButton isDisabled={current === 0} onClick={onBack}>
            <Icon as={IoIosArrowBack} color={iconBg} boxSize="4" />
          </PagButton>
          <Flex fontWeight="600" fontSize="xs" mx="2" opacity={pages > 1 ? 1 : 0.4}>
            {current + 1} / {pages}
          </Flex>
          <PagButton isDisabled={current === pages - 1} onClick={onForward}>
            <Icon as={IoIosArrowForward} color={iconBg} boxSize="4" />
          </PagButton>
        </Flex>
      </Flex>
    </Flex>
  );
}
