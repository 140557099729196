import { Box, Flex } from '@chakra-ui/react';

interface ColorPickerProps {
  value: string;
  onChange: (color: string) => void;
  palette?: string[];
}

const modulus = 4;
const commentsPalette = ['#F74B65', '#FC8930', '#F9C74F', '#90BE6D', '#43AA8B', '#4D908E', '#577590', '#277DA1'];

export function ColorPicker({ value, onChange, palette = commentsPalette }: ColorPickerProps) {
  return (
    <Flex mt="4" borderRadius="4px" overflow="hidden">
      {palette.map((color) => (
        <Flex
          key={color}
          fill={color}
          bg={color}
          width={modulus}
          height={modulus}
          flex="1 1 100%"
          cursor="pointer"
          justifyContent="center"
          onClick={() => onChange(color)}
        >
          <Box>
            <Flex
              cursor="pointer"
              width={4}
              height={4}
              flex="1 1 100%"
              boxShadow="none"
              _focus={{ boxShadow: '0 0 0 #000' }}
              _hover={{ opacity: '0.1' }}
            >
              {value === color && (
                <Box
                  width={modulus / 4}
                  height={modulus / 4}
                  borderRadius="100%"
                  background="#000"
                  fill="#000"
                  mt="50%"
                  ml="50%"
                  style={{
                    transform: 'translate(-50%, -50%)',
                  }}
                />
              )}
            </Flex>
          </Box>
        </Flex>
      ))}
    </Flex>
  );
}
