import { ReactNode, useEffect, useState } from 'react';
import { Alert, AlertIcon, Flex, Grid, GridItem, Text, useColorModeValue } from '@chakra-ui/react';
import { useCompositeState } from 'ds4biz-core';
import { requiredLabel } from 'constants/form';
import { Form } from 'shared/Form';
import { Bar, Line, Pie } from 'shared/Plot';
import { getWindowDimensions } from 'utils/misc';

interface ChartsProps {
  data: Array<{ [key: string]: any }>;
  columns: string[];
}

export function Charts({ data = [], columns = [] }: ChartsProps) {
  const plotWrapperBg = useColorModeValue('#f5f5f5', '#232323');
  const plotBg = useColorModeValue('#EfEfEf', '#343434');
  const plotTextColor = useColorModeValue('#A9A9A9', '#535353');

  const [plotSize, setPlotSize] = useState({
    height: 0,
    width: 0,
  });

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setPlotSize({
      height: windowDimensions.innerHeight - 310,
      width: windowDimensions.innerHeight + 40,
    });
  }, [windowDimensions]);

  const state: {
    submitted: boolean;
    chart: ReactNode;
    defaultData: {
      columns: Array<{ column: string; id: string }>;
      type: string;
      chart: string;
    };
  } = useCompositeState({
    submitted: false,
    chart: null,
    defaultData: null,
  });

  const fields = [
    {
      name: 'columns',
      label: 'Columns',
      type: 'multiKeyValue',
      addLabel: 'Add column',
      fields: [
        {
          name: 'column',
          placeholder: 'Column',
          type: 'select',
          options: columns,
          validation: {
            required: requiredLabel,
          },
        },
      ],
      validation: { required: requiredLabel },
    },
    {
      name: 'chart',
      label: 'Chart type',
      type: 'select',
      placeholder: 'Select chart type',
      options: ['Pie', 'Bar', 'Line'],
      validation: { required: requiredLabel },
      helper:
        'If you select a pie chart, only the first column of those selected will be used, the others will be discarded.',
    },
    {
      name: 'type',
      label: 'Type',
      type: 'select',
      placeholder: 'Select column type',
      options: ['Numerical', 'Categorical'],
      validation: { required: requiredLabel },
    },
  ];

  function generateChartData(updatedFields: {
    columns: Array<{ column: string; id: string }>;
    type: string;
    chart: string;
  }) {
    const { columns: updatedColumns, type, chart } = updatedFields;
    state.submitted = false;

    function getChartComponent() {
      function getCategoricalValues(list: string[]) {
        return list.reduce(
          (
            acum: {
              [key: string]: number;
            },
            cur: string,
          ) =>
            Object.assign(acum, {
              [cur]: (acum[cur] || 0) + 1,
            }),
          {},
        );
      }

      switch (chart.toLowerCase()) {
        case 'pie': {
          // With PIE we use only the first columns element from the form
          const { column } = updatedColumns[0];

          // Clear empty values and get selected column values
          const list = data.filter((row) => row[column]).map((row) => row[column]);

          let toSend: Array<{ id: string | number; label: string | number; value: any }> = [];

          switch (type) {
            case 'Numerical': {
              toSend = list.map((element, i) => ({
                id: i,
                label: i,
                value: element,
              }));

              break;
            }
            case 'Categorical': {
              const values = getCategoricalValues(list);

              toSend = Object.entries(values).map(([key, value]) => ({
                id: key,
                label: key,
                value,
              }));

              break;
            }
          }

          return <Pie data={toSend} />;
        }
        case 'line': {
          const values = updatedColumns.map(({ column }) => {
            const list = data.filter((row) => row[column]).map((row) => row[column]);

            switch (type) {
              case 'Numerical': {
                return {
                  id: column,
                  data: list.map((element, i) => ({
                    x: i,
                    y: element,
                  })),
                };
              }
              case 'Categorical': {
                const categoricalValues = getCategoricalValues(list);

                return {
                  id: column,
                  data: Object.entries(categoricalValues).map(([key, value]) => ({
                    x: key,
                    y: value,
                  })),
                };
              }
              default: {
                return null;
              }
            }
          });

          // @ts-ignore
          return <Line data={values} />;
        }
        case 'bar': {
          const values = updatedColumns.map(({ column }) => {
            const list = data.filter((row) => row[column]).map((row) => row[column]);

            const toSend: {
              [key: string]: string | number;
            } = {
              value: column,
            };

            switch (type) {
              case 'Numerical': {
                list.forEach((element: string, i: number) => {
                  toSend[i] = element;
                });

                return toSend;
              }
              case 'Categorical': {
                const categoricalValues = getCategoricalValues(list);

                Object.entries(categoricalValues).forEach(([key, value]) => {
                  toSend[key] = value;
                });

                return toSend;
              }
              default: {
                return null;
              }
            }
          });

          // Remove 'value' from keys list
          const keys = Object.keys(values[0] || []).filter((element) => element !== 'value');

          // @ts-ignore
          return <Bar data={values} keys={keys} indexBy="value" />;
        }
      }
    }

    if (type && chart && updatedColumns?.length > 0) {
      state.chart = getChartComponent();
      state.defaultData = updatedFields;
      state.submitted = true;
    }
  }

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={4} p="4" bg={plotWrapperBg} borderRadius="1rem" h={plotSize.height}>
        <GridItem colSpan={9} pos="relative">
          {state.submitted ? (
            <>{state.chart}</>
          ) : (
            <Flex w="full" h="full" justifyContent="center" alignItems="center" color={plotTextColor} bg={plotBg}>
              <Text fontStyle="italic">Generated charts will be shown in this section of the page</Text>
            </Flex>
          )}
        </GridItem>
        <GridItem colSpan={3}>
          <Flex h={plotSize.height - 32} overflow="auto">
            <Form
              settings={fields}
              defaultValues={state.defaultData}
              cancelButton={false}
              onChange={generateChartData}
              onSubmit={generateChartData}
              submitLabel="Refresh"
            />
          </Flex>
        </GridItem>
      </Grid>
      {state.submitted && (
        <Alert status="info" mt="4">
          <AlertIcon />
          Use table sorting to also fit the data on the chart. Sort the table and press the refresh button to see the
          changes.
        </Alert>
      )}
    </>
  );
}
