import { useColorMode } from '@chakra-ui/react';
import ReactCodeMirror, { ReactCodeMirrorProps } from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { python } from '@codemirror/lang-python';
import { html } from '@codemirror/lang-html';
import { json } from '@codemirror/lang-json';
import { useCallback } from 'react';

export type Mode = 'javascript' | 'python' | 'html' | 'json';

interface CodeEditorProps extends ReactCodeMirrorProps {
  mode?: Mode;
}

export function CodeEditor({ mode = 'python', ...rest }: CodeEditorProps) {
  const { colorMode } = useColorMode();

  const getLanguage = useCallback(() => {
    switch (mode) {
      case 'javascript':
        return javascript();
      case 'python':
        return python();
      case 'html':
        return html();
      case 'json':
        return json();
    }
  }, [mode]);

  return <ReactCodeMirror theme={colorMode} height="500px" width="100%" extensions={[getLanguage()]} {...rest} />;
}
