export const Button = {
  baseStyle: {
    fontWeight: 'semibold',
    textTransform: 'none',
    // borderRadius: "sm",
    lineHeight: '1.2',
    _focus: {
      boxShadow: 'outline',
    },
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
      boxShadow: 'none',
    },
    _hover: {
      _disabled: {
        bg: 'initial',
      },
    },
  },
  sizes: {
    '6xs': {
      fontSize: '9px',
      padding: '2px 4px',
    },
    '5xs': {
      fontSize: '9px',
      padding: '4px 8px',
    },
    '4xs': {
      fontSize: '10px',
      padding: '5px 10px',
    },
    '3xs': {
      fontSize: '11px',
      padding: '6px 12px',
    },
    '2xs': {
      fontSize: '12px',
      padding: '7px 14px',
    },
  },
  variants: {},
  defaultProps: {
    size: 'md',
    variant: 'solid',
  },
};
