import { Box } from '@chakra-ui/layout';
import { Switch, Route } from 'react-router-dom';
import { Navbar } from 'shared/Navbar';
import Loader from 'shared/Loader';
import { PasswordReset } from 'views/PasswordReset';
import { SignUp } from 'views/SignUp';
import { useOnlineStatus } from 'hooks/useOnlineStatus';
import { Login } from 'views/Login';
import { OfflineView } from 'shared/OfflineView';
import { OnlyDesktop } from 'shared/OnlyDesktop';
import { useAppSelector } from 'hooks/useRedux';
import { Routes } from './Routes';

function App() {
  const loading = useAppSelector((state) => state.resources.loading);
  const isOnline = useOnlineStatus();

  const isSignupDisabled = import.meta.env.VITE_DISABLE_SIGNUP === 'true';

  return (
    <Box minH="100vh" id="main">
      <OnlyDesktop>
        <Switch>
          <Route path="/login" component={Login} />
          {!isSignupDisabled && <Route path="/signup" component={SignUp} />}
          <Route path="/reset" component={PasswordReset} />

          <Route path="*">
            {isOnline ? (
              <>
                <Navbar />
                <Box pt={12}>
                  <Routes />
                </Box>
              </>
            ) : (
              <OfflineView />
            )}
          </Route>
        </Switch>

        {loading && <Loader />}
      </OnlyDesktop>
    </Box>
  );
}

export default App;
