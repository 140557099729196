import { ReactNode } from 'react';
import { Box, Flex, Text, TextProps } from '@chakra-ui/layout';
import { FlexProps } from '@chakra-ui/react';
import { SpinnerProps } from '@chakra-ui/spinner';

export function AsyncLoader({
  isLoading,
  children,
  label,
  asHidden,
  // spinnerStyle = {},
  labelStyle = {},
  ...rest
}: AsyncLoaderProps) {
  return (
    <>
      {asHidden ? (
        <>
          <Flex minH="100px" alignItems="center" justifyContent="center" {...rest} hidden={!isLoading}>
            {/* <Spinner thickness="4px" size="xl" {...spinnerStyle} /> */}
            {label && (
              <Text ml="4" {...labelStyle}>
                {label}
              </Text>
            )}
          </Flex>

          <Box hidden={isLoading}>{children}</Box>
        </>
      ) : (
        <>
          {isLoading ? (
            <Flex minH="100px" alignItems="center" justifyContent="center" {...rest}>
              {/* <Spinner thickness="4px" size="xl" {...spinnerStyle} /> */}
              {label && (
                <Text ml="4" {...labelStyle}>
                  {label}
                </Text>
              )}
            </Flex>
          ) : (
            children
          )}
        </>
      )}
    </>
  );
}

interface AsyncLoaderProps extends FlexProps {
  isLoading: boolean;
  children: ReactNode;
  label?: string;
  asHidden?: boolean;
  spinnerStyle?: SpinnerProps;
  labelStyle?: TextProps;
}
