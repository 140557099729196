import { useFormContext } from 'react-hook-form';
import { IField } from 'constants/form';
import { gatewayCrud } from 'services/api';
import urljoin from 'url-join';
import { AsyncSelect } from './AsyncSelect';

export function GatewayDependent({
  field,
}: {
  field: IField & {
    fragment: string;
    parent: string;
  };
}) {
  const { watch } = useFormContext();

  const parentWatch = watch(field.parent);

  return (
    <>
      {parentWatch && (
        <AsyncSelect
          instance={gatewayCrud.instance}
          field={{ ...field, url: urljoin(parentWatch, field.fragment), config: { full: true } }}
        />
      )}
    </>
  );
}
