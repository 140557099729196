import { ColorMode } from '@chakra-ui/react';

export const IconBarItem = {
  baseStyle: () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0.5rem 0',
    cursor: 'pointer',
    _disabled: {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  }),
  sizes: {
    sm: {
      boxSize: '1.5rem',
      fontSize: '0.75rem',
    },
    md: {
      boxSize: '2rem',
      fontSize: '1rem',
    },
    lg: {
      boxSize: '2.5rem',
      fontSize: '1.25rem',
    },
  },
  variants: {
    plain: ({ colorMode }: { colorMode: ColorMode }) => ({
      backgroundColor: 'transparent',
      transition: 'color 0.3s ease-in-out',
      _hover: {
        color: colorMode === 'dark' ? 'gray.300' : 'gray.500',
      },
    }),
    rounded: ({ colorMode }: { colorMode: ColorMode }) => ({
      color: colorMode === 'dark' ? 'gray.200' : 'gray.400',
      backgroundColor: colorMode === 'dark' ? 'whiteAlpha.200' : 'blackAlpha.100',
      borderRadius: 'full',
      transition: 'color, background-color 0.3s ease-in-out',
      _hover: {
        backgroundColor: colorMode === 'dark' ? 'whiteAlpha.500' : 'blackAlpha.300',
      },
    }),
  },
  defaultProps: {
    size: 'lg',
    variant: 'plain',
  },
};
