import { ResponsiveValue } from '@chakra-ui/react';

const requiredLabel = 'Required field';

const regexMapping: {
  [regex: string]: RegExp;
} = {
  noSpecialChars: /^[A-Z0-9]([A-Z0-9_]*[A-Z0-9])?$/i,
  file: /^[A-Z0-9]([A-Z0-9_.]*[A-Z0-9])?$/i,
};

const noSpecialCharsPattern = {
  value: 'noSpecialChars',
  message: 'No special characters (except _ in the middle of name) and whitespaces allowed',
};

const filePattern = {
  value: 'file',
  message: 'No special characters (except _ in the middle of name) and whitespaces allowed',
};

const structureValidation = {
  required: requiredLabel,
  pattern: noSpecialCharsPattern,
  maxLength: {
    value: 50,
    message: 'Maximum 50 characters allowed',
  },
};

const fileValidation = {
  required: requiredLabel,
  pattern: filePattern,
};

const folderValidation = {
  required: requiredLabel,
  pattern: noSpecialCharsPattern,
};

export interface IField {
  name: string;
  type: string;
  label?: string;
  placeholder?: string;
  validation?: {
    [key: string]: any;
  };
  hideLabel?: boolean;
  helper?: string;
  description?: string;
  value?: any;
  group?: string;
  divider?: boolean;
  colSpan?: ResponsiveValue<number | 'auto'>;
}

export {
  requiredLabel,
  regexMapping,
  noSpecialCharsPattern,
  filePattern,
  structureValidation,
  fileValidation,
  folderValidation,
};
