import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const flatComponents = createSelector(
  (state: RootState) => state.resources.components,
  (components) => components.map((group) => group.components).flat(),
);

export const activeProjectSelector = createSelector(
  (state: RootState) => state.resources,
  (resources) => resources.activeProject,
);
