import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { FlowState } from 'store/reducers/flow';

export const activeTabsSelector = createSelector(
  (state: RootState) => state.flow,
  (flow) => flow.graphs[flow.status.active],
);

export const activeTab = (state: FlowState) => state.graphs[state.status.active];
