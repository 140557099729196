import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { orchestratorCrud } from 'services/api';
import urljoin from 'url-join';
import { Item } from './types';

function getFilesAPI(path: string, params: AxiosRequestConfig['params']) {
  return orchestratorCrud.getOne<AxiosResponse<{ items: Item[]; type: string }>>('files', path, {
    params,
    full: true,
  });
}

function uploadFileAPI(path: string, files: File[]) {
  const promises: Promise<AxiosResponse<any>>[] = [];

  files.forEach((file) => {
    const formData = new FormData();
    formData.append('file', file);

    promises.push(
      orchestratorCrud.createOne(urljoin('files', path), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    );
  });

  return Promise.all(promises);
}

function createElementAPI(path: string, isDir: boolean) {
  return orchestratorCrud.createOne(urljoin('files', path), isDir ? null : ' ');
}

function renameFileAPI(path: string, newPath: string) {
  return orchestratorCrud.updateOne('files', path, { path: newPath });
}

function copyFileAPI(path: string, newPath: string) {
  return orchestratorCrud.createOne(urljoin('copy', path), { path: newPath });
}

function deleteElementAPI(items: string[]) {
  const promises: Promise<AxiosResponse<any>>[] = [];

  items.forEach((item) => {
    promises.push(orchestratorCrud.deleteOne('files', item));
  });

  return Promise.all(promises);
}

export { renameFileAPI, copyFileAPI, createElementAPI, uploadFileAPI, getFilesAPI, deleteElementAPI };
