import { IField } from 'constants/form';
import { gatewayCrud } from 'services/api';
import urljoin from 'url-join';
import { AsyncSelect } from './AsyncSelect';

export function GatewaySelect({
  field,
}: {
  field: IField & {
    fragment: string;
  };
}) {
  return (
    <AsyncSelect
      instance={gatewayCrud.instance}
      field={{ ...field, url: urljoin('services', field.fragment), config: { full: true } }}
    />
  );
}
