import { useState, createContext, useContext, useRef, ReactNode } from 'react';
import { ModalFooter, Button, Text, Flex } from '@chakra-ui/react';
import { Dialog } from 'ds4biz-core';
import { Form } from 'shared/Form';

const ConfirmerContext = createContext<{ show?: any }>({});

interface ValuesProps {
  title: string;
  body?: string;
  cancelLabel?: string;
  confirmLabel?: string;
  settings?: [];
  defaultValues?: { [key: string]: any };
}

export function ConfirmerProvider({ children }: { children: ReactNode }) {
  const [values, setValues] = useState<ValuesProps>({
    title: 'Confirm',
  });
  const [isOpen, setIsOpen] = useState(false);
  const resolver = useRef<HTMLInputElement>(null);

  function handleShow({
    title = 'Confirm',
    body = '',
    cancelLabel = 'Cancel',
    confirmLabel = 'Confirm',
    settings,
    defaultValues = {},
  }: ValuesProps) {
    setIsOpen(true);

    setValues({
      title,
      body,
      cancelLabel,
      confirmLabel,
      settings,
      defaultValues,
    });

    return new Promise((resolve) => {
      // @ts-ignore
      resolver.current = resolve;
    });
  }

  function handleConfirm(_values: { [key: string]: any } | null) {
    if (resolver.current) {
      // @ts-ignore
      resolver.current(_values || true);
    }

    setIsOpen(false);
  }

  function handleCancel() {
    if (resolver.current) {
      // @ts-ignore
      resolver.current(false);
    }

    setIsOpen(false);
  }

  return (
    <ConfirmerContext.Provider value={{ show: handleShow }}>
      <>
        {children}
        <Dialog
          isOpen={isOpen}
          title={values.title}
          onClose={handleCancel}
          modalFooter={
            <>
              {!values.settings && (
                <ModalFooter>
                  <Button mr={1} size="sm" variant="ghost" onClick={handleCancel}>
                    {values.cancelLabel}
                  </Button>
                  <Button size="sm" variant="solid" colorScheme="red" onClick={handleConfirm}>
                    {values.confirmLabel}
                  </Button>
                </ModalFooter>
              )}
            </>
          }
        >
          <>
            {values.body && (
              <Text fontSize="md" py="1">
                {values.body}
              </Text>
            )}
            {values.settings && (
              <Form
                settings={values.settings}
                onSubmit={handleConfirm}
                defaultValues={values.defaultValues}
                cancelLabel={values.cancelLabel}
                submitLabel={values.confirmLabel}
                customFooter={
                  <Flex justifyContent="flex-end">
                    <Button mr={1} size="sm" variant="ghost" onClick={handleCancel}>
                      {values.cancelLabel}
                    </Button>
                    <Button size="sm" variant="solid" colorScheme="red" type="submit">
                      {values.confirmLabel}
                    </Button>
                  </Flex>
                }
              />
            )}
          </>
        </Dialog>
      </>
    </ConfirmerContext.Provider>
  );
}

export const useConfirm = () => useContext(ConfirmerContext).show;
