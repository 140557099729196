import { Center, Flex, Heading, Text } from '@chakra-ui/layout';
import { Modal, ModalBody, ModalContent, ModalHeader } from '@chakra-ui/modal';
import { Button } from '@chakra-ui/react';
import axios from 'axios';
import { requiredLabel } from 'constants/form';
import { useAppDispatch } from 'hooks/useRedux';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { baseURL } from 'services/api';
import { Form } from 'shared/Form';
import { LogoLabel } from 'shared/ImageSprite';
import { showToast } from 'shared/Toast';
import { makeLogout } from 'store/async/resources';
import urljoin from 'url-join';

export function PasswordReset() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  function handlePasswordReset({ email }: { email: string }) {
    const baseurl = `${window.location.protocol}//${window.location.hostname}/`;
    setLoading(true);

    axios
      .post(urljoin(baseURL, `/users/password/reset`), { email, baseurl })
      .then(() => {
        dispatch(makeLogout());
        setLoading(false);
        showToast({ title: `Email sent to ${email}, check your inbox!`, type: 'info' });
      })
      .catch(() => {
        setLoading(false);
        showToast({
          title: 'There is some error, check if the entered email is correct and try again',
          type: 'error',
        });
      });
  }

  return (
    <Modal isOpen onClose={() => false} isCentered size="xs">
      <ModalContent borderRadius="2xl" overflow="hidden">
        {!loading && (
          <ModalHeader mb={2}>
            <Flex my={8} justifyContent="center">
              <LogoLabel width="90" />
            </Flex>
          </ModalHeader>
        )}
        <ModalBody overflow="hidden">
          {loading ? (
            <Center>
              <Heading fontSize="md" pt="4" pb="3">
                loading…
              </Heading>
            </Center>
          ) : (
            <>
              <Text mb={2} fontSize="lg">
                Password reset
              </Text>
              <Text mb={4} fontSize="sm">
                To get a new password, type in your email and click the Reset button.
              </Text>

              <Form
                settings={[
                  {
                    name: 'email',
                    type: 'email',
                    placeholder: 'Your email',
                    label: 'Email',
                    validation: {
                      required: requiredLabel,
                    },
                  },
                ]}
                onSubmit={handlePasswordReset}
                customFooter={
                  <Button mt={-2} mb={2} type="submit" size="sm" isFullWidth variant="solid">
                    Reset password
                  </Button>
                }
              />

              <Flex mb="6" justifyContent="center" color="gray.500">
                <Text fontWeight="600" fontSize="xs" mr="2">
                  or
                </Text>
                <Button
                  size="xs"
                  variant="link"
                  fontWeight="600"
                  color="gray.500"
                  colorScheme="black"
                  onClick={() => history.push('/login')}
                >
                  back to login
                </Button>
              </Flex>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
