import { Dispatch } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { orchestratorCrud, gatewayCrud } from 'services/api';
import { showToast } from 'shared/Toast';
import { GetState } from 'store';
import { setProject } from 'store/reducers/flow';
import {
  createTemplateSuccess,
  setLoading,
  editTemplateSuccess,
  deleteTemplateSuccess,
  setActiveProject,
  makeLogoutSuccess,
  setComponents,
  getUserSuccess,
  setTemplates,
} from 'store/reducers/resources';
import { ITab, IProject } from 'store/types/flow';
import { Structure, IUser, Components } from 'store/types/resources';
import urljoin from 'url-join';

const getUser = () => (dispatch: Dispatch) => {
  dispatch(setLoading(true));

  gatewayCrud
    .getAll<{ me: IUser }>('/auth/me')
    .then((data) => {
      dispatch(getUserSuccess({ user: data.me }));
      dispatch(setLoading(false));
    })
    .catch(() => {
      dispatch(setLoading(false));
    });
};

const makeLogout = () => (dispatch: Dispatch, getState: GetState) => {
  const { clickedNode } = getState().flow.status;

  if (clickedNode) {
    showToast({ title: 'Close your block settings before logout!', type: 'warning' });
    return;
  }

  dispatch(makeLogoutSuccess());
  dispatch(push('/login'));
};

const createTemplate = (templateName: string, tab: ITab) => (dispatch: Dispatch, getState: GetState) => {
  const { templates } = getState().resources;
  const templatesId = templates.map((template) => template.id);

  // Overwrite if already exists
  if (templatesId.includes(templateName)) {
    orchestratorCrud.overwriteOne('templates', templateName, tab).then(() => {
      showToast({ title: `Template ${templateName} updated!` });
    });
  } else {
    const toSend = {
      id: templateName,
      graph: tab,
    };

    orchestratorCrud.createOne<Structure>('templates', toSend).then((payload) => {
      dispatch(createTemplateSuccess(payload));
      showToast({ title: `Template ${templateName} created!` });
    });
  }
};

const editTemplate =
  ({ newName, oldName }: { newName: string; oldName: string }) =>
  (dispatch: Dispatch) => {
    orchestratorCrud.updateOne('templates', oldName, { name: newName }).then(() => {
      dispatch(editTemplateSuccess({ newName, oldName }));
    });
  };

const deleteTemplate = (templateId: string) => (dispatch: Dispatch) => {
  orchestratorCrud.deleteOne<{ template: string }>('templates', templateId).then((data) => {
    dispatch(deleteTemplateSuccess(data));
  });
};

const importProject = (file: File, fname: string) => (dispatch: Dispatch) => {
  const formData = new FormData();
  formData.append('file', file);

  orchestratorCrud
    .createOne<IProject>(urljoin('projects', 'import'), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        fname,
      },
    })
    .then((data) => {
      showToast({ title: `${data.name} imported!` });
      dispatch(push(`/projects/${data.id}`));
    });
};

const loadProject = (projectId: string) => (dispatch: Dispatch) => {
  dispatch(setLoading(true));

  orchestratorCrud
    .getOne<IProject>('projects', projectId)
    .then(async (data) => {
      // Reload components at project load to check if some of them are now enabled
      const components = await orchestratorCrud.getAll<Components[]>('components');
      const templates = await orchestratorCrud.getAll<Structure[]>('templates');

      dispatch(setComponents(components));
      dispatch(setTemplates(templates));
      dispatch(setProject(data));
      dispatch(setActiveProject({ id: data.id, name: data.name }));
      dispatch(setLoading(false));
    })
    .catch(() => {
      dispatch(setLoading(false));
      dispatch(push('/projects'));
    });
};

export { makeLogout, importProject, getUser, loadProject, editTemplate, deleteTemplate, createTemplate };
