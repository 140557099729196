import { useEffect } from 'react';
import { Button, ButtonGroup } from '@chakra-ui/button';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { Box, Text } from '@chakra-ui/layout';
import { ModalFooter } from '@chakra-ui/modal';
import { Spinner } from '@chakra-ui/spinner';
import { Dialog, List, ListItem, useCompositeState } from 'ds4biz-core';
import urljoin from 'url-join';
import { gatewayCrud } from 'services/api';
import { getFileExtension } from 'utils/misc';
import { Item } from './types';

// function getConverters(extension, converters) {
// 	const apps = extension
// 		? converters
// 				.map((item) => {
// 					const enabled =
// 						item.extensions.includes(extension) ||
// 						item.extensions.includes("*");

// 					return {
// 						...item,
// 						disabled: !enabled,
// 					};
// 				})
// 				.filter((item) => !item.disabled)
// 		: converters;

// 	return apps;
// }

// MOCKUP
// const items = [
// 	{
// 		iconBg: "#59F41C",
// 		title: "XLS to CSV converter",
// 		description: "A super fast xls file converter",
// 		value: "xls",
// 		extensions: ["xls", "xlsx"],
// 	},
// 	{
// 		iconBg: "#D50A28",
// 		title: "Convert docs to PDF",
// 		description: "Document to PDF converter",
// 		value: "pdf",
// 		extensions: ["txt", "doc", "docx"],
// 	},
// ];
interface FileConverterProps {
  isOpen: boolean;
  onFileConvert: ({ item, from, to }: { item: Item; from: string; to: string }) => void;
  onClose: () => void;
  item?: Item;
}

export function FileConverter({ isOpen, onFileConvert, onClose, item }: FileConverterProps) {
  const state: { value: string; ext: string; isLoading: boolean; converters: string[][] } = useCompositeState({
    value: '',
    ext: '',
    isLoading: false,
    converters: [],
  });

  useEffect(() => {
    if (isOpen) {
      state.isLoading = true;

      gatewayCrud
        .getAll<any>(urljoin('file-converter', 'converters'))
        .then((data) => {
          state.converters = data.map((el: any) => ({
            title: `${el[0].toUpperCase()} to ${el[1].toUpperCase()}`,
            value: el[0],
            iconBg: '#59F41C',
          }));

          state.isLoading = false;
        })
        .catch(() => onClose());
    }
  }, [isOpen]);

  useEffect(() => {
    if (item) {
      const extension = getFileExtension(item.name);

      if (extension) {
        state.ext = extension;
      }
    }
  }, [item]);

  function handleSave() {
    if (item) {
      let toSend = {
        item,
        from: '',
        to: '',
      };

      switch (state.value) {
        case 'xls': {
          toSend = { ...toSend, from: 'xls', to: 'csv' };
          break;
        }
        case 'pdf': {
          toSend = { ...toSend, from: 'pdf', to: 'doc' };
          break;
        }
      }

      onFileConvert(toSend);
    }
  }

  return (
    <Dialog
      title={`Convert ${state.ext} with...`}
      isOpen={isOpen}
      onClose={onClose}
      autoFocus
      modalFooter={
        <ModalFooter
          px={8}
          py={4}
          d="flex"
          borderTop="1px solid"
          justifyContent="space-between"
          borderColor={useColorModeValue('gray.100', 'gray.700')}
        >
          <Box />
          <ButtonGroup variant="ghost" size="sm" mr={-2}>
            <Button onClick={onClose}>Close</Button>
            <Button colorScheme="pink" onClick={handleSave} isDisabled={!state.value}>
              Convert
            </Button>
          </ButtonGroup>
        </ModalFooter>
      }
    >
      <Box m="0 -0.75rem">
        {state.isLoading ? (
          <Box textAlign="center">
            <Spinner size="xl" />
            <Text color="gray.400" size="sm">
              Retrieving converters...
            </Text>
          </Box>
        ) : (
          <>
            {state.converters.length > 0 ? (
              <List>
                {state.converters.map((app: any) => (
                  <ListItem
                    key={app.title}
                    title={app.title}
                    icon={app.icon}
                    iconBg={app.iconBg}
                    description={app.description}
                    selected={app.value === state.value}
                    isDisabled={app.disabled}
                    onClick={() => (state.value = app.value)}
                    onDoubleClick={handleSave}
                  />
                ))}
              </List>
            ) : (
              <Text color="gray.500" textAlign="center" my="2">
                No converters found
              </Text>
            )}
          </>
        )}
      </Box>
    </Dialog>
  );
}
