import { ReactNode } from 'react';
import { Stat, StatLabel, StatNumber, StatProps, useColorModeValue } from '@chakra-ui/react';
import StatBgImage from 'assets/images/stat-bg-graphic-seamless.svg';

interface CustomStatProps extends StatProps {
  stat: {
    title?: any;
    value?: string | number;
  };
  children?: ReactNode;
}

export function CustomStat({ stat, children, ...rest }: CustomStatProps) {
  const statBgGradient = useColorModeValue(
    'linear-gradient(to top right, #FAFAFA, #EFEFEF)',
    'linear-gradient(to top right, #222222, #343434)',
  );
  const statBg = useColorModeValue('gray.100', 'gray.800');
  const valueColor = useColorModeValue('blackAlpha.700', 'whiteAlpha.700');
  const labelColor = useColorModeValue('gray.800', 'gray.200');
  return (
    <Stat
      mr="4"
      mb="4"
      px="6"
      py="4"
      bgColor={statBg}
      minWidth="48"
      maxWidth="64"
      borderRadius="2xl"
      bgImage={`url(${StatBgImage}), ${statBgGradient}`}
      bgRepeat="no-repeat, no-repeat"
      bgPosition="bottom center, bottom center"
      bgSize="contain, cover"
      {...rest}
    >
      <>
        {stat.title && (
          <StatLabel mt="1" color={labelColor}>
            {stat.title}
          </StatLabel>
        )}
        {stat.value !== undefined && (
          <StatNumber fontSize="3rem" color={valueColor} mt="-1">
            {stat.value}
          </StatNumber>
        )}
        {children && children}
      </>
    </Stat>
  );
}
