import { useColorModeValue } from '@chakra-ui/react';
import { ResponsiveScatterPlot, ScatterPlotDatum, ScatterPlotSvgProps } from '@nivo/scatterplot';
import { getAxisTooltip } from './constants';

interface ScatterProps extends ScatterPlotSvgProps<ScatterPlotDatum> {
  yLegend?: string;
  xLegend?: string;
}

function Dot({ n }: { n: { node: { x: number; y: number } } }) {
  const dotColor = useColorModeValue('rgba(90,30,60,1)', 'rgba(255,255,255,1)');
  const dotStrokeColor = useColorModeValue('rgba(250,50,150,.125)', 'rgba(150,50,75,.25)');
  const { node } = n;

  return <circle cx={node.x} cy={node.y} r={0.5} stroke={dotStrokeColor} strokeWidth={5} fill={dotColor} opacity={1} />;
}

export function Scatter({ data, yLegend, xLegend, ...rest }: ScatterProps) {
  const textColor = useColorModeValue('#222222', '#C9C9C9');
  const gridColor = useColorModeValue('#ddd', '#444');

  return (
    <ResponsiveScatterPlot
      data={data}
      theme={{
        fontFamily: 'Share Tech Mono',
        textColor,
        grid: {
          line: {
            stroke: gridColor,
            strokeWidth: 1,
          },
        },
      }}
      margin={{ top: 5, right: 40, bottom: 55, left: 65 }}
      xScale={{ type: 'linear', min: 0, max: 'auto' }}
      yScale={{ type: 'linear', min: 0, max: 'auto' }}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        renderTick: (v) => {
          const { value } = v;

          return (
            <text x={v.x} y={v.y + 18} textAnchor={v.textAnchor} fontSize="10px" fill={textColor}>
              {value}
            </text>
          );
        },
        legend: xLegend ?? 'X',
        legendPosition: 'middle',
        legendOffset: 46,
        format: (v) => getAxisTooltip(v),
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        renderTick: (v) => {
          const { value } = v;

          return (
            <text x={v.x - 5} y={v.y} textAnchor={v.textAnchor} fontSize="10px" fill={textColor}>
              {value}
            </text>
          );
        },
        legend: yLegend ?? 'Y',
        legendPosition: 'middle',
        legendOffset: -60,
        format: (v) => getAxisTooltip(v),
      }}
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 130,
          translateY: 0,
          itemWidth: 100,
          itemHeight: 12,
          itemsSpacing: 5,
          itemDirection: 'left-to-right',
          symbolSize: 12,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      animate={false}
      nodeComponent={(n) => <Dot n={n} />}
      {...rest}
    />
  );
}
