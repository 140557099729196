import { AxiosInstance, AxiosRequestConfig } from 'axios';
import urljoin from 'url-join';

export interface ExtendedAxiosConfig {
  // If true return all axios response and not just 'data' key
  full?: boolean;
  // List of status code to skip toast message
  skippedCodes?: number[];
}

export class Crud {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getAll<T = unknown>(resource: string, config?: AxiosRequestConfig & ExtendedAxiosConfig): Promise<T> {
    return this.instance.get(resource, config);
  }

  getOne<T = unknown>(resource: string, id: string, config?: AxiosRequestConfig & ExtendedAxiosConfig): Promise<T> {
    return this.instance.get(urljoin(resource, id), config);
  }

  createOne<T = unknown>(
    resource: string,
    payload: unknown,
    config?: AxiosRequestConfig & ExtendedAxiosConfig,
  ): Promise<T> {
    return this.instance.post(resource, payload, config);
  }

  updateOne<T = unknown>(
    resource: string,
    id: string,
    payload: unknown,
    config?: AxiosRequestConfig & ExtendedAxiosConfig,
  ): Promise<T> {
    return this.instance.patch(urljoin(resource, id), payload, config);
  }

  overwriteOne<T = unknown>(
    resource: string,
    id: string,
    payload: unknown,
    config?: AxiosRequestConfig & ExtendedAxiosConfig,
  ): Promise<T> {
    return this.instance.put(urljoin(resource, id), payload, config);
  }

  deleteOne<T = unknown>(resource: string, id: string, config?: AxiosRequestConfig & ExtendedAxiosConfig): Promise<T> {
    return this.instance.delete(urljoin(resource, id), config);
  }
}
