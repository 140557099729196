import { Flex, useColorModeValue } from '@chakra-ui/react';
import { LogoFlat } from './ImageSprite';

function Loader() {
  const overlayBg = useColorModeValue('rgba(255, 255, 255, 0.8)', 'rgba(0, 0, 0, 0.8)');

  return (
    <Flex
      top="0"
      width="100%"
      height="100%"
      zIndex="99999"
      position="fixed"
      alignItems="center"
      justifyContent="center"
      backdropFilter="blur(4px)"
      bg={overlayBg}
    >
      <LogoFlat width="5%" />
    </Flex>
  );
}

export default Loader;
