import { useEffect } from 'react';
import { Flex, IconButton, SelectProps, Text } from '@chakra-ui/react';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { useCompositeState } from 'ds4biz-core';
import { IField } from 'constants/form';
import { BaseSelect } from 'shared/Form/BaseSelect';
import { RiRefreshLine } from 'react-icons/ri';

export interface AsyncSelectProps extends SelectProps {
  field: IField & { url: string; config?: AxiosRequestConfig };
  instance?: AxiosInstance;
  enableRefresh?: boolean;
}

export function AsyncSelect({ field, instance, size = 'sm', enableRefresh = true, ...rest }: AsyncSelectProps) {
  const axiosInstance = instance || axios;

  const state: { error: boolean; loading: boolean; options: any[] } = useCompositeState({
    error: false,
    loading: true,
    options: [],
  });

  function makeRequest() {
    state.loading = true;

    axiosInstance
      .get(field.url, field.config || {})
      .then(({ data }) => {
        state.options = data;
        state.loading = false;
      })
      .catch(() => (state.error = true));
  }

  useEffect(() => {
    if (field.url) {
      makeRequest();
    }
  }, [field.url]);

  return (
    <>
      {state.error ? (
        <Text color="red.300" as="p" mt={2}>
          Error during retrieve informations
        </Text>
      ) : (
        <Flex>
          {enableRefresh && (
            <IconButton
              icon={<RiRefreshLine />}
              height="32px"
              borderRightRadius={0}
              variant="outline"
              aria-label="Refresh"
              title="Refresh"
              onClick={makeRequest}
            />
          )}
          <BaseSelect
            field={field}
            size={size}
            borderLeftRadius={0}
            options={state.options}
            isLoading={state.loading}
            {...rest}
          />
        </Flex>
      )}
    </>
  );
}
