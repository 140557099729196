import { ColorMode } from '@chakra-ui/react';

export const Pagination = {
  parts: ['pagination', 'item', 'control', 'footer'],
  baseStyle: () => ({
    pagination: {
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '1rem',
    },
    item: {
      display: 'flex',
      cursor: 'pointer',
      margin: '0 0.1rem',
      letterSpacing: '0',
      borderRadius: '50%',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'all 0.3s',
      _disabled: {
        opacity: 0.5,
        pointerEvents: 'none',
      },
      _hover: {
        bg: 'gray.200',
      },
      _current: {
        cursor: 'default',
        fontWeight: 'bold',
        bg: 'blue.400',
      },
    },
    footer: {
      fontSize: '0.75em',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      margin: '0.25rem 1rem 0',
    },
  }),
  sizes: {
    sm: {
      item: {
        fontSize: '0.7em',
        width: '2.1rem',
        height: '2.1rem',
      },
    },
    md: {
      item: {
        fontSize: '0.8em',
        width: '2.4rem',
        height: '2.4rem',
      },
    },
    lg: {
      item: {
        fontSize: '1em',
        width: '3rem',
        height: '3rem',
      },
    },
  },
  variants: {
    plain: ({ colorMode }: { colorMode: ColorMode }) => ({
      item: {
        color: colorMode === 'dark' ? 'gray.100' : 'gray.800',
        bg: colorMode === 'dark' ? 'gray.900' : 'gray.200',
      },
      control: {
        fill: colorMode === 'dark' ? '#fed300' : 'gray.200',
        bg: colorMode === 'dark' ? 'gray.900' : 'gray.200',
      },
      footer: {
        color: 'currentColor',
      },
    }),
    rounded: ({ colorMode }: { colorMode: ColorMode }) => ({
      item: {
        color: colorMode === 'dark' ? 'gray.100' : 'gray.800',
        bg: colorMode === 'dark' ? 'gray.900' : 'gray.200',
      },
      control: {
        fill: colorMode === 'dark' ? '#fed300' : 'gray.200',
        bg: colorMode === 'dark' ? 'gray.900' : 'gray.200',
      },
      footer: {
        color: 'currentColor',
      },
    }),
  },
  defaultProps: {
    size: 'lg',
    variant: 'rounded',
  },
};
