import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { ConnectedRouter } from 'connected-react-router';
import ReactDOM from 'react-dom';
import { GlobalHotKeys } from 'react-hotkeys-ce';
import { Provider } from 'react-redux';
import 'focus-visible/dist/focus-visible';
import './assets/index.css';
import { ConfirmerProvider } from 'shared/ConfirmerProvider';
import { ErrorBoundary } from 'shared/ErrorBoundary';
import store, { history } from 'store';
import { customTheme } from 'assets/themes';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ChakraProvider theme={customTheme}>
          <ConfirmerProvider>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </ConfirmerProvider>

          <GlobalHotKeys allowChanges />
        </ChakraProvider>
      </ConnectedRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
