import { ColorMode } from '@chakra-ui/react';

export const styles = {
  global: ({ colorMode }: { colorMode: ColorMode }) => ({
    html: {
      height: '100%',
    },
    body: {
      height: '100%',
      margin: 0,
      fontWeight: 300,
      letterSpacing: -0.3,
      webkitFontSmoothing: 'antialiased',
      mozOsxFontSmoothing: 'grayscale',
      userSelect: 'none',
      color: colorMode === 'dark' ? 'white' : 'gray.800',
      backgroundColor: colorMode === 'dark' ? 'gray.900' : 'gray.50',
    },
    ':focus': {
      outline: 'none',
    },
  }),
};

export const fonts = {
  body: "'Share Tech Mono', 'Inter', 'Helvetica', 'Arial', system-ui, sans-serif",
  heading: "Share Tech Mono, 'Inter', 'Helvetica', 'Arial', system-ui, sans-serif",
  mono: "'Share Tech Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New' ,monospace",
};

export const shadows = {
  base: '0 0 3px 0 rgba(0, 0, 0, 0.1), 0 0 2px 0 rgba(0, 0, 0, 0.06)',
};

export const colors = {
  whiteAlpha: {
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.06)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.36)',
    600: 'rgba(255, 255, 255, 0.48)',
    700: 'rgba(255, 255, 255, 0.64)',
    800: 'rgba(255, 255, 255, 0.80)',
    900: 'rgba(255, 255, 255, 0.92)',
  },
  blackAlpha: {
    50: 'rgba(0, 0, 0, 0.04)',
    100: 'rgba(0, 0, 0, 0.06)',
    200: 'rgba(0, 0, 0, 0.08)',
    300: 'rgba(0, 0, 0, 0.16)',
    400: 'rgba(0, 0, 0, 0.24)',
    500: 'rgba(0, 0, 0, 0.36)',
    600: 'rgba(0, 0, 0, 0.48)',
    700: 'rgba(0, 0, 0, 0.64)',
    800: 'rgba(0, 0, 0, 0.80)',
    900: 'rgba(0, 0, 0, 0.92)',
  },
  transparent: 'transparent',
  black: {
    50: '#efefef',
    100: '#e8e8e8',
    200: '#d0d0d0',
    300: '#c9c9c9',
    400: '#000000',
    500: '#535353',
    600: '#888888',
    700: '#000000',
    800: '#000000',
    900: '#000000',
  },
  white: {
    50: '#ffffff',
    100: '#ffffff',
    200: '#efefef',
    300: '#e8e8e8',
    400: '#ffffff',
    500: '#222222',
    600: '#343434',
    700: '#ffffff',
    800: '#ffffff',
    900: '#ffffff',
  },
  dark: {
    50: '#FFFFFF',
    100: '#d0d0d0',
    200: '#b0b0b0',
    300: '#a0a0a0',
    400: '#909090',
    500: '#888888',
    600: '#606060',
    700: '#404040',
    800: '#222222',
    900: '#000000',
  },
  gray: {
    50: '#F9F8F8',
    100: '#EFEFEF',
    200: '#E8E8E8',
    300: '#C9C9C9',
    400: '#A9A9A9',
    500: '#888888',
    600: '#535353',
    700: '#343434',
    800: '#222222',
    900: '#171717',
  },
  red: {
    hex: '#F8637A',
    rgb: '248, 99, 122',
    100: '#FBB1BC',
    200: '#FA9BA9',
    300: '#F98596',
    400: '#F86E83',
    500: '#F74B65',
    600: '#F41C3D',
    700: '#D50A28',
    800: '#A6081F',
    900: '#760616',
  },
  orange: {
    hex: '#FA804C',
    rgb: '250, 128, 76',
    50: '#FFF1E6',
    100: '#FED7B8',
    200: '#FEBD8B',
    300: '#FDA35E',
    400: '#FC8930',
    500: '#FC6F03',
    600: '#CA5902',
    700: '#974302',
    800: '#652D01',
    900: '#321601',
  },
  yellow: {
    hex: '#FDCA60',
    rgb: '253, 202, 96',
    50: '#FFF5E5',
    100: '#FFE2B8',
    200: '#FFD08A',
    300: '#FFBE5C',
    400: '#FFAB2E',
    500: '#FF9900',
    600: '#CC7A00',
    700: '#995C00',
    800: '#663D00',
    900: '#331F00',
  },
  green: {
    hex: '#8DF863',
    rgb: '141, 248, 99',
    100: '#C6FBB1',
    200: '#B6FA9B',
    300: '#A6F985',
    400: '#95F86E',
    500: '#7CF74B',
    600: '#59F41C',
    700: '#44D50A',
    800: '#35A608',
    900: '#267606',
  },
  azure: {
    hex: '#56A6DC',
    rgb: '86, 166, 220',
    100: '#AAD3EE',
    200: '#92C6E9',
    300: '#7AB9E4',
    400: '#62ADDF',
    500: '#449DD9',
    600: '#2988C8',
    700: '#216FA3',
    800: '#1A577F',
    900: '#133E5B',
  },
  cyan: {
    50: '#EDFDFD',
    100: '#C4F1F9',
    200: '#9DECF9',
    300: '#76E4F7',
    400: '#0BC5EA',
    500: '#00B5D8',
    600: '#00A3C4',
    700: '#0987A0',
    800: '#086F83',
    900: '#065666',
  },
  purple: {
    hex: '#A64E6D',
    rgb: '166, 78, 109',
    50: '#FEE7EF',
    100: '#FBBCD3',
    200: '#F990B7',
    300: '#F6659B',
    400: '#F3397F',
    500: '#F10E63',
    600: '#C10B4F',
    700: '#91083B',
    800: '#600627',
    900: '#300314',
  },
  gold: {
    hex: '#D4A92F',
    rgb: '212, 169, 47',
    100: '#EAD497',
    200: '#E4C77A',
    300: '#DEBB5C',
    400: '#D8AF3E',
    500: '#C89E29',
    600: '#AA8623',
    700: '#8B6D1C',
    800: '#6C5516',
    900: '#4D3D10',
  },
  aqua: {
    hex: '#56DCB9',
    rgb: '86, 220, 185',
    50: '#C3F3E6',
    100: '#C3F3E6',
    200: '#AAEEDC',
    300: '#92E9D2',
    400: '#7AE4C8',
    500: '#62DFBD',
    600: '#44D9B1',
    700: '#29C89D',
    800: '#21A381',
    900: '#1A7F64',
  },
  pink: {
    50: '#FEE7E8',
    100: '#FCBBBE',
    200: '#F98F94',
    300: '#F76369',
    400: '#F5383F',
    500: '#F30C15',
    600: '#C20A11',
    700: '#92070D',
    800: '#610509',
    900: '#310204',
  },
  google: {
    50: '#FDE8E7',
    100: '#FAC0BD',
    200: '#F69792',
    300: '#F36E68',
    400: '#F0453D',
    500: '#EC1C13',
    600: '#BD170F',
    700: '#8E110B',
    800: '#5E0B08',
    900: '#2F0604',
  },
  github: {
    50: '#F1F2F3',
    100: '#D9DBDE',
    200: '#C0C4C9',
    300: '#A7ADB4',
    400: '#8E959E',
    500: '#767E89',
    600: '#5E656E',
    700: '#474C52',
    800: '#2F3337',
    900: '#18191B',
  },
  groups: {
    Time: {
      color: '#FA804C',
      light: '#FB8E60',
      dark: '#F97239',
    },
    Inputs: {
      color: '#F8637A',
      light: '#F9798D',
      dark: '#F53451',
    },
    Outputs: {
      color: '#56DCB9',
      light: '#78E3C7',
      dark: '#5EDEBC',
    },
    Notifications: {
      color: '#8CC152',
      light: '#9CCA6B',
      dark: '#78AE3F',
    },
    DS4Biz: {
      color: '#FFB300',
      light: '#FFBD24',
      dark: '#DB9900',
    },
    Core: {
      color: '#FFB300',
      light: '#FFBD24',
      dark: '#DB9900',
    },
    HTTP: {
      color: '#56A6DC',
      light: '#6EB3E1',
      dark: '#3193D5',
    },
    Brokers: {
      color: '#A8C2BC',
      light: '#AEC7C1',
      dark: '#7AA399',
    },
    Parser: {
      color: '#56DCB9',
      light: '#6EE1C2',
      dark: '#31D5A9',
    },
    Common: {
      color: '#D770AD',
      light: '#DC84B9',
      dark: '#CC4B99',
    },
    Cloud: {
      color: '#74C339',
      light: '#87CD52',
      dark: '#63A630',
    },
    MQTT: {
      color: '#0097CC',
      light: '#00B6F8',
      dark: '#0080AF',
    },
    Integrations: {
      color: '#888888',
      light: '#AAAAAA',
      dark: '#666666',
    },
    component_instance: {
      color: '#888888',
      light: '#AAAAAA',
      dark: '#666666',
    },
  },
};
