import { useEffect, useRef } from 'react';
import { IconButton, Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react';
import { RiCloseLine, RiSearchLine } from 'react-icons/ri';
import { useDebouncedCallback } from 'use-debounce';

export function SearchbarFileManager({ value = '', onChange }: { value: string; onChange: (search: string) => void }) {
  const searchRef = useRef<HTMLInputElement>(null);
  const debounced = useDebouncedCallback((newValue) => {
    onChange(newValue);
  }, 300);

  useEffect(() => {
    // Empty searchbar on folder change
    if (searchRef.current && searchRef.current.value !== value) {
      searchRef.current.value = '';
    }
  }, [value]);

  return (
    <InputGroup size="sm">
      <InputLeftElement pointerEvents="none" ml={0.5}>
        <RiSearchLine />
      </InputLeftElement>
      <Input
        ref={searchRef}
        placeholder="Filter entries"
        defaultValue={value}
        onChange={(e) => debounced(e.target.value)}
      />
      <InputRightElement>
        {value && (
          <IconButton
            mr={-2}
            aria-label="Delete search"
            variant="unstyled"
            size="sm"
            onClick={() => {
              if (searchRef.current) {
                searchRef.current.value = '';
                onChange('');
              }
            }}
            icon={<RiCloseLine />}
          />
        )}
      </InputRightElement>
    </InputGroup>
  );
}
