/* eslint-disable react/no-children-prop */
import { ReactNode } from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Switch,
  Textarea,
  Box,
  Checkbox,
  FormControlProps,
  Button,
  Text,
} from '@chakra-ui/react';
import { deepClone } from 'ds4biz-core';
import { Controller, useFormContext } from 'react-hook-form';
import { RiCloseFill, RiInformationFill } from 'react-icons/ri';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { GatewayDependent } from 'shared/Form/GatewayDependent';
import { IField, regexMapping, requiredLabel } from 'constants/form';
import { CodeEditor, Mode } from 'shared/CodeEditor';
import { ColorPicker } from 'shared/ColorPicker';
import { FileManager } from 'shared/FileManager';
import { BaseSelect } from 'shared/Form/BaseSelect';
import { Path } from './Path';
import { GatewaySelect } from './GatewaySelect';
import { AsyncSelect, AsyncSelectProps } from './AsyncSelect';
import { Dynamic } from './Dynamic';
import { MultiKeyValue, MultiKeyValueField } from './MultiKeyValue';

function getFieldType(_field: IField, register: any) {
  const fieldsList: {
    [key: string]: (field: any) => ReactNode;
  } = {
    password(field: IField) {
      return (
        <Input
          size="sm"
          ref={register}
          id={field.name}
          name={field.name}
          type="password"
          placeholder={field.placeholder}
        />
      );
    },
    text(field: IField) {
      return (
        <Input size="sm" ref={register} id={field.name} name={field.name} type="text" placeholder={field.placeholder} />
      );
    },
    email(field: IField) {
      return (
        <Input
          size="sm"
          ref={register}
          id={field.name}
          name={field.name}
          type="email"
          placeholder={field.placeholder}
        />
      );
    },
    multiKeyValue(field: MultiKeyValueField) {
      return <MultiKeyValue field={field} />;
    },
    number(field: IField & { min?: number; max?: number }) {
      return (
        <Input
          size="sm"
          ref={register}
          id={field.name}
          name={field.name}
          type="number"
          inputMode="numeric"
          placeholder={field.placeholder}
          min={field.min || 'none'}
          max={field.max || 'none'}
          step="any"
        />
      );
    },
    area(field: IField) {
      return (
        <Textarea
          size="sm"
          borderRadius="md"
          id={field.name}
          name={field.name}
          ref={register}
          placeholder={field.placeholder}
        />
      );
    },
    select(field: IField & { options: any[] }) {
      return <BaseSelect field={field} options={field.options} />;
    },
    asyncSelect(field: AsyncSelectProps['field']) {
      return <AsyncSelect field={field} />;
    },
    color(field: IField & { palette?: string[] }) {
      return (
        <Controller
          name={field.name}
          rules={field.validation}
          render={({ onChange, value }) => <ColorPicker onChange={onChange} value={value} palette={field.palette} />}
        />
      );
    },
    boolean(field: IField) {
      return <Switch name={field.name} ref={register} size="sm" />;
    },
    checkbox(field: IField) {
      return (
        <Checkbox name={field.name} ref={register}>
          {field.placeholder}
        </Checkbox>
      );
    },
    service(
      field: IField & {
        fragment: string;
      },
    ) {
      return <GatewaySelect field={field} />;
    },
    dependent(
      field: IField & {
        fragment: string;
        parent: string;
      },
    ) {
      return <GatewayDependent field={field} />;
    },
    path(field: IField) {
      return <Path field={field} />;
    },
    files(field: IField) {
      return (
        <Controller
          name={field.name}
          rules={{
            validate: (value) => {
              if (!value && field.validation?.required) {
                return requiredLabel;
              }

              if (value.isDir) {
                return 'Select only files';
              }
            },
          }}
          render={({ onChange, value }) => (
            <>
              {value && (
                <Box>
                  <Text>Selected element:</Text>
                  <Button
                    variant="link"
                    colorScheme="cyan"
                    size="sm"
                    textDecor="underline"
                    rightIcon={<RiCloseFill onClick={() => onChange(null)} />}
                  >
                    {value.path}
                  </Button>
                </Box>
              )}
              <FileManager
                onItemClick={onChange}
                showRange={false}
                editable={false}
                multipleSelection={false}
                shortcutsEnabled={false}
                selected={value ? [value.name] : []}
                path={value ? value.parent : '/'}
                isHeaderSticky={false}
                p={1}
              />
            </>
          )}
        />
      );
    },
    code(field: IField & { mode: Mode }) {
      return (
        <Controller
          name={field.name}
          rules={field.validation}
          render={({ onChange, value }) => (
            <CodeEditor onChange={(newValue) => onChange(newValue)} value={value} mode={field.mode} />
          )}
        />
      );
    },
    directories(field: IField) {
      return (
        <Controller
          name={field.name}
          rules={{
            validate: (value) => {
              if (!value && field.validation?.required) {
                return requiredLabel;
              }

              if (!value.isDir) {
                return 'Select only directories';
              }
            },
          }}
          render={({ onChange, value }) => (
            <>
              {value && (
                <Box>
                  <Text>Selected element:</Text>
                  <Button
                    variant="link"
                    colorScheme="cyan"
                    size="sm"
                    textDecor="underline"
                    rightIcon={<RiCloseFill onClick={() => onChange(null)} />}
                  >
                    {value.path}
                  </Button>
                </Box>
              )}
              <FileManager
                onItemClick={onChange}
                showRange={false}
                editable={false}
                multipleSelection
                shortcutsEnabled={false}
                selected={value ? [value.name] : []}
                path={value ? value.parent : '/'}
                isHeaderSticky={false}
                p={1}
              />
            </>
          )}
        />
      );
    },
  };

  if (fieldsList[_field.type]) {
    return fieldsList[_field.type](_field);
  }
}

interface FieldProps {
  field: IField;
  customStyle?: FormControlProps;
}

export function Field({ field, customStyle = {} }: FieldProps) {
  const { register, errors } = useFormContext();

  const parsedValidation: {
    [key: string]: any;
  } = deepClone(field.validation || {});

  // Retrieve from regex mapping the right match
  if (parsedValidation?.pattern) {
    // If match doesn't exist, we remove the key
    if (regexMapping[parsedValidation.pattern.value]) {
      parsedValidation.pattern.value = regexMapping[parsedValidation.pattern.value];
    } else {
      delete parsedValidation.pattern;
    }
  }

  return (
    <>
      {field.type === 'dynamic' ? (
        <Dynamic field={field as IField & { parent: string; condition: string; dynamicType: string }} />
      ) : (
        <FormControl
          mb="6"
          isInvalid={errors[field.name]}
          display={field.type === 'boolean' ? 'flex' : 'inherit'}
          justifyContent={field.type === 'boolean' ? 'space-between' : 'unset'}
          alignItems={field.type === 'boolean' ? 'center' : 'unset'}
          textAlign={field.type === 'checkbox' ? 'left' : 'unset'}
          {...customStyle}
        >
          <FormLabel
            htmlFor={field.label || field.name}
            fontSize="xs"
            fontWeight="600"
            marginBottom={field.type === 'boolean' ? 0 : '2px'}
            alignItems="center"
            d="flex"
          >
            {!field.hideLabel && (
              <>
                {field.type !== 'checkbox' && (field.label || field.name)}
                {field.type !== 'checkbox' && parsedValidation.required && (
                  <Box as="span" color="orange.500" ml={0.25}>
                    *
                  </Box>
                )}
              </>
            )}
            {field.description && (
              <Popover placement="left">
                <PopoverTrigger>
                  <IconButton
                    top="4px"
                    right="-8px"
                    aria-label="Show description"
                    position={field.type === 'boolean' ? 'initial' : 'absolute'}
                    ml={field.type === 'boolean' ? '1' : '0'}
                    size="sm"
                    variant="link"
                    icon={<RiInformationFill />}
                  />
                </PopoverTrigger>
                <PopoverContent maxW="250px" borderRadius="md">
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>{field.label}</PopoverHeader>
                  <PopoverBody>
                    <ReactMarkdown plugins={[gfm]} children={field.description} />
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            )}
          </FormLabel>
          {getFieldType({ ...field, validation: parsedValidation }, register(parsedValidation))}
          {field.helper && (
            <FormHelperText id={field.name} fontStyle="italic" fontSize="xs">
              {field.helper}
            </FormHelperText>
          )}
          <FormErrorMessage fontSize="xs" mt={1}>
            {(errors[field.name] && errors[field.name].message) || 'Check field values'}
          </FormErrorMessage>
        </FormControl>
      )}
    </>
  );
}
