import { ColorMode, theme } from '@chakra-ui/react';

export const Modal = {
  ...theme.components.Modal,
  parts: ['overlay', 'dialogContainer', 'dialog', 'header', 'closeButton', 'body', 'footer'],
  baseStyle: ({ colorMode }: { colorMode: ColorMode }) => ({
    dialog: {
      backgroundColor: colorMode === 'dark' ? 'gray.800' : 'white',
      borderRadius: '0.5rem',
      boxShadow: '2xl',
      padding: 0,
      header: {
        pl: 6,
        pb: 2,
      },
      body: {
        pl: 6,
        py: 2,
      },
    },
    overlay: {
      bg: colorMode === 'dark' ? 'whiteAlpha.300' : 'blackAlpha.300',
    },
  }),
};
