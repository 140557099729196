import { useColorModeValue } from '@chakra-ui/color-mode';
import { PieSvgProps, ResponsivePie } from '@nivo/pie';

export function Pie({ data, ...rest }: Omit<PieSvgProps<Record<string, unknown>>, 'width' | 'height'>) {
  const textColor = useColorModeValue('#222222', '#C9C9C9');
  const gridColor = useColorModeValue('#ddd', '#444');
  return (
    <ResponsivePie
      data={data}
      margin={{ top: 40, right: 80, bottom: 40, left: 80 }}
      innerRadius={0.5}
      padAngle={0.7}
      theme={{
        fontFamily: 'Share Tech Mono',
        textColor,
        grid: {
          line: {
            stroke: gridColor,
            strokeWidth: 1,
          },
        },
      }}
      arcLinkLabelsColor={useColorModeValue('black', 'white')}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsThickness={2}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
      {...rest}
    />
  );
}
