import { useRef, ChangeEvent } from 'react';
import { Button, ButtonProps } from '@chakra-ui/button';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';

export function FileUpload({
  label = 'Upload',
  onUpload,
  multiple = false,
  accept = '*',
  isDisabled,
  ...rest
}: FileUploadProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Button {...rest} onClick={() => inputRef.current && inputRef.current.click()} isDisabled={isDisabled}>
      <FormControl>
        <FormLabel
          htmlFor="file-upload"
          aria-describedby="upload-files-button"
          m="inherit"
          p="inherit"
          cursor="inherit"
          fontSize="inherit"
          fontWeight="inherit"
          verticalAlign="inherit"
          onClick={(e) => e.stopPropagation()}
        >
          {label}
          <Input
            ref={inputRef}
            multiple={multiple}
            accept={accept}
            type="file"
            id="file-upload"
            d="none"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              onUpload(e.target.files);
              // eslint-disable-next-line no-param-reassign
              e.target.value = '';
            }}
            isDisabled={isDisabled}
          />
        </FormLabel>
      </FormControl>
    </Button>
  );
}

interface FileUploadProps extends ButtonProps {
  label: string;
  onUpload: (files: FileList | null) => void;
  multiple?: boolean;
  accept?: string;
}
