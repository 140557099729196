import { ReactNode, useEffect, useState } from 'react';
import { Button, Image, Text, ButtonGroup, Box, IconButton } from '@chakra-ui/react';
import { RiArrowGoBackLine, RiCloseFill } from 'react-icons/ri';
import { JsonViewer } from 'shared/FileManager/Editor/JsonViewer';
import { Column, Header, Content } from 'shared/Layout';
import { Table } from 'shared/Table';
import { arrayBufferToBase64, GenericObject, getFileExtension } from 'utils/misc';
import { AxiosResponse } from 'axios';
import { orchestratorCrud } from 'services/api';
import { EDITOR_STORAGE } from 'constants/localStorage';
import useLocalStorage from '@rehooks/local-storage';
import { PredictorDetails } from 'views/Predictors/PredictorDetails';
import { Item } from '../types';
import { TextViewer } from './TextViewer';

export function Editor({ edit, onClose }: { edit: { item: Item; editor?: string }; onClose: () => void }) {
  const [editor, setEditor] = useState<ReactNode>(null);

  const [storedEditor] = useLocalStorage<{ [extension: string]: string }>(EDITOR_STORAGE, {});

  function getApplication(extension: string) {
    const defaultApplication = storedEditor[extension];

    if (defaultApplication) {
      return defaultApplication;
    }

    switch (extension) {
      case 'png':
      case 'jpg':
      case 'gif':
      case 'jpeg': {
        return 'image_view';
      }
      case 'txt': {
        return 'text';
      }
      case 'template':
      case 'metadata':
      case 'project':
      case 'json': {
        return 'json';
      }
      case 'csv': {
        return 'csv';
      }
      case 'predictor': {
        return 'predictor';
      }
      case 'pdf': {
        return 'pdf';
      }
      case 'eval': {
        return 'predictor';
      }
      default: {
        return 'download';
      }
    }
  }
  useEffect(() => {
    async function openEditor() {
      if (edit) {
        const { item } = edit;
        const extension = getFileExtension(item.name);

        if (extension) {
          const defaultEditor = edit.editor || getApplication(extension);

          switch (defaultEditor) {
            case 'csv': {
              setEditor(<Table path={item.path} />);

              break;
            }
            case 'image_view': {
              const response = await orchestratorCrud.getOne<AxiosResponse<string>>('files', item.path, {
                responseType: 'arraybuffer',
                full: true,
              });

              const data = arrayBufferToBase64(response.data, response.headers['content-type']);

              setEditor(<Image objectFit="contain" src={data} alt={item.path} mx="auto" maxH="82vh" />);

              break;
            }
            case 'json': {
              setEditor(<JsonViewer item={item} />);
              break;
            }
            case 'predictor': {
              const data = await orchestratorCrud.getOne<GenericObject>('files', item.path);

              setEditor(<PredictorDetails evaluate={data} />);
              break;
            }
            case 'text': {
              setEditor(<TextViewer item={item} />);
              break;
            }
            case 'pdf': {
              let data = await orchestratorCrud.getOne<string>('files', item.path, {
                responseType: 'arraybuffer',
              });

              data = arrayBufferToBase64(data, 'application/pdf');

              setEditor(
                <object data={data} type="application/pdf" style={{ width: '100%', height: 'calc(100vh - 120px)' }}>
                  Document
                </object>,
              );

              break;
            }
            default: {
              setEditor(
                <Text align="center">
                  No editor available for this file{' '}
                  <Button
                    variant="link"
                    fontSize="14px"
                    color="inherit"
                    fontWeight={500}
                    onClick={onClose}
                    textDecor="underline"
                    leftIcon={<RiArrowGoBackLine size="12" />}
                  >
                    go back
                  </Button>
                </Text>,
              );
              break;
            }
          }
        }
      }
    }

    openEditor();
  }, [edit]);

  return (
    <Column id="editor" flexDirection="column" p="2" height="full" overflow="auto">
      <Header flex="0 1 3rem" py={1}>
        <ButtonGroup variant="ghost" size="md" justifyContent="flex-start">
          <IconButton aria-label="Close editor" icon={<RiCloseFill fontSize="24px" />} onClick={onClose} />
        </ButtonGroup>
      </Header>
      <Content width="100%" height="100%" id="editorContent">
        <Box px={4}>{editor}</Box>
      </Content>
    </Column>
  );
}
