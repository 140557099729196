import { ColorMode } from '@chakra-ui/react';

export const IconBar = {
  baseStyle: ({ colorMode }: { colorMode: ColorMode }) => ({
    width: 54,
    alignItems: 'center',
    padding: '0.125rem 0',
    flexDirection: 'column',
    borderRightWidth: '1px',
    justifyContent: 'center',
    background: colorMode === 'dark' ? 'gray.800' : 'gray.50',
    borderColor: colorMode === 'dark' ? 'gray.700' : 'gray.100',
  }),
};
