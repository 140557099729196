import { Editable, EditableInput, EditableInputProps, EditablePreview, EditableProps } from '@chakra-ui/editable';
import { ReactNode, useEffect, useRef } from 'react';

export function EditableItem({
  value,
  editMode,
  onRename,
  onCancel,
  inputStyle = {},
  children,
  ...rest
}: EditableItemProps) {
  const editableRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (editMode) {
      editableRef.current?.focus();
    }
  }, [editMode]);

  return (
    <>
      {editMode ? (
        <Editable
          {...rest}
          defaultValue={value}
          startWithEditView
          submitOnBlur={false}
          onBlur={() => onCancel(value)}
          onCancel={() => onCancel(value)}
          onSubmit={(newValue) => onRename(value, newValue)}
        >
          <EditablePreview px="1" />
          <EditableInput px="1" {...inputStyle} ref={editableRef as React.LegacyRef<HTMLInputElement>} />
        </Editable>
      ) : (
        children
      )}
    </>
  );
}

interface EditableItemProps extends EditableProps {
  value: string;
  onCancel: (value: string) => void;
  onRename: (oldValue: string, newValue: string) => void;
  children: ReactNode;
  inputStyle?: EditableInputProps;
  editMode: boolean;
}
