import { forwardRef, LegacyRef, ReactNode } from 'react';
import { Flex, FlexProps, StylesProvider, useMultiStyleConfig, useStyles } from '@chakra-ui/react';

// eslint-disable-next-line react/display-name
export const Column = forwardRef((props: FlexProps, ref: LegacyRef<HTMLDivElement> | undefined) => {
  const styles = useMultiStyleConfig('Layout', props);

  return (
    <Flex ref={ref} sx={styles.column} flex={props.flex || '1 0 4rem'} {...props}>
      <StylesProvider value={styles}>{props.children}</StylesProvider>
    </Flex>
  );
});

export function Header({ children, flex = '0 1 100%', ...rest }: LayoutProps) {
  const styles = useStyles();

  return (
    <Flex sx={styles.header} flex={flex} {...rest}>
      {children}
    </Flex>
  );
}

export function Content({ children, flex = '0 1 100%', ...rest }: LayoutProps) {
  const styles = useStyles();

  return (
    <Flex sx={styles.content} flex={flex} {...rest}>
      {children}
    </Flex>
  );
}

export function Footer({ children, flex = '0 1 100%', ...rest }: LayoutProps) {
  const styles = useStyles();

  return (
    <Flex sx={styles.footer} flex={flex} {...rest}>
      {children}
    </Flex>
  );
}

interface LayoutProps extends FlexProps {
  children: ReactNode;
  flex?: string;
}
