import { Action, Dispatch, Store } from '@reduxjs/toolkit';
import { UNDOS_STORAGE } from 'constants/localStorage';
import { orchestratorCrud } from 'services/api';
import {
  addComment,
  addTab,
  addNode,
  changeHandleStatus,
  createEdge,
  deleteComment,
  deleteEdges,
  deleteTab,
  deleteNodes,
  editComment,
  renameTab,
  setTab,
  updateNodeValues,
  lockNode,
  unlockNodes,
  FlowState,
} from 'store/reducers/flow';

// Only generate undo
const undoActions = [
  setTab.type,
  deleteNodes.type,
  deleteEdges.type,
  changeHandleStatus.type,
  createEdge.type,
  addNode.type,
  addComment.type,
  editComment.type,
  deleteComment.type,
];

// This actions launch a project overwrite (PUT)
const saveActions = [
  setTab.type,
  addTab.type,
  deleteTab.type,
  renameTab.type,
  deleteNodes.type,
  deleteEdges.type,
  changeHandleStatus.type,
  createEdge.type,
  addNode.type,
  addComment.type,
  editComment.type,
  deleteComment.type,
  updateNodeValues.type,
  lockNode.type,
  unlockNodes.type,
];

export const flowSaver = (store: Store) => (next: Dispatch) => (action: Action) => {
  const oldFlow = store.getState().flow;

  next(action);

  if (saveActions.includes(action.type)) {
    const { status, ...project }: FlowState = store.getState().flow;

    if (undoActions.includes(action.type)) {
      const storedUndos = JSON.parse(localStorage.getItem(UNDOS_STORAGE) || '[]');

      if (storedUndos.length > 4) {
        storedUndos.shift();
      }

      storedUndos.push(oldFlow);

      localStorage.setItem(UNDOS_STORAGE, JSON.stringify(storedUndos));
    }

    orchestratorCrud.overwriteOne('projects', project.id, project);
  }
};
