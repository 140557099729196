import { Fragment, ReactNode, useEffect, useMemo, useState } from 'react';
import {
  BoxProps,
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
  Divider,
  Flex,
  FormControlProps,
  Grid,
  GridItem,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { areObjectEquals } from 'ds4biz-core';
import { FormProvider, useForm } from 'react-hook-form';
import { IField } from 'constants/form';
import { Field } from './Field';

function organizeSettings(
  settings: IField[],
  defaultValues?: { [field: string]: any },
): { flat: IField[]; grouped: { [group: string]: IField[] }; sanitizedDefaultValues: Record<string, unknown> } {
  // Separate grouped fields from flat fields
  const group = settings.reduce((acc: { [group: string]: IField[] }, field) => {
    acc[field.group || 'flat'] = [...(acc[field.group || 'flat'] || []), field];
    return acc;
  }, {});

  const { flat, ...rest } = group;

  const sanitizedDefaultValues: {
    [value: string]: string;
  } = {};

  // Initialize all fields as empty string (required for Controller component)
  settings.forEach(({ name }) => (sanitizedDefaultValues[name] = defaultValues?.[name] ?? ''));

  return { flat: flat || [], grouped: rest, sanitizedDefaultValues };
}

interface FormProps {
  defaultValues?: Record<string, unknown>;
  mode?: 'onBlur' | 'onChange' | 'onSubmit' | 'onTouched' | 'all';
  onSubmit: (values: any | null) => void;
  onChange?: (values: any, { isDirty, errors }: { isDirty: boolean; errors: Record<string, unknown> }) => void;
  // Fields name returned to OnChange callback when values change
  watchers?: string[];
  isLoading?: boolean;
  settings: IField[];
  formStyle?: BoxProps;
  buttonGroupStyle?: ButtonGroupProps;
  submitButtonStyle?: ButtonProps;
  fieldStyle?: FormControlProps;
  submitLabel?: string;
  cancelLabel?: string;
  cancelButton?: boolean;
  submitButtonsOnTop?: boolean;
  customFooter?: ReactNode;
}

export function Form({
  defaultValues,
  mode = 'onSubmit',
  onSubmit,
  onChange,
  watchers,
  settings,
  isLoading,
  formStyle = {},
  buttonGroupStyle = {},
  submitButtonStyle = {},
  fieldStyle = {},
  submitLabel = 'Save',
  cancelButton = true,
  cancelLabel = 'Cancel',
  submitButtonsOnTop,
  customFooter,
}: FormProps) {
  const [fieldStatuses, setFieldStatuses] = useState({});

  const { flat, grouped, sanitizedDefaultValues } = useMemo(
    () => organizeSettings(settings, defaultValues),
    [settings, defaultValues],
  );

  const methods = useForm({
    mode,
    defaultValues: sanitizedDefaultValues,
  });

  const { isDirty, errors } = methods.formState;

  // Setted null to avoid useless render if we don't want to track any fields change
  // eslint-disable-next-line no-nested-ternary
  const watchAllFields = onChange
    ? watchers && watchers.length > 0
      ? methods.watch(watchers)
      : methods.watch()
    : null;

  useEffect(() => {
    if (watchAllFields && onChange && !areObjectEquals(fieldStatuses, watchAllFields)) {
      setFieldStatuses(watchAllFields);
      onChange(watchAllFields, { isDirty, errors });
    }
  }, [watchAllFields]);

  return (
    <FormProvider {...methods}>
      <Flex
        w="full"
        h="full"
        as="form"
        zIndex="0"
        pos="relative"
        onSubmit={methods.handleSubmit(onSubmit)}
        flexDir={submitButtonsOnTop ? 'column-reverse' : 'column'}
        {...formStyle}
      >
        <Flex flexDir="column">
          <Grid templateColumns="repeat(12, 1fr)" columnGap={2}>
            {flat.map((field) => (
              <GridItem key={field.name} colSpan={field.colSpan || 12}>
                <Field field={field} customStyle={fieldStyle} />
                {field.divider && <Divider mb={4} />}
              </GridItem>
            ))}
          </Grid>

          {Object.keys(grouped).length > 0 && (
            <Tabs variant="enclosed" mt="8" size="sm">
              <TabList>
                {Object.keys(grouped).map((group) => (
                  <Tab key={group}>{group}</Tab>
                ))}
              </TabList>

              <TabPanels>
                {Object.keys(grouped).map((group) => (
                  <TabPanel key={group} px="0">
                    {grouped[group].map((field) => (
                      <Fragment key={field.name}>
                        <Field field={field} />
                        {field.divider && <Divider mb={4} />}
                      </Fragment>
                    ))}
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          )}
        </Flex>

        {customFooter ? (
          <>{customFooter}</>
        ) : (
          <ButtonGroup
            spacing={2}
            size="sm"
            variant="ghost"
            display="flex"
            py="2"
            justifyContent="flex-end"
            {...buttonGroupStyle}
          >
            {cancelButton && (
              <Button variant="ghost" onClick={() => onSubmit(null)}>
                {cancelLabel}
              </Button>
            )}
            {settings.length > 0 && (
              <Button isLoading={isLoading} type="submit" variant="solid" colorScheme="red" {...submitButtonStyle}>
                {submitLabel}
              </Button>
            )}
          </ButtonGroup>
        )}
      </Flex>
    </FormProvider>
  );
}
