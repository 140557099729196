import { ChangeEvent, ReactNode } from 'react';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Box, Flex, GridItem } from '@chakra-ui/layout';
import { GridItemProps, Menu, MenuList, Button, MenuButton, MenuItem } from '@chakra-ui/react';
import { Stat, StatLabel } from '@chakra-ui/stat';
import { Switch } from '@chakra-ui/switch';
import { RiArrowDownSFill } from 'react-icons/ri';
import StatBgImage from 'assets/images/stat-bg-graphic-seamless.svg';

interface PredictorDetailsBoxProps extends GridItemProps {
  title: string;
  viewSwitch?: boolean;
  viewSwitchLabel?: string;
  exportable?: boolean;
  children: ReactNode;
  onViewChange?: (event: ChangeEvent) => void;
  onExport?: (type: 'zip' | 'blueprint') => void;
}

export function PredictorDetailsBox({
  title,
  viewSwitch,
  viewSwitchLabel,
  children,
  exportable,
  onViewChange,
  onExport,
  ...rest
}: PredictorDetailsBoxProps) {
  const statBgGradient = useColorModeValue(
    'linear-gradient(to top right, #FAFAFA, #EFEFEF)',
    'linear-gradient(to top right, #222222, #343434)',
  );
  const statBg = useColorModeValue('gray.100', 'gray.800');
  const titleColor = useColorModeValue('gray.800', 'gray.100');

  return (
    <GridItem
      pos="relative"
      overflow="hidden"
      h={{ base: '70vh', md: '60vh' }}
      color="gray.500"
      borderRadius="2xl"
      {...rest}
    >
      <Box
        px={6}
        pt={4}
        pb={12}
        h="full"
        minH={50}
        bgColor={statBg}
        bgImage={`url(${StatBgImage}), ${statBgGradient}`}
        bgRepeat="no-repeat, no-repeat"
        bgPosition="bottom center, bottom center"
        bgSize="cover, cover"
      >
        <Flex mt={1}>
          <Stat>
            {title && (
              <StatLabel fontSize="md" color={titleColor}>
                {title}
              </StatLabel>
            )}
          </Stat>
          {exportable && onExport && (
            <Flex alignItems="center" justifyContent="space-between">
              <Flex>
                <Menu>
                  <MenuButton as={Button} size="sm" variant="ghost" rightIcon={<RiArrowDownSFill />}>
                    Export
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => onExport('blueprint')}>Blueprint</MenuItem>
                    <MenuItem onClick={() => onExport('zip')}>Zip</MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            </Flex>
          )}
          {viewSwitch && (
            <Flex>
              <FormControl display="flex" alignItems="center" justifyContent="flex-end">
                <FormLabel fontSize="xs" htmlFor="tableView" mb={0}>
                  {viewSwitchLabel || 'Table view / Chart view'}
                </FormLabel>
                <Switch size="sm" id="tableView" onChange={onViewChange} />
              </FormControl>
            </Flex>
          )}
        </Flex>
        {children && (
          <Flex mt={8} h="calc(100% - 32px)" maxH="400px" overflow="auto" flexDirection="column">
            {children}
          </Flex>
        )}
      </Box>
    </GridItem>
  );
}
