import { ColorMode } from '@chakra-ui/react';

export const Layout = {
  parts: ['column', 'header', 'filter', 'content', 'footer'],
  baseStyle: ({ colorMode }: { colorMode: ColorMode }) => ({
    column: {
      // overflow: "hidden",
      alignItems: 'center',
      position: 'relative',
      flexDirection: 'column',
      justifyContent: 'center',
      borderColor: colorMode === 'dark' ? 'gray.700' : 'gray.400',
    },
    header: {
      position: 'relative',
      width: '100%',
      // overflow: "hidden",
      fontSize: '0.875rem',
      borderColor: colorMode === 'dark' ? 'gray.700' : 'gray.400',
      flexDirection: 'column',
    },
    filter: {
      width: '100%',
      position: 'relative',
      transition: 'all 0.3s',
    },
    content: {
      position: 'relative',
      width: '100%',
      flex: '1 1 100%',
      flexDirection: 'column',
      // overflowX: "hidden",
      overflowY: 'auto',
      borderColor: colorMode === 'dark' ? 'gray.700' : 'gray.400',
    },
    footer: {
      width: '100%',
      position: 'relative',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      borderColor: colorMode === 'dark' ? 'gray.700' : 'gray.400',
    },
  }),
};
