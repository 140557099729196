import { theme } from '@chakra-ui/react';

export const Menu = {
  ...theme.components.Menu,
  baseStyle: {
    list: {
      padding: 0,
      width: '240px',
    },
  },
  sizes: {
    sm: {
      fontSize: '0.75rem',
    },
    md: {
      fontSize: '0.8125rem',
    },
    lg: {
      fontSize: '1rem',
    },
  },
  defaultProps: {
    size: 'sm',
  },
};
