import { Component } from 'react';
import { Box, Button, ButtonGroup, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { RiEmotionSadLine } from 'react-icons/ri';

function Error() {
  const authFormBackground = useColorModeValue('gray.100', 'gray.700');

  return (
    <Flex w="full" h="100vh" alignItems="center" justifyContent="center" flexDirection="column">
      <Box
        px="4"
        py="7"
        minWidth="320px"
        borderRadius="xl"
        textAlign="center"
        border="1px solid"
        borderColor={authFormBackground}
      >
        <Icon as={RiEmotionSadLine} w={10} h={10} my="4" />
        <Text color="red.400" mb="5">
          There was an error in loading this page.
        </Text>

        <ButtonGroup spacing="2">
          <Button onClick={() => window.location.reload()}>Retry</Button>
          <Button as="a" href="/welcome">
            Homepage
          </Button>
        </ButtonGroup>
      </Box>
    </Flex>
  );
}

export class ErrorBoundary extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    // eslint-disable-next-line react/no-unused-state
    error: '',
    hasError: false,
  };

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error };
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return <Error />;
    }

    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}
