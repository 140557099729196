import { Button } from '@chakra-ui/button';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { Flex, Spacer } from '@chakra-ui/layout';
import { chakra, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';
import { navbarHeight } from 'constants/layout';
import { requiredLabel } from 'constants/form';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { ReactNode } from 'react';
import { FaBook } from 'react-icons/fa';
import { RiQuestionAnswerFill, RiShareForwardFill } from 'react-icons/ri';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { gatewayCrud } from 'services/api';
import { useConfirm } from 'shared/ConfirmerProvider';
import { LogoFlat } from 'shared/ImageSprite';
import { showToast } from 'shared/Toast';
import { setActiveProject } from 'store/reducers/resources';
import { activeProjectSelector } from 'store/selectors/resources';
import urljoin from 'url-join';
import { PopoverMenu } from './PopoverMenu';

interface SubmenuItemProps {
  label?: string;
  children?: ReactNode;
}

export function SubmenuItem({ label, children }: SubmenuItemProps) {
  return (
    <Flex
      mx={1}
      h="8"
      minW={0}
      fontSize="sm"
      cursor="pointer"
      fontWeight={700}
      borderRadius="md"
      alignItems="center"
      borderStyle="solid"
      fontFamily="Share Tech Mono"
      _hover={{ bg: useColorModeValue('blackAlpha.50', 'whiteAlpha.100') }}
    >
      <Flex px={2} pointerEvents="none" textAlign="center" justifyContent="center" alignItems="center">
        {children}
        {label && <Flex px={1}>{label}</Flex>}
      </Flex>
    </Flex>
  );
}

export function Navbar() {
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const location = useLocation();

  const colorBg = useColorModeValue('white', 'black');

  const user = useAppSelector((state) => state.resources.user);
  const activeProject = useAppSelector(activeProjectSelector);

  const isFlowRoute = location.pathname.startsWith('/projects/') && !!location.pathname.split('/').pop();

  async function handleFriendInvite() {
    const { email } = await confirm({
      title: 'Invite a friend',
      settings: [
        {
          type: 'email',
          label: "Friend's email",
          name: 'email',
          validation: {
            required: requiredLabel,
          },
        },
      ],
      confirmLabel: 'Send',
    });

    if (email && user) {
      gatewayCrud
        .createOne(urljoin('users', user.username, 'invite'), { email })
        .then(() => showToast({ title: 'Friend invited!', type: 'success' }));
    }
  }

  async function handleNewTicket() {
    const { type, description, email } = await confirm({
      title: 'Create ticket',
      body: (
        <chakra.span fontSize="sm" d="block" mb={4}>
          Are you having problems with LOKO AI? Do you need assistance with a specific feature?
          <br />
          Select the category and describe your problem.
          <br />
          <b>We will reply to your registration email, check it out!</b>
        </chakra.span>
      ),
      settings: [
        {
          name: 'type',
          label: 'Issue category',
          type: 'select',
          options: [
            'Log in',
            'Components',
            'Console',
            'Flow',
            'File manager',
            'Crash',
            'Machine learning',
            'Export/Import',
            'Other',
          ],
          validation: {
            required: requiredLabel,
          },
        },
        {
          name: 'description',
          label: 'Issue description',
          type: 'area',
          validation: {
            required: requiredLabel,
          },
        },
        {
          name: 'email',
          label: 'User email',
          type: 'email',
          validation: {
            required: requiredLabel,
          },
        },
      ],
      confirmLabel: 'Submit',
    });

    if (type && description && email) {
      gatewayCrud
        .createOne('/ticket', { type, description, email })
        .then(() => showToast({ title: 'Ticket submitted!' }));
    }
  }
  return (
    <Flex
      mx={3}
      id="navbar"
      pos="fixed"
      zIndex={1090}
      bg={colorBg}
      boxShadow="base"
      alignItems="center"
      w="calc(100vw - 24px)"
      h={`${navbarHeight}px`}
      borderBottomLeftRadius="3xl"
      borderBottomRightRadius="3xl"
    >
      <RouterLink to="/welcome">
        <Flex mx={4}>
          <LogoFlat width="23" />
        </Flex>
      </RouterLink>

      <RouterLink to="/projects">
        <SubmenuItem>Projects</SubmenuItem>
      </RouterLink>

      {activeProject && !isFlowRoute && (
        <RouterLink to={`/projects/${activeProject.id}`}>
          <Tag size="md" borderRadius="full" variant="solid" colorScheme="teal">
            <TagLabel>{activeProject.name}</TagLabel>
            <TagCloseButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                dispatch(setActiveProject(null));
              }}
            />
          </Tag>
        </RouterLink>
      )}
      <RouterLink to="/predictors">
        <SubmenuItem>Predictors</SubmenuItem>
      </RouterLink>

      <RouterLink to="/files">
        <SubmenuItem>Files</SubmenuItem>
      </RouterLink>

      <Spacer />

      <Flex mx={3} alignItems="center" pos="relative" zIndex={80}>
        <RouterLink to="/academy">
          <SubmenuItem label="Academy">
            <FaBook />
          </SubmenuItem>
        </RouterLink>

        <Button variant="unstyled" onClick={handleFriendInvite}>
          <SubmenuItem label="Invite a friend">
            <RiShareForwardFill />
          </SubmenuItem>
        </Button>

        <Button variant="unstyled" onClick={handleNewTicket}>
          <SubmenuItem label="Need help?">
            <RiQuestionAnswerFill />
          </SubmenuItem>
        </Button>
        <PopoverMenu />
      </Flex>
    </Flex>
  );
}
