import { useColorModeValue } from '@chakra-ui/react';
import { HeatMapDatum, HeatMapSvgProps, ResponsiveHeatMap } from '@nivo/heatmap';
import { getAxisTooltip } from './constants';

interface HeatmapProps extends Omit<HeatMapSvgProps<HeatMapDatum, any>, 'width' | 'height'> {
  xLegend?: string;
  yLegend?: string;
}

export function Heatmap({ data, xLegend, yLegend, ...rest }: HeatmapProps) {
  const textColor = useColorModeValue('#222222', '#C9C9C9');
  const contrastColor = useColorModeValue('#434343', '#222222');
  // @ts-ignore
  const newData = [];

  data.forEach((dd, i) => {
    let rowTotalValue = 0;
    let newRowData = {};
    Object.values(dd.data).forEach((d) => {
      // @ts-ignore
      rowTotalValue += d.y;
      newRowData = { ...dd, rowTotalValue };
    });
    newData[i] = newRowData;
  });

  return (
    <ResponsiveHeatMap
      data={data}
      margin={{ bottom: 80, left: 10 }}
      theme={{ fontFamily: 'Share Tech Mono', textColor }}
      forceSquare
      axisTop={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: -45,
        legend: xLegend ?? 'X',
        legendOffset: 66,
        legendPosition: 'middle',
        format: (v) => getAxisTooltip(v),
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        legend: yLegend ?? 'Y',
        legendPosition: 'middle',
        legendOffset: -70,
        format: (v) => getAxisTooltip(v),
      }}
      colors={{
        type: 'sequential',
        scheme: 'cool',
      }}
      cellComponent={(v) => {
        // @ts-ignore
        const t = newData.filter((d: any) => d.id === v.cell.serieId)[0].rowTotalValue;

        // @ts-ignore
        const luminosity = Math.round((v.cell.value / t) * 32);
        const fill = `hsl(358, 90%, ${99 - luminosity}%)`;

        return (
          <g>
            <rect
              transform={`translate(-${v.cell.width * 0.5}, -${v.cell.width * 0.5})`}
              width={v.cell.width - 4}
              height={v.cell.height - 4}
              rx={8}
              ry={8}
              x={v.cell.x + 2}
              y={v.cell.y + 2}
              fill={fill}
            />
            <text
              fontSize={13}
              textAnchor="middle"
              fontWeight={700}
              letterSpacing="-0.08rem"
              transform="translate(0, 5)"
              fill={contrastColor}
              x={v.cell.x}
              y={v.cell.y}
            >
              {v.cell.formattedValue}
            </text>
          </g>
        );
      }}
      emptyColor="#555555"
      hoverTarget="cell"
      {...rest}
    />
  );
}
