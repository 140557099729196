/* eslint-disable prefer-promise-reject-errors */
import { writeStorage, deleteFromStorage } from '@rehooks/local-storage';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { goBack, push } from 'connected-react-router';
import { ACCESS_TOKEN_STORAGE, REFRESH_TOKEN_STORAGE } from 'constants/localStorage';
import { showToast } from 'shared/Toast';
import store from 'store';
import { gatewayCrud, orchestratorCrud } from './api';
import { ExtendedAxiosConfig } from './crud';

function requestHandler(request: AxiosRequestConfig): AxiosRequestConfig {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_STORAGE);

  // eslint-disable-next-line no-param-reassign
  request.headers.Authorization = `Bearer ${accessToken}`;

  return request;
}
// END REQUEST

function successHandler(response: AxiosResponse & { config: AxiosRequestConfig & ExtendedAxiosConfig }) {
  // If full === false then only data is passed as response
  const allResponse = response.config.full;

  return allResponse ? response : response.data;
}

async function errorHandler(error: AxiosError & { config: AxiosRequestConfig & ExtendedAxiosConfig }) {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_STORAGE);

  if (!error?.response) {
    return;
  }

  // Array with status to skip and prevent to display error snackbar
  const skippedCodes = error.config?.skippedCodes || [];

  const { status, data } = error.response || {};

  if (!skippedCodes.includes(status)) {
    if (status === 403) {
      // Not authorized
      store.dispatch(goBack());
    } else if (status === 401) {
      const originalRequest = error.config;

      try {
        // Refresh token
        const { access_token } = await gatewayCrud.createOne<{ access_token: string }>(
          'auth/refresh',
          { refresh_token: refreshToken },
          { skippedCodes: [401] },
        );

        writeStorage(ACCESS_TOKEN_STORAGE, access_token);

        return await orchestratorCrud.instance(originalRequest);
      } catch (err) {
        // Not authenticated
        deleteFromStorage(ACCESS_TOKEN_STORAGE);
        deleteFromStorage(REFRESH_TOKEN_STORAGE);

        store.dispatch(push('/login'));
      }

      return Promise.reject({ ...error });
    }

    const errorMessage = data?.error ? data?.error : 'Some error occurred, try again';

    showToast({ title: errorMessage, type: 'error' });
  }

  return Promise.reject({ ...error });
}
// END RESPONSE

export { requestHandler, successHandler, errorHandler };
