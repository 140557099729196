import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Switch,
  useClipboard,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import useLocalStorage from '@rehooks/local-storage';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { RiExternalLinkFill } from 'react-icons/ri';
import { gatewayCrud } from 'services/api';
import { makeLogout } from 'store/async/resources';
import { Link as RouterLink } from 'react-router-dom';
import urljoin from 'url-join';
import { ACCESS_TOKEN_STORAGE } from 'constants/localStorage';

export function PopoverMenu() {
  const { colorMode, toggleColorMode } = useColorMode();
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.resources.user);

  const popoverBg = useColorModeValue('#fff', 'black');

  const [accessToken] = useLocalStorage<string>(ACCESS_TOKEN_STORAGE);

  const { onCopy, hasCopied } = useClipboard(`Bearer ${accessToken}`);

  return (
    <Popover placement="bottom">
      <PopoverTrigger>
        <Avatar name={user?.username} cursor="pointer" h={8} w={8} size="sm" />
      </PopoverTrigger>
      <PopoverContent w={80} border={0} p={1} mt={0.75} mr={0.5} bg="none" boxShadow="none">
        <PopoverBody px={3} pt={4} pb={2} bg={popoverBg} border={0} borderRadius="3xl" boxShadow="base">
          <Flex flexDir="column" w="full">
            <Flex px={4} mt={1} mb={1} alignItems="center" justifyContent="space-between">
              <Flex flexDir="column" noOfLines={1}>
                <Heading fontWeight={700} fontSize="md">
                  {user?.username || 'User'}
                </Heading>
              </Flex>
              <Avatar name={user?.username} size="sm" />
            </Flex>
            <Divider my={4} />
            <Flex px={4}>
              <Button size="sm" variant="link" as={RouterLink} fontWeight={400} to="/settings" colorScheme="dark">
                Settings
              </Button>
            </Flex>
            <Flex mt={3} px={4}>
              <Button
                size="sm"
                variant="link"
                fontWeight={400}
                onClick={() =>
                  gatewayCrud.instance.defaults?.baseURL &&
                  window.open(urljoin(gatewayCrud.instance.defaults.baseURL, 'api'), '_blank')
                }
                colorScheme="dark"
              >
                Gateway API
                <Box ml={1}>
                  <RiExternalLinkFill />
                </Box>
              </Button>
            </Flex>
            <Flex mt={3} px={4}>
              <Button
                size="sm"
                variant="link"
                fontWeight={400}
                onClick={onCopy}
                colorScheme={hasCopied ? 'cyan' : 'dark'}
              >
                {hasCopied ? 'Copied in clipboard!' : 'Copy Token'}
              </Button>
            </Flex>
            <Flex mt={3} px={4}>
              <Button
                size="sm"
                variant="link"
                fontWeight={400}
                colorScheme="red"
                onClick={() => dispatch(makeLogout())}
              >
                Logout
              </Button>
            </Flex>
            <Divider my={4} />
            <Box px={4} mb={2} w="full">
              <FormControl display="flex" alignItems="center" justifyContent="space-between">
                <FormLabel fontSize="sm" htmlFor="color-mode" mb={0}>
                  {`${colorMode === 'light' ? 'Light' : 'Dark'} mode`}
                </FormLabel>
                <Switch
                  id="color-mode"
                  _focus={{ outline: 'none', boxShadow: 'none' }}
                  size="xs"
                  onChange={toggleColorMode}
                />
              </FormControl>
            </Box>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
