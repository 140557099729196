const ACCESS_TOKEN_STORAGE = 'loko.accessToken';
const REFRESH_TOKEN_STORAGE = 'loko.refreshToken';
const EDITOR_STORAGE = 'loko.editor';
const TENANT_STORAGE = 'loko.tenant';
const SHOW_CONSOLE_STORAGE = 'loko.showConsole';
const SHOW_MINIMAP_STORAGE = 'loko.showMinimap';
const SHOW_FLOW_JOYRIDE_STORAGE = 'loko.flowJoyride';
const SHOW_PROJECTS_JOYRIDE_STORAGE = 'loko.projectsJoyride';
const CONSOLE_HEIGHT_STORAGE = 'loko.consoleHeight';
const SETTINGS_FULL_WIDTH_STORAGE = 'loko.settingsFullWidth';
const UNDOS_STORAGE = 'loko.undos';
const SETTINGS_STORAGE = 'loko.settings';

export {
  ACCESS_TOKEN_STORAGE,
  REFRESH_TOKEN_STORAGE,
  EDITOR_STORAGE,
  TENANT_STORAGE,
  SHOW_CONSOLE_STORAGE,
  SHOW_MINIMAP_STORAGE,
  SHOW_FLOW_JOYRIDE_STORAGE,
  SHOW_PROJECTS_JOYRIDE_STORAGE,
  CONSOLE_HEIGHT_STORAGE,
  SETTINGS_FULL_WIDTH_STORAGE,
  UNDOS_STORAGE,
  SETTINGS_STORAGE,
};
