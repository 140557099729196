import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { messagesStore } from './messages';
import { resourcesStore } from './resources';
import { settingsStore } from './settings';
import { flowStore } from './flow';

const history = createBrowserHistory();

export default combineReducers({
  flow: flowStore.reducer,
  settings: settingsStore.reducer,
  resources: resourcesStore.reducer,
  messages: messagesStore.reducer,
  router: connectRouter(history),
});
