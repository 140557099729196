import { ColorMode, theme } from '@chakra-ui/react';

export const Accordion = {
  ...theme.components.Accordion,
  parts: ['container', 'button', 'panel'],
  baseStyle: {
    container: {
      borderTopWidth: '1px',
      borderColor: 'inherit',
      _last: {
        borderBottomWidth: '1px',
      },
    },
    button: {
      display: 'flex',
      fontSize: '1rem',
      cursor: 'pointer',
      _focus: {
        boxShadow: 'none',
      },
      _hover: {
        bg: 'blackAlpha.50',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
      px: 4,
      py: 1,
    },
    panel: { pt: 2, px: 0, pb: 4 },
  },
  variants: {
    treeview: ({ colorMode }: { colorMode: ColorMode }) => ({
      panel: {
        mt: '-px',
        px: 2,
        pt: 2,
        pb: 3,
      },
      button: {
        p: 2,
        borderColor: colorMode === 'dark' ? 'gray.700' : 'gray.200',
      },
    }),
  },
};
