import { useState, useEffect } from 'react';
import { Box, Flex, Textarea, Button } from '@chakra-ui/react';
import { showToast } from 'shared/Toast';
import { orchestratorCrud } from 'services/api';
import { GenericObject } from 'utils/misc';
import { Item } from '../types';

export function TextViewer({ item }: { item: Item }) {
  const [text, setText] = useState('');

  useEffect(() => {
    orchestratorCrud.getOne<string | GenericObject>('files', item.path).then((data) => {
      if (typeof data === 'string') {
        setText(data);
      } else {
        setText(JSON.stringify(data, null, 2));
      }
    });
  }, [item]);

  function handleSave() {
    const form = new FormData();
    const blob = new Blob([text], {
      type: 'text/plain',
    });
    form.append('file', blob, item.name);

    orchestratorCrud.overwriteOne('files', item.parent, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    showToast({ title: 'File saved!' });
  }

  return (
    <Flex flex="1" flexDirection="column">
      {text && <Textarea value={text} onChange={(e) => setText(e.target.value)} h="full" resize="none" rows={20} />}

      <Box mt={4} textAlign="right">
        <Button colorScheme="pink" size="sm" variant="ghost" onClick={handleSave}>
          Save
        </Button>
      </Box>
    </Flex>
  );
}
