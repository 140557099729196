import { writeStorage } from '@rehooks/local-storage';
import { createSlice } from '@reduxjs/toolkit';
import { SETTINGS_STORAGE } from 'constants/localStorage';

const initialState = {
  fields: [
    {
      name: 'autoSave',
      label: 'Auto save',
      type: 'boolean',
    },
  ],
  values: localStorage.getItem(SETTINGS_STORAGE) || {
    autoSave: true,
  },
};

export const settingsStore = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettings(state, action) {
      state.values = action.payload;
      writeStorage(SETTINGS_STORAGE, state.values);
    },
  },
});

export const { setSettings } = settingsStore.actions;
