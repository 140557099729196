import { Fragment, useEffect } from 'react';
import { Button, HStack, IconButton, Input } from '@chakra-ui/react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { RiCloseLine } from 'react-icons/ri';
import { v4 as uuid } from 'uuid';
import { IField } from 'constants/form';
import { BaseSelect } from './BaseSelect';

export type MultiKeyValueField = IField & {
  fields: Array<IField & { options: any[] }>;
  addLabel?: string;
};

interface MultiKeyValueProps {
  field: MultiKeyValueField;
}

export function MultiKeyValue({ field }: MultiKeyValueProps) {
  const { register, control, setError, errors, clearErrors } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: field.name,
    keyName: '_id',
  });

  useEffect(() => {
    if (fields) {
      if (
        field.validation?.required &&
        fields.length === 0 &&
        errors[field.name]?.message !== 'Insert at least one field'
      ) {
        setError(field.name, {
          type: 'manual',
          message: 'Insert at least one field',
        });
      } else if (
        field.validation?.required &&
        fields.length > 0 &&
        errors[field.name]?.message === 'Insert at least one field'
      ) {
        clearErrors(field.name);
      }
    }
  }, [fields]);

  return (
    <>
      {fields.map((item, index) => (
        <HStack key={item._id} spacing={0} marginBottom={3} borderRadius="md" overflow="hidden">
          {field.fields.map((element, j) => (
            <Fragment key={element.name}>
              {element.type === 'select' ? (
                <BaseSelect
                  size="sm"
                  field={element}
                  id={`${field.name}[${index}].[${element.name}]`}
                  name={`${field.name}[${index}].[${element.name}]`}
                  defaultValue={item[element.name]}
                  options={element.options}
                  borderRadius="0"
                  mr={j < 1 ? '-px' : 'unset'}
                  borderTopLeftRadius={j < 1 ? 'md' : 'unset'}
                  borderBottomLeftRadius={j < 1 ? 'md' : 'unset'}
                />
              ) : (
                <Input
                  type="text"
                  size="sm"
                  name={`${field.name}[${index}].[${element.name}]`}
                  id={`${field.name}[${index}].[${element.name}]`}
                  placeholder={element.placeholder}
                  defaultValue={item[element.name]}
                  ref={register(element.validation)}
                  borderRadius="0"
                  mr={j < 1 ? '-px' : 'unset'}
                  borderTopLeftRadius={j < 1 ? 'md' : 'unset'}
                  borderBottomLeftRadius={j < 1 ? 'md' : 'unset'}
                />
              )}
            </Fragment>
          ))}
          <Input
            hidden
            size="md"
            id={`${field.name}[${index}].id`}
            name={`${field.name}[${index}].id`}
            type="text"
            defaultValue={item.id}
            ref={register()}
          />

          <IconButton
            aria-label="Remove item"
            size="sm"
            borderRadius="0"
            icon={<RiCloseLine />}
            onClick={() => remove(index)}
          />
        </HStack>
      ))}

      <Button
        size="xs"
        onClick={() => {
          const toSend: {
            [key: string]: string;
          } = {
            id: uuid(),
          };

          field.fields.forEach((element) => (toSend[element.name] = ''));

          append(toSend);
        }}
      >
        {field.addLabel || 'Add field'}
      </Button>
    </>
  );
}
