import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Message, Payload } from 'store/types/messages';

interface MessagesState {
  notification: any;
  console: Array<{ type: 'default' | 'process' | 'error' } & Message>;
  animation: Message[];
  info: Message[];
  processes: Message[];
}

const initialState = {
  notification: null,
  console: [],
  animation: [],
  info: [],
  processes: [],
} as MessagesState;

export const messagesStore = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    pushMessage(state, action: PayloadAction<Payload>) {
      const { key, value } = action.payload;
      const n = 49;

      if (key in state) {
        if (Array.isArray(value)) {
          state[key].push(...value);
        } else {
          state[key].push(value);
        }
        if (state[key].length > n) {
          state[key] = state[key].slice(-n);
        }
      } else {
        console.error(`${key} key not in store`);
      }
    },
    deleteMessage(state, action: PayloadAction<Payload>) {
      const { key, value } = action.payload;
      if (key in state) {
        state[key] = state[key].filter((message: Message) => message.uid !== value);
      } else {
        console.error(`${key} key not in store`);
      }
    },
    setKey(state, action: PayloadAction<Payload>) {
      const { key } = action.payload;
      if (key in state) {
        state[key] = action.payload.value;
      } else {
        console.error(`${key} key not in store`);
      }
    },
  },
});

export const { pushMessage, deleteMessage, setKey } = messagesStore.actions;
