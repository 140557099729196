import { ColorMode, useColorModeValue } from '@chakra-ui/color-mode';
import Icon from '@chakra-ui/icon';
import { Box, Flex } from '@chakra-ui/layout';
import { chakra } from '@chakra-ui/system';
import { createStandaloneToast } from '@chakra-ui/toast';
import { customTheme } from 'assets/themes';
import { RiCheckboxCircleFill, RiErrorWarningFill, RiInformationFill, RiEmotionSadLine } from 'react-icons/ri';
import { capitalize } from 'utils/misc';

interface ToastProps {
  title: string;
  description?: string;
  type?: 'info' | 'warning' | 'success' | 'error';
}

export function Toast({ title, description = '', type = 'success' }: ToastProps) {
  const toastBg = useColorModeValue('gray.50', 'gray.800');
  const titleColor = useColorModeValue('gray.900', 'gray.200');

  const toastColor: { [key: string]: { bg: string; span: string; icon: any } } = {
    success: {
      bg: 'green.700',
      span: useColorModeValue('green.700', 'green.700'),
      icon: RiCheckboxCircleFill,
    },
    info: {
      bg: 'cyan.500',
      span: useColorModeValue('cyan.600', 'cyan.300'),
      icon: RiInformationFill,
    },
    warning: {
      bg: 'yellow.500',
      span: useColorModeValue('yellow.500', 'yellow.400'),
      icon: RiErrorWarningFill,
    },
    error: {
      bg: 'red.500',
      span: useColorModeValue('red.500', 'red.400'),
      icon: RiEmotionSadLine,
    },
  };

  return (
    <Flex mb="1" mx="0" w="full">
      <Flex w="full" mx="auto" shadow="md" boxShadow="xl" overflow="hidden" borderRadius="2xl" bg={toastBg}>
        <Flex justifyContent="center" alignItems="center" w={16} bg={toastColor[type].bg}>
          <Icon as={toastColor[type].icon} fill="#fff" boxSize={6} />
        </Flex>

        <Box mx={-3} py={2} px={4} w="400px">
          <Box mx={3} pt="2">
            <chakra.span color={toastColor[type].span} fontWeight="bold">
              {capitalize(type)}
            </chakra.span>
            <chakra.p color={titleColor} fontSize="md" fontWeight="700" mt="2">
              {capitalize(title)}
            </chakra.p>
            {description && (
              <chakra.p color={titleColor} fontSize="md" mb="2">
                {capitalize(description)}
              </chakra.p>
            )}
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export function showToast({ title, description, type = 'success' }: ToastProps) {
  // Need to retrieve manually the colorMode, not being able to use useColorMode hook outside of components
  const colorMode = localStorage.getItem('chakra-ui-color-mode') || 'light';
  const toast = createStandaloneToast({
    theme: customTheme,
    colorMode: colorMode as ColorMode,
  });

  toast({
    position: 'bottom',
    duration: 5000,
    render: () => <Toast title={title} description={description} type={type} />,
  });
}
