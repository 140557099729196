import { IconButton, Input, InputGroup, InputRightElement, Tooltip, useClipboard } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { RiFileCopyLine } from 'react-icons/ri';
import urljoin from 'url-join';
import { IField } from 'constants/form';
import { orchestratorCrud } from 'services/api';
import { activeProjectSelector } from 'store/selectors/resources';
import { useAppSelector } from 'hooks/useRedux';

export function Path({ field, ...rest }: { field: IField }) {
  const { register, watch } = useFormContext();
  const activeProject = useAppSelector(activeProjectSelector);
  const inputWatch = watch(field.name);

  const { onCopy } = useClipboard(
    urljoin(orchestratorCrud.instance.defaults.baseURL || '', 'endpoints', activeProject?.id || '', inputWatch),
  );

  return (
    <InputGroup {...rest}>
      <Input
        name={field.name}
        type="text"
        size="sm"
        ref={register({
          pattern: {
            value: /^[^/*].*/,
            message: "Invalid syntax, don't insert '/' at start",
          },
        })}
        placeholder={field.placeholder}
      />
      <InputRightElement width="3rem" height="2rem">
        <Tooltip label="Copy" aria-label="Copy">
          <IconButton aria-label="Copy" size="xs" variant="ghost" icon={<RiFileCopyLine />} onClick={onCopy} />
        </Tooltip>
      </InputRightElement>
    </InputGroup>
  );
}
