import useLocalStorage from '@rehooks/local-storage';
import { TENANT_STORAGE } from 'constants/localStorage';
import { useAppDispatch } from 'hooks/useRedux';
import { lazy, Suspense, useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { baseURL, gatewayCrud, orchestratorCrud } from 'services/api';
import Loader from 'shared/Loader';
import { showToast } from 'shared/Toast';
import { getUser, makeLogout } from 'store/async/resources';
import urljoin from 'url-join';

export interface Tenant {
  id: string;
  owner: string;
  name: string;
  url: string;
  creation_date: string;
  status: string;
  plan: string;
}

const Welcome = lazy(() => import('views/Welcome/Welcome'));
const Projects = lazy(() => import('views/Projects/Projects'));
const Settings = lazy(() => import('views/Settings/Settings'));
const Help = lazy(() => import('views/Help/Help'));
const Files = lazy(() => import('views/Files/Files'));
const Academy = lazy(() => import('views/Academy/Academy'));
const Predictors = lazy(() => import('views/Predictors/Predictors'));

export function Routes() {
  const routes = [
    {
      path: '/welcome',
      main: <Welcome />,
    },
    {
      path: '/projects',
      main: <Projects />,
    },
    {
      path: '/predictors',
      main: <Predictors />,
    },
    {
      path: '/files/:folder*',
      main: <Files />,
    },
    {
      path: '/settings',
      main: <Settings />,
    },
    {
      path: '/help',
      main: <Help />,
    },
    {
      path: '/academy',
      main: <Academy />,
    },
  ];

  const [isReady, setIsReady] = useState(false);
  const [selectedTenant] = useLocalStorage<string>(TENANT_STORAGE);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const useIAM = import.meta.env.VITE_USE_IAM === 'true';

    if (useIAM) {
      // NEED TO SET AGAIN TO PREVENT WRONG URL ON LOGOUT AND RE-LOGIN
      gatewayCrud.instance.defaults.baseURL = baseURL;

      gatewayCrud.getAll<Tenant[]>(urljoin('iaas', 'tenants')).then((data) => {
        if (!selectedTenant || !data.map((tenant) => tenant.url).includes(selectedTenant)) {
          dispatch(makeLogout());
          showToast({ title: 'Tenant not found or not available', type: 'warning' });
          return;
        }

        orchestratorCrud.instance.defaults.baseURL = urljoin(selectedTenant, 'orchestrator');
        gatewayCrud.instance.defaults.baseURL = selectedTenant;

        setIsReady(true);

        dispatch(getUser());
      });
    } else {
      setIsReady(true);
      dispatch(getUser());
    }
  }, []);

  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/welcome" />
      </Route>

      {routes.map((route) => (
        <Route key={route.path} path={route.path}>
          <Suspense fallback={<Loader />}>{isReady && route.main}</Suspense>
        </Route>
      ))}

      <Route path="*">
        <Redirect to="/welcome" />
      </Route>
    </Switch>
  );
}
