import { Flex, Text } from '@chakra-ui/react';

export function OfflineView() {
  return (
    <Flex w="full" h="100vh" justifyContent="center" alignItems="center" flexDirection="column" textAlign="center">
      <Text fontSize="3xl" color="orange" mb="2">
        You are offline!
      </Text>
      <Text fontSize="lg">LOKO will be restored automatically when the connection comes back</Text>
    </Flex>
  );
}
