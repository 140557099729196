import { ColorMode, theme } from '@chakra-ui/react';

export const Input = {
  ...theme.components.Input,
  parts: ['field', 'addon'],
  baseStyle: ({ colorMode }: { colorMode: ColorMode }) => ({
    field: {
      w: 'full',
      mr: '-px',
      color: colorMode === 'dark' ? 'gray.300' : 'gray.700',
    },
  }),
  sizes: {
    '2xs': {
      field: {
        fontSize: '0.75rem',
        pl: '0.25rem',
        pr: '0.25rem',
        h: '1.25rem',
        borderRadius: 2,
      },
    },
    xs: {
      field: {
        fontSize: '0.875rem',
        pl: '0.375rem',
        pr: '0.375rem',
        h: '1.75rem',
        borderRadius: 'sm',
      },
    },
    sm: {
      field: {
        borderRadius: 'md',
      },
    },
  },
  variants: {
    coloured: ({ colorMode }: { colorMode: ColorMode }) => ({
      field: {
        bg: colorMode === 'dark' ? 'gray.700' : 'gray.100',
      },
    }),
  },
  defaultProps: {
    size: 'sm',
  },
};
