import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';
import { IconBar } from './components/iconbar';
import { IconBarItem } from './components/iconbarItem';
import { Layout } from './components/layout';
import { MenuItem } from './components/menuitem';
import { Message } from './components/message';
import { Pagination } from './components/pagination';
import { Flow } from './components/flow';
import { Accordion } from './shared/accordion';
import { Button } from './shared/button';
import { Input } from './shared/input';
import { Menu } from './shared/menu';
import { Modal } from './shared/modal';
import { Popover } from './shared/popover';
import { Switch } from './shared/switch';
import { Tabs } from './shared/tabs';
import { Textarea } from './shared/textarea';
import { Tooltip } from './shared/tooltip';
import { styles, fonts, colors, shadows } from './styles';

const config = {
  useSystemColorMode: false,
  initialColorMode: 'light',
};

const overrides = {
  config,
  styles,
  fonts,
  colors,
  shadows,
  components: {
    // Shared components
    Button,
    Menu,
    Tabs,
    Input,
    Accordion,
    Switch,
    Popover,
    Modal,
    Tooltip,
    Textarea,
    // Custom Loko components
    Flow,
    IconBar,
    IconBarItem,
    MenuItem,
    Message,
    Pagination,
    Layout,
    // Other components go here
  },
};

export const customTheme = extendTheme(withDefaultColorScheme({ colorScheme: 'gray' }), overrides);
