import { ColorMode, theme } from '@chakra-ui/react';

export const Popover = {
  ...theme.components.Popover,
  parts: ['popper', 'content', 'header', 'body', 'footer', 'arrow'],
  baseStyle: ({ colorMode }: { colorMode: ColorMode }) => ({
    popper: {
      bg: colorMode === 'dark' ? 'gray.600' : 'gray.100',
      borderRadius: 'md',
    },
    header: {
      bg: colorMode === 'dark' ? 'gray.900' : 'gray.100',
    },
    body: {
      bg: colorMode === 'dark' ? 'gray.900' : 'gray.100',
      borderRadius: 'md',
    },
    footer: {
      bg: colorMode === 'dark' ? 'gray.900' : 'gray.100',
    },
    arrow: {
      bg: colorMode === 'dark' ? 'gray.900' : 'gray.100',
    },
  }),
};
