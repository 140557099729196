import { ColorMode } from '@chakra-ui/react';

export const Message = {
  baseStyle: ({ colorMode }: { colorMode: ColorMode }) => ({
    px: '2',
    fontFamily: "'Menlo', monospace",
    alignItems: 'center',
    color: colorMode === 'dark' ? 'gray.400' : 'gray.700',
  }),
  sizes: {
    xs: { fontSize: '0.675rem', py: '0' },
    sm: { fontSize: '0.75rem', py: '0.125rem' },
    md: { fontSize: '0.875rem', py: '0.125rem' },
    lg: { fontSize: '1rem', py: '0.25rem' },
    xl: { fontSize: '1.125rem', py: '0.375rem' },
  },
  defaultProps: {
    size: 'sm',
  },
};
