export type GenericObject<T = unknown> = Record<string, T>;
export type Nullable<T> = T | null;
export type Maybe<T> = T | undefined;

export function getTypeColor(type?: string) {
  switch (type) {
    case 'Time': {
      return '#FA804C';
    }
    case 'Inputs': {
      return '#F8637A';
    }
    case 'Outputs': {
      return '#56DCB9';
    }
    case 'Notifications': {
      return '#8CC152';
    }
    case 'DS4Biz':
    case 'Core': {
      return '#FFB300';
    }
    case 'HTTP': {
      return '#56A6DC';
    }
    case 'Brokers': {
      return '#A8C2BC';
    }
    case 'Parser': {
      return '#56DCB9';
    }
    case 'Common': {
      return '#D770AD';
    }
    case 'Cloud': {
      return '#74C339';
    }
    case 'MQTT': {
      return '#0097CC';
    }
    case 'Integrations': {
      return '#888888';
    }
    default: {
      return '#888';
    }
  }
}

export function ellipsis(object: string | Record<string, unknown>, length: number) {
  if (!object) {
    return null;
  }

  const truncatedString = typeof object === 'string' ? object : JSON.stringify(object);
  if (truncatedString.length > length) return `${truncatedString.slice(0, length)}...`;

  return truncatedString;
}

//  Without ending dot (.)
export function getFileExtension(filename: string) {
  return filename.split('.').pop()?.toLowerCase();
}

export function getFileName(path: string) {
  return path.replace(/^.*[\\/]/, '');
}

// Filename without extension
export function getClearFileName(filename: string) {
  return filename.replace(/\.[^/.]+$/, '');
}

// @ts-ignore
export const isFirefox = typeof InstallTrigger !== 'undefined';

export function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getWindowDimensions() {
  const { innerWidth, innerHeight } = window;

  return {
    innerWidth,
    innerHeight,
  };
}

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export function arrayBufferToBase64(data: string, contentType: string) {
  return `data:${contentType.toLowerCase()};base64,${Buffer.from(data, 'binary').toString('base64')}`;
}
