import {
  Box,
  Button,
  ButtonProps,
  Radio,
  RadioGroup,
  Flex,
  FlexProps,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import { ChangeEvent, Fragment, ReactElement, ReactNode, useState } from 'react';
import { RiArrowDownSLine, RiArrowUpSLine, RiSearchLine, RiSortAsc, RiSortDesc } from 'react-icons/ri';

export interface ViewListProps extends FlexProps {
  title: string;
  actions?: Array<ButtonProps & { icon?: ReactElement; label?: string; render?: ReactNode }>;
  filters?: {
    criterias: string[];
  };
  children:
    | ((search: { keyword: string; order: 'asc' | 'desc'; orderBy: string; advancedFilter: string }) => ReactNode)
    | ReactNode;
  isSearchEnabled?: boolean;
  headerStyle?: FlexProps;
  isHeaderSticky?: boolean;
}

export function ViewList({
  title,
  actions,
  filters,
  children,
  isSearchEnabled = true,
  isHeaderSticky = true,
  headerStyle = {},
  ...restProps
}: ViewListProps) {
  const textColor = useColorModeValue('gray.600', 'gray.400');
  const bgColor = useColorModeValue('gray.50', 'gray.900');

  const [search, setSearch] = useState({
    keyword: '',
    order: 'asc',
    orderBy: filters && filters.criterias[0],
    advancedFilter: filters && filters.criterias[0],
  });

  function handleOrder() {
    setSearch({ ...search, order: search.order === 'asc' ? 'desc' : 'asc' });
  }

  function handleOrderBy(value: string) {
    setSearch({ ...search, orderBy: value });
  }

  function handleAdvancedFilterChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    setSearch({ ...search, advancedFilter: value });
  }

  return (
    <Flex w="full" p="12" pt="0" flexDirection="column" {...restProps}>
      <Flex
        pt={6}
        pb={1}
        px={12}
        mx={-12}
        color={textColor}
        w="calc(100% + 96px)"
        flexDirection="column"
        position={isHeaderSticky ? 'sticky' : 'initial'}
        zIndex={1030}
        top={12}
        backgroundColor={isHeaderSticky ? bgColor : 'transparent'}
        {...headerStyle}
      >
        <Flex alignItems="center" mb={actions ? 2 : 0}>
          <Heading fontSize="xl" lineHeight={8} mr={2}>
            {title}
          </Heading>

          {actions &&
            actions.map(({ icon, label, render, ...rest }, i) => (
              <Fragment key={i}>
                {render || (
                  <Button leftIcon={icon} size="sm" variant="ghost" colorScheme="black" {...rest}>
                    {label}
                  </Button>
                )}
              </Fragment>
            ))}
        </Flex>
        {isSearchEnabled && (
          <Flex justifyContent="space-between" mb={1} alignItems="end">
            <Flex flexDir="column">
              {filters && (
                <Box mb={1}>
                  <RadioGroup value={search.advancedFilter}>
                    <Stack spacing={[1, 5]} direction={['column', 'row']}>
                      {filters.criterias.map((criteria, index) => (
                        <Radio onChange={handleAdvancedFilterChange} size="sm" key={index} value={criteria}>
                          {criteria}
                        </Radio>
                      ))}
                    </Stack>
                  </RadioGroup>
                </Box>
              )}
              <Box maxW={60}>
                <InputGroup size="sm">
                  <InputLeftElement pointerEvents="none" ml={0.5}>
                    <RiSearchLine />
                  </InputLeftElement>
                  <Input
                    placeholder="Search"
                    value={search.keyword}
                    onChange={(e) => setSearch({ ...search, keyword: e.target.value })}
                  />
                </InputGroup>
              </Box>
            </Flex>
            {filters && (
              <Flex>
                <Menu id="list order">
                  {({ isOpen }) => (
                    <>
                      <MenuButton
                        mr="2"
                        size="sm"
                        as={Button}
                        variant="outline"
                        rightIcon={isOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
                      >
                        {`Order by ${search.orderBy}`}
                      </MenuButton>
                      <MenuList>
                        {filters.criterias.map((value, index) => (
                          <MenuItem key={index} onClick={() => handleOrderBy(value)}>
                            {value}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </>
                  )}
                </Menu>
                <Button size="sm" variant="outline" onClick={handleOrder}>
                  {search.order === 'asc' ? <RiSortDesc /> : <RiSortAsc />}
                </Button>
              </Flex>
            )}
          </Flex>
        )}
      </Flex>

      {/* @ts-ignore */}
      {isSearchEnabled ? children(filters ? search : search.keyword) : children}
    </Flex>
  );
}
