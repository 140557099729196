import { CSSProperties, memo, ReactNode } from 'react';
import { useColorMode } from '@chakra-ui/color-mode';
import { Box } from '@chakra-ui/layout';
import { BoxProps } from '@chakra-ui/react';
import { Menu, Item, useContextMenu, ItemParams } from 'react-contexify';
import { createPortal } from 'react-dom';
import { v4 as uuid } from 'uuid';
import 'react-contexify/dist/ReactContexify.css';

function _ContextMenu({
  id = uuid(),
  value,
  items,
  children,
  customStyles = {},
  enabled = true,
  usePortal = true,
  isNested,
  ...rest
}: ContextMenuProps) {
  const { colorMode } = useColorMode();

  const { show } = useContextMenu({
    id,
  });

  function Portal({ children: portalChildren }: { children: ReactNode }) {
    return createPortal(portalChildren, document.querySelector('#root') as HTMLElement);
  }

  function renderMenu() {
    return (
      <Menu id={id} style={customStyles} theme={colorMode} animation={false}>
        {items.map((item, i) => (
          <Item key={i} onClick={(e) => item.onClick(value, e)} disabled={item.disabled} hidden={item.hidden}>
            {item.label}
          </Item>
        ))}
      </Menu>
    );
  }

  return (
    <>
      {enabled ? (
        <>
          <Box
            onContextMenu={(e) => {
              // To avoid multiple contexts if are nested
              isNested && e.stopPropagation();

              show(e, {
                id,
              });
            }}
            {...rest}
          >
            {children}
          </Box>

          {usePortal ? <Portal>{renderMenu()}</Portal> : renderMenu()}
        </>
      ) : (
        children
      )}
    </>
  );
}

export const ContextMenu = memo(_ContextMenu);

interface ContextMenuProps extends BoxProps {
  id?: string;
  value?: any;
  items: Array<{
    label: string;
    disabled?: boolean;
    hidden?: boolean;
    onClick: (value: any, event: ItemParams) => void;
  }>;
  children: ReactNode;
  enabled?: boolean;
  usePortal?: boolean;
  isNested?: boolean;
  customStyles?: CSSProperties;
}
