import { theme } from '@chakra-ui/react';

export const Textarea = {
  ...theme.components.Textarea,
  sizes: {
    sm: {
      field: {
        borderRadius: 'xl',
      },
    },
  },
};
